/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAllProductsWholeSale, addTocart } from "../../service/api";
/* IMAGE IMPORTS */
import PrimaryImage from '../../assets/product-detail/detail-02.webp';
import SlideImage01 from '../../assets/product-detail/detail-03.webp';
import SlideImage02 from '../../assets/product-detail/detail-04.webp';
import SlideImage03 from "../../assets/product-detail/detail-05.webp";
import SlideImage04 from "../../assets/product-detail/detail-06.webp";
import SlideImage05 from "../../assets/product-detail/detail-07.webp";
import SlideImage06 from "../../assets/product-detail/detail-08.webp";
import ReactHtmlParser from "react-html-parser";
import { IMG_URL } from "../../config";
/* ICON IMPORTS */
import LockIcon from '../../assets/icons/lock-icon.png';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProductImage from "./ProductImage";
import ProductDetailLoading from "../../components/detail-loadingWholesale";
import { useSnackbar } from "notistack";

const WholesaleShop = () => {
  const { wholesaleProduct, shopAll, categoryId, trigger, singlePdt, setMenu } = useSelector(state => state.products)
  const { enqueueSnackbar } = useSnackbar();

  const [allProducts, setAllProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalResults, setTotalResults] = useState(null);
  const navigate = useNavigate()
  const [activeImage, setactiveImage] = useState(null)
  const [color, setColor] = useState(null)
  const [productImages, setProductImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [minQty, setMinQty] = useState(0)

  const getProductList = () => {
    let params = {
      page: 1,
      sizePerPage: pageSize,
    };
    setLoading(true)
    getAllProductsWholeSale(params)
      .then((res) => {
        console.log("all pdt",res?.data)
        if (res?.status) {
          let temp = []
          if (wholesaleProduct === null && shopAll !== true) {
            console.log("no wholesale")
            temp = res?.data[0]?.docs?.filter(item =>
              item.category?.some(item2 => item2._id === categoryId._id)
            );
          }
          else {
            if (shopAll) {
              temp = res?.data[0]?.docs?.filter(item => item?._id !== wholesaleProduct?._id)
            }
            else if(singlePdt && !wholesaleProduct?.sku) {
              temp =res?.data[0]?.docs?.filter(item => item?._id === wholesaleProduct?._id)
            } 
            else if(singlePdt) {
              temp =res?.data[0]?.docs?.filter(item => item?._id === wholesaleProduct?._id)
            } 
            else if(setMenu) {
              console.log("setmenu",setMenu)
              temp = res?.data[0]?.docs?.filter(item =>
                item.category?.some(item2 => item2._id === wholesaleProduct._id) 
              );
              console.log("temp",temp)
            } 
            else
            {
              temp = res?.data[0]?.docs?.filter(item =>
                item.category?.some(item2 => item2._id === categoryId._id) &&
                item._id !== wholesaleProduct?._id
              );
            }
          }
          console.log("temp: ", temp);
          const finalTemp=[]
          temp?.map(item => item?.isWholeSale ? finalTemp.push(item) : null)
          setAllProducts(finalTemp);
          setTotalResults(res?.data[0]?.metadata[0]?.total);
          setColor(wholesaleProduct?.color?.length > 0 ? wholesaleProduct?.color[0] : null)
        }
        // console.log("products--->", res?.data[0]?.docs);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally((res) => {
        setLoading(false)
      });
  };

  useEffect(() => {
    getProductList()
    setMinQty({})
  }, [wholesaleProduct, trigger])

  const [peakQty, setPeakQty] = useState(0)
  useEffect(() => {

    const tepQty = wholesaleProduct?.quantity?.find(item => item?.color === color?._id)
    // console.log("quantity",tepQty?.quantity)
    setPeakQty(tepQty?.quantity)
    console.log("wholesaleProduct", wholesaleProduct)
    if (Array.isArray(wholesaleProduct?.productImage)) {
      const temp = wholesaleProduct?.productImage?.find(item => item?.color === color?._id)
      let tempArr = []
      if (temp?.front?.length > 0)
        tempArr.push(temp?.front[0])
      if (temp?.back?.length > 0)
        tempArr.push(temp?.back[0])
      if (temp?.others?.length > 0)
        temp?.others?.forEach(item => tempArr.push(item))

      // console.log("tempArr",tempArr)
      setProductImages(tempArr)
      if (tempArr?.length > 0)
        setactiveImage(tempArr[0])
    }
    else {
      const temp = wholesaleProduct?.productImage
      let tempArr = []
      if (temp?.front?.length > 0)
        tempArr.push(temp?.front[0])
      if (temp?.back?.length > 0)
        tempArr.push(temp?.back[0])
      if (temp?.others?.length > 0)
        temp?.others?.forEach(item => tempArr.push(item))

      // console.log("tempArr",tempArr)
      setProductImages(tempArr)
      if (tempArr?.length > 0)
        setactiveImage(tempArr[0])
    }

  }, [color])

  const [bag, setBag] = useState({})

  

  function handleQuantityChange(e, item) {
    // console.log("item",item)
    // console.log("wholesale", wholesaleProduct?.wholeSaleAmount)
    const newQuantity = parseInt(e.target.value);
    console.log("newQuantity",newQuantity)
    let tempTier = "tier1";
    if (newQuantity < 99) {
      tempTier = "tier1";
    } else if (newQuantity < 200) {
      tempTier = "tier2";
    } else if (newQuantity > 200){
      tempTier = "tier3";
    }
    console.log("tempTier",tempTier)
    
    setBag((prevBag) => {
      const existingItem = prevBag[item._id];
      if (existingItem) {
        const updatedItem = {
          ...existingItem,
          quantity: newQuantity,
        };
        setMinQty((prevMinQty) => ({
          ...prevMinQty,
          [item._id]: updatedItem?.quantity,
        }));
        if (newQuantity === 0) {
          const { [item._id]: removedItem, ...newBag } = prevBag;
          return newBag;
        } else {
          return {
            ...prevBag,
            [item._id]: updatedItem,
          };
        }
      } else {
        if (newQuantity === 0) {
          return prevBag;
        } else {
          const newItem = {
            product: wholesaleProduct?._id,
            color: item?._id,
            quantity: newQuantity,
            wholeSaleTier:tempTier
          };
          setMinQty((prevMinQty) => ({
            ...prevMinQty,
            [item._id]: newItem?.quantity,
          }));
          return {
            ...prevBag,
            [item._id]: newItem,
          };
        }
      }
    });
  }


  useEffect(() => {
    console.log("bag", bag)
  }, [bag])

  const [cartLoading, setCartLoading] = useState(false)
  const [cartLoadingItem, setCartLoadingItem] = useState(null)
  const addToCartFun = (item) => {

    const tempQty = wholesaleProduct?.quantity?.find(qitem => qitem?.color === item?._id)
    // console.log("wholesaleProduct",wholesaleProduct?.quantity)
    // console.log("quantity",tempQty?.quantity)
    // console.log("item",item)
    if(tempQty?.quantity < bag[item?._id]?.quantity)
    {
      enqueueSnackbar(`We have only ${tempQty?.quantity} pieces available for this color`, {
        variant: "error",
      });
      return
    }
    // console.log("item",item)
    // console.log("minQty", minQty)
    const itemMinQty = minQty[item._id];
    if (itemMinQty >= wholesaleProduct?.wholeSaleMinQuantity) {
      if (bag[item?._id]) {
        setCartLoading(true)
        setCartLoadingItem(item?._id)
        addTocart(bag[item?._id]).then(res => {
          console.log(res)
          const newBag = { ...bag };
          delete newBag[item._id];
          setMinQty((prevMinQty) => ({
            ...prevMinQty,
            [item._id]: 0,
          }));
          setBag(newBag);
          setCartLoading(true)
          enqueueSnackbar("Your product has been added to cart successfully.", {
            variant: "success",
          });
        })
          .catch(err => {
            enqueueSnackbar("Something went wrong.", {
              variant: "error",
            });
          })
          .finally(res => {
            setCartLoading(false)
            setCartLoadingItem(null)
          })
      }
      else {
        enqueueSnackbar("Please select quantity.", {
          variant: "error",
        });
      }
    }
    else {
      enqueueSnackbar("Minimum quantity has to be " + wholesaleProduct?.wholeSaleMinQuantity + " for this product", {
        variant: "error",
      });
    }

  }

  const { loggedIn } = useSelector(state => state.user)
  const stateS = useSelector(state => state.products)
  return (
    <section className="wholesale-shop">
      <Helmet>
        <title>Shop All | Browbar By Reema</title>
      </Helmet>
      <div className="container">
        {loading ? (
          <ProductDetailLoading />
        ) : (
          <div className="row">
            <h1>{stateS?.categoryId?.name ? stateS?.categoryId?.name.toUpperCase() : 'SHOP'} PRODUCTS</h1>


            {
              allProducts?.length > 0 ?
              allProducts?.map(item=><ProductImage product={item} loggedIn={loggedIn} key={item?._id} />) : <p className=" justify-content-center text-center pt-3" >No Products Found</p>
              // allProducts?.map(item => item?.isWholeSale ? <p>{JSON.stringify(item?.isWholeSale)}</p> : item?.isWholeSale)
            }

          </div>
        )}
      </div>
    </section>
  );
};

export default WholesaleShop;