import React from 'react';
import { Row, Col, Popover, OverlayTrigger} from "react-bootstrap";
import img from '../assets/products/lips-01.webp';
import { Button } from 'react-bootstrap';
import { AiOutlineShoppingCart } from 'react-icons/ai';

function ItemRow({category}) {

  const colorOverlay = (
      <Popover className='pop-over'>
        <Popover.Body className='pop-body'>
                <div>
                  <div className='color-list'>
                    <div className='color-list-1'/>
                    <p>Berry Diva</p>
                  </div>
                  <div className='color-list'>
                    <div className='color-list-2'/>
                    <p>BH Nude</p>
                  </div>
                  <div className='color-list'>
                    <div className='color-list-3'/>
                    <p>Drama Queen</p>
                  </div>
                  <div className='color-list'>
                    <div className='color-list-4'/>
                    <p>Rodeo Vamp</p>
                  </div>
                  <div className='color-list'>
                    <div className='color-list-5'/>
                    <p>Sapphire Siren</p>
                  </div>
                </div>
        </Popover.Body>
      </Popover>
  );
    console.log(category);
  return (
    <div>
      <Row className='row-div'>
        <Col lg="2" className='info-col'>
          <img src={img} alt="item" className="item-image"/>
        </Col>
        <Col lg="2" className='imp-col'>
          <p>Bombshell Lipstick</p>
        </Col>
        <Col lg="2" className='info-col'>
          <p>Face, Lips, sets</p>
        </Col>
        <Col lg="2" className='info-col'>
          <p>BBRBL1</p>
        </Col>
        <Col lg="2" className='info-col'>
          <OverlayTrigger trigger="click" rootClose placement='bottom' overlay={colorOverlay}>
            <Button className='item-btn'>
              Choose Color
            </Button>
          </OverlayTrigger>
        </Col>
        <Col lg="2" className='cart-col'>
          <input
          className='item-quant'
          name="quantity"
          id="quantity"
          placeholder='Quantity'
          />
          <Button className='cart-btn'>
            <AiOutlineShoppingCart/>
          </Button>
        </Col>
      </Row>
    </div>
    )
}

export default ItemRow
