/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Accordion, Button, Modal, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import Rating from "@mui/material/Rating";
import { AiOutlineStar, AiFillStar, AiFillDelete } from "react-icons/ai";
import ReactPaginate from "react-paginate";

import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { IMG_URL } from "../../config";
import { getProductById, postRating, getRating } from "../../service/api";
import { addTocart } from "../../service/api";
import SpinnerComponent from "../../components/Spinner";
import ReactHtmlParser from "react-html-parser";
import StarIcon from "@mui/icons-material/Star";
import { useSnackbar } from "notistack";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";

/* SWIPER STYLES */
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";

/* IMAGES IMPORT */
import Detail01 from "../../assets/product-detail/detail-02.webp";
import Detail02 from "../../assets/product-detail/detail-01.png";
import Detail03 from "../../assets/product-detail/detail-03.webp";
import Detail04 from "../../assets/product-detail/detail-04.webp";
import Detail05 from "../../assets/product-detail/detail-05.webp";
import { useUpdateCart } from "../../hooks/useUpdateCart";
import actions from "../../redux/products/actions";
import ProductDetailLoading from "../../components/detail-loading";

const options = [
    { value: "Date", label: "Date" },
    { value: "Rating", label: "Rating" },
    { value: "Helpfulness", label: "Helpfulness" },
];


function BrowKit() {
    const [count, setCount] = useState(1);
    const [reviewModal, setReviewModal] = useState(false);
    const [images, setImages] = useState([]);
    const [tempImg, settempImg] = useState([]);
    const { selectedProductId, selectedProduct, selectedColorId, cart } =
        useSelector((state) => state.products);
    const [apiCall, setApiCall] = useState("");


    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    let previewUrl = null;
    if (file) {
        previewUrl = URL.createObjectURL(file);
    }

    /* QUANTITY INCREMENT FUNCTION */
    function increment() {
        setCount(function (prevCount) {
            return (prevCount += 1);
        });
    }

    /* QUANTITY DECREMENT FUNCTION */
    function decrement() {
        setCount(function (prevCount) {
            if (prevCount > wholeSaleMinQuantity) {
                return (prevCount -= 1);
            } else {
                return (prevCount = wholeSaleMinQuantity);
            }
        });
    }

    const [ratings, setRatings] = useState([]);
    const [rpage, setrPage] = useState(1);
    const [rPageSize, setrPageSize] = useState(5);
    const [totalRPage, setTotalrPage] = useState(0);
    const [sortBy, setSortBy] = useState("DATE");
    const [avgReview, setAvgReview] = useState(0)
    const [wholeSaleMinQuantity, setWholeSaleMinQuantity] = useState(0)



    /* IMAGE ONCHANGE FUNCTION */
    const onChange = (imageList) => {
        setImages(imageList);
    };

    const [activeImage, setActiveImage] = useState(null);
    const [productDetail, setProductDetail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [colorIdIn, setcolorIdIn] = useState(null);
    const { loggedIn } = useSelector((state) => state.user);
    const { enqueueSnackbar } = useSnackbar();
    const { setUpdateCart } = useUpdateCart();
    const dispatch = useDispatch();

    /* USE EFFECT FUNCTION */
    useEffect(() => {
        setLoading(true);
        setcolorIdIn("647f603f2a0e6a9131a9dd27");
        getProductById("647f601e2a0e6a9131a9dd18")
            .then((res) => {
                // console.log("res", res);
                setProductDetail(res?.data);
                setWholeSaleMinQuantity(res?.data?.productData?.wholeSaleMinQuantity)
                setCount(wholeSaleMinQuantity)
            })
            .finally((res) => {
                setLoading(false);
            });
        window.scrollTo(0, 0);
    }, [selectedProduct, selectedProductId, selectedColorId]);

    const [showSelectedColor, setShowSelectedColor] = useState(null);
    const [selectedImg, setSelectedImg] = useState("");

    /* USE EFFECT FUNCTION */
    useEffect(() => {

        let data1 = productDetail?.productData?.quantity?.filter(
            (colorId) => colorId?.color?._id === colorIdIn
        );
        // console.log("data1--->", data1);
        if (data1) setShowSelectedColor(data1[0]);
        let data2 = productDetail?.productData?.productImage?.filter(
            (colorId) => colorId?.color?._id === colorIdIn
        );
        // console.log("data1--->", data2);

        if (data2) {
            setSelectedImg(data2[0]);
            setActiveImage(data2[0]?.front[0]);
        }
        return () => {
            settempImg([]);
        };
    }, [colorIdIn, productDetail]);

    const [rating, setRating] = useState(0);
    const [price, setPrice] = useState(0);
    const [qty, setQty] = useState(0);

    const [ratingInput, setRatingInput] = useState({
        nickName: "",
        summary: "",
        review: "",
        email: "",
    });

    /* RATINGS CHANGE FUNCTION */
    const onChangeRating = (e) => {
        setRatingInput({ ...ratingInput, [e.target.name]: e.target.value });
    };

    /* RATINGS FUNCTION */
    const saveRating = () => {
        if (!rating) {
            enqueueSnackbar(" Please rate the product", { variant: "error" });
            return;
        }
        if (!price) {
            enqueueSnackbar(" Please rate pricing", { variant: "error" });
            return;
        }
        if (!qty) {
            enqueueSnackbar(" Please rate quality", { variant: "error" });
            return;
        }

        if (!ratingInput?.nickName) {
            enqueueSnackbar(" Please enter name", { variant: "error" });
            return;
        }
        if (!ratingInput?.summary) {
            enqueueSnackbar(" Please enter summary", { variant: "error" });
            return;
        }
        if (!ratingInput?.email) {
            enqueueSnackbar(" Please enter email", { variant: "error" });
            return;
        }
        if (!ratingInput?.review) {
            enqueueSnackbar(" Please enter review", { variant: "error" });
            return;
        }

        setReviewModal(!reviewModal)
        // console.log("ratingInput", ratingInput)
        // console.log("rating", rating)
        // console.log("price", price)
        // console.log("qty", qty)
        const formData = new FormData()
        formData.append("nickName", ratingInput?.nickName)
        formData.append("summary", ratingInput?.summary)
        formData.append("review", ratingInput?.review)
        formData.append("email", ratingInput?.email)
        formData.append("quality", qty)
        formData.append("price", price)
        formData.append("rating", rating)
        formData.append("productId", selectedProductId)
        // images.map(file => { formData.append("image", file?.file) })
        formData.append('image', file)
        postRating(formData).then(res => {
            if (res?.status)
                enqueueSnackbar(" Rating posted successfully", { variant: 'success' });
            // const obj = {}
            // obj.productId = selectedProductId
            // obj.page = rpage
            // obj.sizePerPage = rPageSize
            // obj.sortBy = sortBy
            // getRating(obj).then(res => {
            //   console.log("rating re--->", res)
            //   setRatings(res?.data?.docs)
            //   setTotalrPage(res?.data?.totalDocs)
            // })
            // console.log(res)
            setReviewModal(false)
            setRatingInput({
                ...ratingInput,
                nickName: "",
                summary: "",
                review: "",
                email: ""
            })
            setImages([])
            setFile(null)
            setQty(wholeSaleMinQuantity)
            setPrice(0)
            setRating(0)
        })
            .catch(err => {
                // console.log(err)
                enqueueSnackbar(" Something went wrong", { variant: 'erro' });
                setReviewModal(true)
            })

    }

    /* ADD TO CART FUNCTION */

    const handleAddToCart = (tag) => {
        if (count === 0) {
            enqueueSnackbar("Add the Quantity you want to buy.", {
                variant: "error",
            });
            return;
        }
        setApiCall(true);
        if (loggedIn) {
            let body = {
                product: "647f601e2a0e6a9131a9dd18",
                color: colorIdIn,
                quantity: count,
            };
            addTocart(body).then((res) => {
                setApiCall(false);
                enqueueSnackbar(res?.message, { variant: "success" });
                if (res?.status) {
                    setCount(1);
                    setUpdateCart(true);
                }
            });
        } else {
            if (
                cart.filter(
                    (obj) =>
                        obj.product?._id === selectedProduct?._id &&
                        obj.color?._id === colorIdIn
                )?.length > 0
            ) {
                let data = cart.filter(
                    (obj) =>
                        obj.product?._id !== selectedProduct?._id &&
                        obj.color?._id !== colorIdIn
                );
                let product = cart.filter(
                    (obj) =>
                        obj.product?._id === selectedProduct?._id &&
                        obj.color?._id === colorIdIn
                )[0];
                product.quantity = product.quantity + count;

                data.push(product);
                actions.setCartData(data);
                setApiCall(false);
                // console.log(data)
                enqueueSnackbar("Your product has been added to cart successfully.", {
                    variant: "success",
                });
            } else {
                setApiCall(false);
                setCount(1);
                enqueueSnackbar("Your product has been added to cart successfully.", {
                    variant: "success",
                });
                dispatch(
                    actions.setCartData([
                        ...cart,
                        {
                            product: selectedProduct,
                            quantity: count,
                            color: selectedProduct.color.filter(
                                (obj) => obj?._id === colorIdIn
                            )[0],
                            amount: selectedProduct?.amount,
                        },
                    ])
                );
            }
        }
    };

    /* RETURN DATE FUNCTION */
    const returnDate = (date) => {
        let objectDate = new Date(date);
        let day = objectDate.getDate();
        let month = objectDate.getMonth();
        let year = objectDate.getFullYear();
        return day + "/" + month + 1 + "/" + year;
    };
    return (
        <div>
            <Container>
                <Row className='kit-row'>
                    <Col lg="6" className='kit-image-col' style={{ position: 'sticky', top: '120px', height: '100vh', overflowY: 'auto' }}>

                        <img src={'https://storage.googleapis.com/cdn-browbarbyreema-test/product/Poster.jpg'}
                            className="kit-image" alt="Starter Pack"
                        />
                    </Col>
                    <Col lg="6" className='kit-description px-4 pt-4 pb-4' style={{ height: '100%', overflowY: 'auto' }}>
                        <h4><strong>SMOOTH OPERATOR PROFESSIONAL SALON KIT</strong> <small style={{ float: 'right', fontSize: '42px !important' }}>$1050.00</small></h4>
                        <h6>Award winning starter Pro kit </h6>
                        <div className='kit-contents'>
                            {/* Bombshell Brow Kit */}
                            <div className='content-item mb-2'>
                                <Row className='w-100 align-content-center'>

                                    <Col md='2'>
                                        <img style={{ marginTop: '40px' }} alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1674670528926.webp'} className="content-img" />

                                    </Col>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='12' >
                                                <h4 className='mx-3'>Bombshell Brow Kit</h4>
                                            </Col>
                                            {/* <Col md='3' >
                                                <p className='paragraph'>Qty: <strong>9</strong></p>
                                            </Col>
                                            <Col md='3' >
                                                <p className='paragraph'>Price: <strong>$151.20</strong></p>
                                            </Col> */}
                                        </Row>

                                        <Accordion flush backgroundColor="transparent" className='mt-0 mb-0 pt-0 pb-0' >
                                            <Accordion.Item eventKey="0" className='pt-0 pb-0 mt-0 mb-0'>
                                                <Accordion.Header className="accordion-header pt-0 pb-0 mt-0 mb-0">
                                                    <Row className='pt-0 pb-0 mt-0 mb-0'>
                                                        <Col md='4' className='pt-0 pb-0 mt-0 mb-0' >
                                                            <div className="shade-present mx-3 mb-0">
                                                                <p ><strong>Shade</strong></p>
                                                            </div>
                                                        </Col>
                                                        <Col md='3' style={{ textAlign: "center" }} >
                                                            <p>Quantity</p>
                                                        </Col>
                                                        <Col md='2' style={{ textAlign: "center" }} >
                                                            <p>Price</p>
                                                        </Col>
                                                        <Col md='3' style={{ textAlign: "center" }} >
                                                            <p>Total</p>
                                                        </Col>
                                                    </Row></Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='pt-0 mt-0'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#BE6C4B' }}
                                                                />
                                                                <p className='mx-3'>{'Light'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >3</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$16.80</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$50.40</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='pt-2'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#665953' }}
                                                                />
                                                                <p className='mx-3'>{'Medium'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >3</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$16.80</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$50.40</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='pt-2'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#212529' }}
                                                                />
                                                                <p className='mx-3'>{'Dark'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >3</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$16.80</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$50.40</p>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>


                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px', marginTop: '0px' }} />

                                        <Row className='mt-1'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p className='px-4'><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "start" }} >
                                                <p className='px-4'>9</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "start" }} >
                                                <p className='px-4'>$16.80</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "start" }} >
                                                <p className='px-4'>$151.20</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* Brow Lift */}
                            <div className='content-item mb-2'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img style={{ marginTop: '40px' }} alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1674664622140.webp'} className="content-img" />

                                    </Col>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='12' >
                                                <h4 className='mx-3'>Brow Lift</h4>
                                            </Col>
                                            {/* <Col md='3' >
                                                <p className='paragraph'>Qty: <strong>9</strong></p>
                                            </Col>
                                            <Col md='3' >
                                                <p className='paragraph'>Price: <strong>$151.20</strong></p>
                                            </Col> */}
                                        </Row>
                                        <Accordion flush backgroundColor="transparent" className='mt-0 mb-0 pt-0 pb-0' >
                                            <Accordion.Item eventKey="0" className='pt-0 pb-0 mt-0 mb-0'>
                                                <Accordion.Header className="accordion-header pt-0 pb-0 mt-0 mb-0">
                                                    <Row className='pt-0 pb-0 mt-0 mb-0'>
                                                        <Col md='4' className='pt-0 pb-0 mt-0 mb-0' >
                                                            <div className="shade-present mx-3 mb-0">
                                                                <p ><strong>Shade</strong></p>
                                                            </div>
                                                        </Col>
                                                        <Col md='3' style={{ textAlign: "center" }} >
                                                            <p>Quantity</p>
                                                        </Col>
                                                        <Col md='2' style={{ textAlign: "center" }} >
                                                            <p>Price</p>
                                                        </Col>
                                                        <Col md='3' style={{ textAlign: "center" }} >
                                                            <p>Total</p>
                                                        </Col>
                                                    </Row></Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='pt-0 mt-0'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#E3B47D' }}
                                                                />
                                                                <p className='mx-3'>{'Light'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >3</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$4.20</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$12.60</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='pt-2'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#D79764' }}
                                                                />
                                                                <p className='mx-3'>{'Medium'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >3</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$4.20</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$12.60</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='pt-2'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#AF6D46' }}
                                                                />
                                                                <p className='mx-3'>{'Dark'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >3</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$4.20</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$12.60</p>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='mt-1'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p className='px-4'><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "start" }} >
                                                <p className='px-4'>9</p>
                                            </Col>
                                            <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                <p>$4.20</p>
                                            </Col>
                                            <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                <p>$37.80</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* Brow Makeover Kit */}
                            <div className='content-item mb-2'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img style={{ marginTop: '40px' }} alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1674671354670.webp'} className="content-img" />

                                    </Col>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='12' >
                                                <h4 className='mx-3'>Brow Makeover Kit</h4>
                                            </Col>
                                            {/* <Col md='3' >
                                                <p className='paragraph'>Qty: <strong>9</strong></p>
                                            </Col>
                                            <Col md='3' >
                                                <p className='paragraph'>Price: <strong>$151.20</strong></p>
                                            </Col> */}
                                        </Row>
                                        <Accordion flush backgroundColor="transparent" className='mt-0 mb-0 pt-0 pb-0' >
                                            <Accordion.Item eventKey="0" className='pt-0 pb-0 mt-0 mb-0'>
                                                <Accordion.Header className="accordion-header pt-0 pb-0 mt-0 mb-0">
                                                    <Row className='pt-0 pb-0 mt-0 mb-0'>
                                                        <Col md='4' className='pt-0 pb-0 mt-0 mb-0' >
                                                            <div className="shade-present mx-3 mb-0">
                                                                <p ><strong>Shade</strong></p>
                                                            </div>
                                                        </Col>
                                                        <Col md='3' style={{ textAlign: "center" }} >
                                                            <p>Quantity</p>
                                                        </Col>
                                                        <Col md='2' style={{ textAlign: "center" }} >
                                                            <p>Price</p>
                                                        </Col>
                                                        <Col md='3' style={{ textAlign: "center" }} >
                                                            <p>Total</p>
                                                        </Col>
                                                    </Row></Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='pt-0 mt-0'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#BE6C4B' }}
                                                                />
                                                                <p className='mx-3'>{'Light'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >3</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$13.50</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$40.50</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='pt-2'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#665953' }}
                                                                />
                                                                <p className='mx-3'>{'Medium'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >3</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$13.50</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$40.50</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='pt-2'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#3E3E3F' }}
                                                                />
                                                                <p className='mx-3'>{'Dark'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >3</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$13.50</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$40.50</p>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='mt-1'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p className='px-4'><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "start" }} >
                                                <p className='px-4'>9</p>
                                            </Col>
                                            <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                <p>$13.50</p>
                                            </Col>
                                            <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                <p>$121.50</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* Browlights, Camera, Action Duo Brow Highlighter */}
                            <div className='content-item mb-2'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img style={{ marginTop: '40px' }} alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1674564674784.jpg'} className="content-img" />

                                    </Col>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='12' >
                                                <h4 className='mx-3'>Browlights, Camera, Action Duo Brow Highlighter</h4>
                                            </Col>
                                            {/* <Col md='3' >
                                                <p className='paragraph'>Qty: <strong>9</strong></p>
                                            </Col>
                                            <Col md='3' >
                                                <p className='paragraph'>Price: <strong>$151.20</strong></p>
                                            </Col> */}
                                        </Row>
                                        <Accordion flush backgroundColor="transparent" className='mt-0 mb-0 pt-0 pb-0' >
                                            <Accordion.Item eventKey="0" className='pt-0 pb-0 mt-0 mb-0'>
                                                <Accordion.Header className="accordion-header pt-0 pb-0 mt-0 mb-0">
                                                    <Row className='pt-0 pb-0 mt-0 mb-0'>
                                                        <Col md='4' className='pt-0 pb-0 mt-0 mb-0' >
                                                            <div className="shade-present mx-3 mb-0">
                                                                <p ><strong>Shade</strong></p>
                                                            </div>
                                                        </Col>
                                                        <Col md='3' style={{ textAlign: "center" }} >
                                                            <p>Quantity</p>
                                                        </Col>
                                                        <Col md='2' style={{ textAlign: "center" }} >
                                                            <p>Price</p>
                                                        </Col>
                                                        <Col md='3' style={{ textAlign: "center" }} >
                                                            <p>Total</p>
                                                        </Col>
                                                    </Row></Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className='pt-0 mt-0'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#F7E3CB' }}
                                                                />
                                                                <p className='mx-3'>{'Light'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >4</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$6.60</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$26.40</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='pt-2'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#D79764' }}
                                                                />
                                                                <p className='mx-3'>{'Medium'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >4</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$6.60</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$26.40</p>
                                                        </Col>
                                                    </Row>
                                                    <Row className='pt-2'>
                                                        <Col md='4' >
                                                            <div className="shade-present mx-3">
                                                                <div
                                                                    className="shade-color"
                                                                    style={{ backgroundColor: '#AF6D46' }}
                                                                />
                                                                <p className='mx-3'>{'Dark'}</p>
                                                            </div>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }}    >
                                                            <p >4</p>
                                                        </Col>
                                                        <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$6.60</p>
                                                        </Col>
                                                        <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                            <p>$26.40</p>
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='mt-1'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p className='px-4'><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "start" }} >
                                                <p className='px-4'>12</p>
                                            </Col>
                                            <Col md='2' className='px-4' style={{ textAlign: "start" }} >
                                                <p>$6.60</p>
                                            </Col>
                                            <Col md='3' className='px-4' style={{ textAlign: "start" }} >
                                                <p>$79.20</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* Microblade Pen */}
                            <div className='content-item mb-2'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img style={{ marginTop: '40px' }} alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1674672882194-c2fc52d2-fe11-40e8-b40d-00e6f79c9e0a.webp'} className="content-img" />

                                    </Col>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='12' >
                                                <h4 className='mx-3'>Microblade Pen</h4>
                                            </Col>
                                            {/* <Col md='3' >
                                                <p className='paragraph'>Qty: <strong>9</strong></p>
                                            </Col>
                                            <Col md='3' >
                                                <p className='paragraph'>Price: <strong>$151.20</strong></p>
                                            </Col> */}
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Shade</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Quantity</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>Price</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Total</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='pt-0 mt-0'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#BE6C4B' }}
                                                    />
                                                    <p className='mx-3'>{'Light'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >4</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$12.00</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$48.0</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#665953' }}
                                                    />
                                                    <p className='mx-3'>{'Medium'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >4</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$12.00</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$48.0</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#3E3E3F' }}
                                                    />
                                                    <p className='mx-3'>{'Dark'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >4</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$12.00</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$48.0</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>12</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$12.00</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$144.00</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* PROBROW Pomade */}
                            <div className='content-item mb-2'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img style={{ marginTop: '40px' }} alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1674666757668.webp'} className="content-img" />

                                    </Col>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='12' >
                                                <h4 className='mx-3'>PROBROW Pomade</h4>
                                            </Col>
                                            {/* <Col md='3' >
                                                <p className='paragraph'>Qty: <strong>9</strong></p>
                                            </Col>
                                            <Col md='3' >
                                                <p className='paragraph'>Price: <strong>$151.20</strong></p>
                                            </Col> */}
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Shade</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Quantity</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>Price</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Total</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='pt-0 mt-0'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#6F5347' }}
                                                    />
                                                    <p className='mx-3'>{'Brown'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >5</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$6.00</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$30.0</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#693B2B' }}
                                                    />
                                                    <p className='mx-3'>{'Chocolate'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >5</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$6.00</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$30.0</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#44312B' }}
                                                    />
                                                    <p className='mx-3'>{'Ebony'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >5</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$6.00</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$30.0</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>15</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$6.00</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$90.00</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* True Brow Couplet */}
                            <div className='content-item mb-2'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img style={{ marginTop: '40px' }} alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1681386050518-ba49c22a-9b37-4da7-ae4b-754cb68f825b.png'} className="content-img" />

                                    </Col>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='12' >
                                                <h4 className='mx-3'>True Brow Couplet</h4>
                                            </Col>
                                            {/* <Col md='3' >
                                                <p className='paragraph'>Qty: <strong>9</strong></p>
                                            </Col>
                                            <Col md='3' >
                                                <p className='paragraph'>Price: <strong>$151.20</strong></p>
                                            </Col> */}
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Shade</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Quantity</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>Price</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Total</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='pt-0 mt-0'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#BE6C4B' }}
                                                    />
                                                    <p className='mx-3'>{'Light'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p>5</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$6.80</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$34.0</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#665953' }}
                                                    />
                                                    <p className='mx-3'>{'Medium'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p>5</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$6.80</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$34.0</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#3E3E3F' }}
                                                    />
                                                    <p className='mx-3'>{'Dark'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p>5</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$6.80</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$34.0</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>15</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$6.80</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$102.00</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* True Brow Pro-Finish Definer Pencil */}
                            <div className='content-item mb-2'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img style={{ marginTop: '40px' }} alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1674669834087.webp'} className="content-img" />

                                    </Col>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='12' >
                                                <h4 className='mx-3'>True Brow Pro-Finish Definer Pencil</h4>
                                            </Col>
                                            {/* <Col md='3' >
                                                <p className='paragraph'>Qty: <strong>9</strong></p>
                                            </Col>
                                            <Col md='3' >
                                                <p className='paragraph'>Price: <strong>$151.20</strong></p>
                                            </Col> */}
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Shade</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Quantity</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>Price</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Total</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='pt-0 mt-0'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#BE6C4B' }}
                                                    />
                                                    <p className='mx-3'>{'Light'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p>5</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.25</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$26.25</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#665953' }}
                                                    />
                                                    <p className='mx-3'>{'Medium'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p>5</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.25</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$26.25</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#3E3E3F' }}
                                                    />
                                                    <p className='mx-3'>{'Dark'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p>5</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.25</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$26.25</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>15</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.25</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$78.75</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* True Nude Lipstick */}
                            <div className='content-item mb-2'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img style={{ marginTop: '40px' }} alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1674669361922.webp'} className="content-img" />

                                    </Col>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='12' >
                                                <h4 className='mx-3'>True Nude Lipstick</h4>
                                            </Col>
                                            {/* <Col md='3' >
                                                <p className='paragraph'>Qty: <strong>9</strong></p>
                                            </Col>
                                            <Col md='3' >
                                                <p className='paragraph'>Price: <strong>$151.20</strong></p>
                                            </Col> */}
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Shade</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Quantity</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>Price</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Total</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='pt-0 mt-0'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#B20015' }}
                                                    />
                                                    <p className='mx-3'>{'Rodeo Rouge'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >3</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.95</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$17.85</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#C57374' }}
                                                    />
                                                    <p className='mx-3'>{'9021Oh'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >3</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.95</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$17.85</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#D58585' }}
                                                    />
                                                    <p className='mx-3'>{'BH Bare'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >3</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.95</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$17.85</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>9</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.95</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$53.55</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* Rodeo Rouge Collection */}
                            <div className='content-item mb-2'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img style={{ marginTop: '40px' }} alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1674565009422.png'} className="content-img" />

                                    </Col>
                                    <Col md='10'>
                                        <Row>
                                            <Col md='12' >
                                                <h4 className='mx-3'>Rodeo Rouge Collection</h4>
                                            </Col>
                                            {/* <Col md='3' >
                                                <p className='paragraph'>Qty: <strong>9</strong></p>
                                            </Col>
                                            <Col md='3' >
                                                <p className='paragraph'>Price: <strong>$151.20</strong></p>
                                            </Col> */}
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Shade</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Quantity</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>Price</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Total</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='pt-0 mt-0'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#B20015' }}
                                                    />
                                                    <p className='mx-3'>{'Rodeo Rouge'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >3</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.95</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$17.85</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#FA0102' }}
                                                    />
                                                    <p className='mx-3'>{'BH Bombshell'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >3</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.95</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$17.85</p>
                                            </Col>
                                        </Row>
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#770000' }}
                                                    />
                                                    <p className='mx-3'>{'Porn Star'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >3</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.95</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$17.85</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>9</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.95</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$53.55</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* Sita Kajal Intense Eyeliner */}
                            <div className='content-item align-content-center'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1674582154459.webp'} className="content-img pt-3" />

                                    </Col>
                                    <Col md='10'>
                                        <Row className=''>
                                            <Col md='12' >
                                                <h4 className='mx-3 mt-1'>Sita Kajal Intense Eyeliner</h4>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Shade</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Quantity</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>Price</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Total</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <div
                                                        className="shade-color"
                                                        style={{ backgroundColor: '#060709' }}
                                                    />
                                                    <p className='mx-3'>{'Midnight Masquerade'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >5</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.60</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$28.00</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>5</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$5.60</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$28.00</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* Bombshell Brow Gel */}
                            <div className='content-item align-content-center'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1674572042715.webp'} className="content-img pt-3" />

                                    </Col>
                                    <Col md='10'>
                                        <Row className=''>
                                            <Col md='12' >
                                                <h4 className='mx-3 mt-1'>Bombshell Brow Gel</h4>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Shade</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Quantity</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>Price</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Total</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <p className=''>{'No Shade'}</p>

                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >3</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$3.15</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$9.45</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>3</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$3.15</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$9.45</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                            {/* Bombshell Brow & Lash Serum */}
                            <div className='content-item align-content-center'>
                                <Row className='w-100 align-content-center'>
                                    <Col md='2'>
                                        <img alt="Starter Pack" src={'https://storage.googleapis.com/cdn-browbarbyreema/product/1681406798765-c293d174-48f3-4a13-8799-c5865c6189ea.jpg'} className="content-img pt-3" />

                                    </Col>
                                    <Col md='10'>
                                        <Row className=''>
                                            <Col md='12' >
                                                <h4 className='mx-3 mt-1'>Bombshell Brow & Lash Serum</h4>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Shade</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Quantity</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>Price</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>Total</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginTop: '0px' }} />
                                        <Row className='pt-2'>
                                            <Col md='4' >
                                                <div className="shade-present mx-3">
                                                    <p className=''>{'No Shade'}</p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >10</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$6.30</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$63.00</p>
                                            </Col>
                                        </Row>
                                        <hr style={{ width: '95%', marginLeft: '15px', marginBottom: '3px' }} />
                                        <Row>
                                            <Col md='4' >
                                                <div className="shade-present mx-3 mb-0">
                                                    <p ><strong>Total</strong></p>
                                                </div>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }}    >
                                                <p >10</p>
                                            </Col>
                                            <Col md='2' style={{ textAlign: "center" }} >
                                                <p>$6.30</p>
                                            </Col>
                                            <Col md='3' style={{ textAlign: "center" }} >
                                                <p>$63.00</p>
                                            </Col>
                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                        </div>
                        {/* <div className='one-price'>
                            <p>1x Bombshell Brow Kit</p>
                            <p>$10.50</p>
                        </div> */}
                        <div className='pt-3'>
                            <Col lg="6" className='add-to-cart'>

                                <Col lg="5">
                                    <div className='quantity-field float-start' >
                                        <button
                                            className="value-button decrease-button"
                                            onClick={decrement}
                                        >-</button>
                                        <div class="number">{count}</div>
                                        <button
                                            className="value-button increase-button"
                                            onClick={increment}
                                        >+
                                        </button>
                                    </div>
                                </Col>
                                <Col lg="7">
                                <Button className='kit-btn' onClick={handleAddToCart}
                    disabled={apiCall}>
                    Add to Cart
                  </Button>
                                </Col>
                            </Col>
                        </div>
                        <div className='pt-4 pb-1'>
                            <h4>Retail Value: <strong>$2,612.00</strong></h4>
                            <h4>Wholesale Value: <strong>$1,050.00</strong></h4>
                            <p>
                                Price includes 120 Products and outer packaging
                            </p>
                        </div>
                        <div className='details pt-2'>
                            <p>
                                <span>Details: </span>Includes 120 award winning branded brow, face and lip products for retail sale.
                            </p>
                            <span>Features: </span>
                            <ul>
                                <li>Award winning luxurious Bombshell Brow Kit</li>
                                <li>Luxurious Microblade Pen that mimics the look of actual brow hairs</li>
                                <li>Brow duo couplet that perfectly defines the brows</li>
                                <li>Beverly Hills inspired sensual True Nude Lipstick Collection</li>
                                <li>Includes the long-lasting, deep black Kohl formula</li>
                            </ul>
                            <hr />
                            <p className=''>
                                <span>Category: </span>Prepacks
                            </p>
                            {/* <p>
                                <span>Details: </span>Completely customize the display card – your words, photos, layout, etc. We provide proof for your approval. Fast turnaround.
                            </p> */}

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BrowKit
