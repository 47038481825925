/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Button } from 'react-bootstrap';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import PhoneInput from "react-phone-number-input";
import { useSnackbar } from "notistack";
import {
  register,
  login,
  resendOtp,
  verifyOtp,
  loginVerifyEmail,
  forgotPassword,
} from "../../service/api";
import { useDispatch } from "react-redux";
import actions from "../../redux/user/actions";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useUpdateCart } from "../../hooks/useUpdateCart";
const Account = () => {
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [showRegisterPassword, setRegisterPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputReg, setInputReg] = useState({
    name: false,
    email: false,
    password: false,
    phoneNumber: false
  });
  const [input, setInput] = useState({ name: "", email: "", password: "", phoneNumber: 0, });

  const [loginInput, setLoginInput] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [inputLogin, setInputLogin] = useState({
    name: false,
    email: false,
    password: false,
  });

  const [phoneNumber, setPhoneNumber] = useState(0);
  const [otp, setOtp] = useState(null);
  const [otpError, setOtpError] = useState(false);

  const tabs = {
    REGISTER: "REGISTER",
    VERIFY: "VERIFY",
  };

  const loginTabs = {
    LOGIN: "LOGIN",
    VERIFY: "VERIFY",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    SEND_OTP: "SEND_OTP",
    SEND_PASSWORD: "SEND_PASSWORD",
  };

  const [activeTab, setactiveTab] = useState(tabs.REGISTER);
  const [loginActiveTab, setLoginActiveTab] = useState(loginTabs.LOGIN);
  const { setUpdateCart } = useUpdateCart();
  const onRegchange = (e) => {
    console.log('e: ', e);
    setInput({ ...input, [e.target.name]: e.target.value });
    setInputReg({ ...inputReg, [e.target.name]: false });
  };
  const onLogchange = (e) => {
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
    setInputLogin({ ...inputLogin, [e.target.name]: false });
  };

  function emailValidation(body) {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(body) === false) {
      return false;
    }
    return true;
  }

  const [loadingReg, setloadingReg] = useState(false);

  const registerForm = (e) => {
    e.preventDefault();
    if (!input?.name) {
      setInputReg({ ...inputReg, name: true });
      return;
    }
    if (phoneNumber === 0 || !input?.phoneNumber) {
      setInputReg({ ...inputReg, phoneNumber: true });
      return;
    } else {
      setInputReg({ ...inputReg, phoneNumber: false });
    }
    if (!input?.email) {
      setInputReg({ ...inputReg, email: true });
      return;
    }
    if (!emailValidation(input?.email)) {
      setInputReg({ ...inputReg, email: true });
      return;
    }
    if (!input?.password) {
      setInputReg({ ...inputReg, password: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("name", input.name);
    submitForm.append("email", input.email);
    submitForm.append("password", input.password);
    submitForm.append("mobile", input.phoneNumber);
    submitForm.append("role", "63bbbbbd72d26924462631bb");
    setloadingReg(true);
    register(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          dispatch(actions.setUser(res?.data?.userData));
          dispatch(actions.setToken(res?.data?.token));
          localStorage.setItem("authenticated", true);
          localStorage.setItem("token", res?.data?.token);
          setactiveTab(tabs.VERIFY);
          setLoginActiveTab(loginTabs.LOGIN);
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setloadingReg(false);
      });
  };

  const resendOtpFun = (e) => {
    e.preventDefault();
    if (!input?.email) {
      enqueueSnackbar("Please enter email");
      return;
    }
    if (!emailValidation(input?.email)) {
      enqueueSnackbar("Please enter valid email");
      return;
    }
    const submitForm = new FormData();
    submitForm.append("email", input.email);
    setloadingReg(true);
    resendOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message);
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message);
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message);
      })
      .finally((res) => {
        setloadingReg(false);
      });
  };

  const sendOtpReg = (e) => {
    e.preventDefault();
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true);
      return;
    }
    const submitForm = new FormData();
    submitForm.append("code", otp);
    console.log("send otp");
    setloadingReg(true);
    verifyOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          navigate("/dashboard");
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setOtp("");
        setloadingReg(false);
      });
  };

  const [loading, setLoading] = useState(false);
  const loginForm = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!loginInput?.password) {
      setInputLogin({ ...inputLogin, password: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    submitForm.append("password", loginInput.password);
    submitForm.append("role", "63bbbbbd72d26924462631bb");  
    setLoading(true);
    login(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          setUpdateCart(true);
          dispatch(actions.setUser(res?.data?.userData));
          dispatch(actions.setToken(res?.data?.token));
          localStorage.setItem("authenticated", true);
          localStorage.setItem("token", res?.data?.token);

          if (!res?.data?.userData?.isEmailVerified) {
            loginVerifyEmail({ email: loginInput.email }).then((res) => {
              console.log("verify email--->", res);
            });
            setLoginActiveTab(loginTabs.VERIFY);
            setactiveTab(tabs.REGISTER);
            return;
          } else {
            dispatch(actions.setLoggedIn(true));
            navigate("/dashboard");
          }
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const resendLoginOtpFun = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    setLoading(true);
    resendOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const sendOtp = (e) => {
    e.preventDefault();
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true);
      return;
    }
    const submitForm = new FormData();
    submitForm.append("code", otp);
    console.log("send otp");
    setLoading(true);
    verifyOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          navigate("/dashboard");
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setOtp("");
        setLoading(false);
      });
  };

  const forgotPasswordFun = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!loginInput?.password) {
      // enqueueSnackbar("Please enter your password");
      setInputLogin({ ...inputLogin, password: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    submitForm.append("password", loginInput.password);
    setLoading(true);
    forgotPassword(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          setLoginActiveTab(loginTabs.LOGIN);
          setLoginInput({ ...loginInput, name: "", email: "", password: "" });
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const [otpMatch, setOtpMatch] = useState(null);
  const sendOtpToMail = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    // if (!loginInput?.password) {
    //   // enqueueSnackbar("Please enter your password");
    //   setInputLogin({ ...inputLogin, password: true })
    //   return
    // }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    // submitForm.append("password", loginInput.password);
    setLoading(true);
    loginVerifyEmail(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          setOtpMatch(res?.data);
          setLoginActiveTab(loginTabs.SEND_OTP);
          // setLoginInput({ ...loginInput, name: '', email: '', password: '' })
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message);
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const verifyOtpToChangePassword = (e) => {
    e.preventDefault();
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true);
      return;
    }
    if (otp === otpMatch) {
      setLoginActiveTab(loginTabs.SEND_PASSWORD);
      enqueueSnackbar("OTP verified successfully", { variant: "success" });
    } else {
      enqueueSnackbar("The OTP enetered is incorrect", { variant: "error" });
    }
  };

  return (
    <section className="account ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>Customer Account | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <Row>
          {/* LOGIN FORM */}
          {loading ? (
            <Col
              lg="6"
              sm="12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </Col>
          ) : loginActiveTab === loginTabs.LOGIN ? (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3>Login to your Account</h3>
                <p>
                  If you are a registered user, please enter your email and
                  password.
                </p>
                <form className="login-form">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={loginInput?.email}
                      onChange={onLogchange}
                    />
                    {inputLogin?.email ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {inputLogin?.email ? "Email is required" : ""}
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      value={loginInput?.password}
                      onChange={onLogchange}
                      type={showLoginPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder="Password"
                    />
                    <Button
                      className="password-icon"
                      onClick={() => setShowLoginPassword(!showLoginPassword)}
                    >
                      {showLoginPassword ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </Button>
                    {inputLogin?.password ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {inputLogin?.password ? "password is required" : ""}
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="forgot-pass">
                    <span
                      onClick={() =>
                        setLoginActiveTab(loginTabs.FORGOT_PASSWORD)
                      }
                    >
                      Forgot Password ?
                    </span>
                  </div>
                  <button className="login-btn" onClick={loginForm}>
                    Log in
                  </button>
                </form>
              </div>
            </Col>
          ) : loginActiveTab === loginTabs.FORGOT_PASSWORD ? (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3 className="mb-30">Forgot your password?</h3>
                <form className="login-form">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      value={loginInput?.email}
                      onChange={onLogchange}
                      className="form-control"
                      placeholder="Your Email"
                    />
                    {inputLogin?.email ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {inputLogin?.email ? "Email is required" : ""}
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="forgot-pass">
                    <p
                      onClick={() => {
                        setLoginActiveTab(loginTabs.LOGIN);
                      }}
                    >
                      Sign in to your account
                    </p>
                  </div>
                  <button className="login-btn" onClick={sendOtpToMail}>
                    Send OTP
                  </button>
                </form>
              </div>
            </Col>
          ) : loginActiveTab === loginTabs.SEND_OTP ? (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3 className="mb-30">Verify Your Account</h3>
                <form className="login-form">
                  <div className="form-group">
                    <input
                      type="number"
                      name="otp"
                      value={loginInput?.otp}
                      onChange={(e) => {
                        setOtp(e.target.value);
                      }}
                      className="form-control"
                      placeholder="Enter OTP"
                    />
                    {otp ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {otp === null ? "Please enter OTP" : ""}
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>

                  <span className="forgot-pass">
                    <p onClick={sendOtpToMail}>Resend OTP</p>
                  </span>

                  <button
                    className="login-btn"
                    onClick={verifyOtpToChangePassword}
                  >
                    Verify OTP
                  </button>
                </form>
              </div>
            </Col>
          ) : loginActiveTab === loginTabs.SEND_PASSWORD ? (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3 className="mb-30">Enter Credentials</h3>
                <form className="login-form">
                  <div className="form-group">
                    <input
                      value={loginInput?.password}
                      onChange={onLogchange}
                      type={showForgotPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder="Password"
                    />
                    <Button
                      className="password-icon"
                      onClick={() => setShowForgotPassword(!showForgotPassword)}
                    >
                      {showForgotPassword ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </Button>
                    {inputLogin?.password ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {inputLogin?.password ? "password is required" : ""}
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="forgot-pass">
                    <p
                      onClick={() => {
                        setLoginActiveTab(loginTabs.LOGIN);
                      }}
                    >
                      Sign in to your account
                    </p>
                  </div>
                  <button className="login-btn" onClick={forgotPasswordFun}>
                    Change password
                  </button>
                </form>
              </div>
            </Col>
          ) : (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3 className="mb-30">Verify Email</h3>

                <form className="login-form">
                  <div className="form-group">
                    <input
                      value={otp}
                      onChange={(e) => {
                        setOtpError(false);
                        setOtp(e.target.value);
                      }}
                      type="number"
                      name="otp"
                      className="form-control"
                      placeholder="Enter 6 digit OTP"
                    />
                    {otpError ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {otpError ? "Please enter OTP" : ""}
                      </small>
                    ) : (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {otpError ? "Please enter OTP" : ""}
                      </small>
                    )}
                    <span className="forgot-pass">
                      <p onClick={resendLoginOtpFun}>Resend OTP</p>
                    </span>
                  </div>
                  <button className="login-btn" onClick={sendOtp}>
                    Verify Email
                  </button>
                </form>
              </div>
            </Col>
          )}

          {/* REGISTER FORM */}
          {loadingReg ? (
            <Col
              lg="6"
              sm="12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </Col>
          ) : activeTab === tabs.REGISTER ? (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3 className="mb-30">Create an Account</h3>
                <p className="mb-30">
                  Create an account so that you can checkout quickly, manage
                  preferences, save your addresses, track your orders, and more!
                </p>
                <form className="login-form">
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      value={input?.name}
                      onChange={onRegchange}
                    />
                    {inputReg?.name ? <small
                      className="validation-error"
                      style={{ color: "red", marginBottom: '0px' }}
                    >
                      {inputReg?.name ? "Name is required" : ""}
                    </small> : <></>}
                  </div>

                  <div className="form-group">
                    <PhoneInput
                      placeholder="Phone"
                      defaultCountry="US"
                      className="phone-number-select"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e);
                        onRegchange({
                          target: { name: "phoneNumber", value: e },
                        });
                      }}
                    />
                    {inputReg?.phoneNumber ? <small
                      className="validation-error"
                      style={{ color: "red", marginBottom: '0px' }}
                    >
                      {inputReg?.phoneNumber ? "Phone number is required" : ""}
                    </small> : <></>}
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={input?.email}
                      onChange={onRegchange}
                    />
                    {inputReg?.email ? <small
                      className="validation-error"
                      style={{ color: "red", marginBottom: '0px' }}
                    >
                      {inputReg?.email ? "email is required" : ""}
                    </small> : <></>}
                  </div>

                  <div className="form-group">
                    <input
                      type={showRegisterPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      value={input?.password}
                      onChange={onRegchange}
                    />
                    <Button
                      className="password-icon"
                      onClick={() => setRegisterPassword(!showRegisterPassword)}
                    >
                      {showRegisterPassword ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </Button>
                    {inputReg?.password ? <small
                      className="validation-error"
                      style={{ color: "red", marginBottom: '0px' }}
                    >
                      {inputReg?.password ? "password is required" : ""}
                    </small> : <></>}
                  </div>

                  <button className="login-btn" onClick={registerForm}>
                    Register
                  </button>
                </form>
              </div>
            </Col>
          ) : (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3 className="mb-30">Verify Email</h3>

                <form className="login-form">
                  <div className="form-group">
                    <input
                      value={otp}
                      onChange={(e) => {
                        setOtpError(false);
                        setOtp(e.target.value);
                      }}
                      type="number"
                      name="otp"
                      className="form-control"
                      placeholder="Enter 6 digit OTP"
                    />
                    {otpError ? <small
                      className="validation-error"
                      style={{ color: "red", marginBottom: '0px' }}
                    >
                      {otpError ? "Please enter OTP" : ""}
                    </small> : <></>}
                    <h5
                      style={{
                        fontSize: "15px",
                        marginTop: "10px",
                        textAlign: "end",
                      }}
                    >
                      Didn't receive code?
                      <span
                        onClick={resendOtpFun}
                        style={{
                          fontSize: "15px",
                          cursor: "pointer",
                          marginLeft: "5px",
                          textDecoration: "underline",
                        }}
                      >
                        Request again
                      </span>
                    </h5>
                  </div>
                  <button className="login-btn" onClick={sendOtpReg}>
                    Verify Email
                  </button>
                </form>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </section>
  );
};

export default Account;