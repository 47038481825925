/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAllProductsWholeSale, addTocart } from "../../service/api";
/* IMAGE IMPORTS */
import ReactHtmlParser from "react-html-parser";
import { IMG_URL } from "../../config";
/* ICON IMPORTS */
import LockIcon from '../../assets/icons/lock-icon.png';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useUpdateCart } from "../../hooks/useUpdateCart";

function ProductImage({ product, loggedIn }) {
    const { enqueueSnackbar } = useSnackbar();

    const [activeImage, setactiveImage] = useState(null)
    const [color, setColor] = useState(null)
    const [productImages, setProductImages] = useState([])
    const [minQty, setMinQty] = useState(0)
    const [peakQty, setPeakQty] = useState(0)
    const { setUpdateCart } = useUpdateCart();

    useEffect(() => {
        // console.log("product", product)
        const tepQty = product?.quantity?.find(item => item?.color === color?._id)
        console.log("quantity", tepQty?.quantity)
        setPeakQty(tepQty?.quantity)

        let temp;
        if (color?._id && Array.isArray(product?.productImage))
            temp = product?.productImage?.find(item => item?.color === color?._id)
        else if (Array.isArray(product?.productImage))
            temp = product?.productImage?.length > 0 ? product?.productImage[0] : null
        else
            temp = product?.productImage
        let tempArr = []
        if (temp?.front?.length > 0)
            tempArr.push(temp?.front[0])
        if (temp?.back?.length > 0)
            tempArr.push(temp?.back[0])
        if (temp?.others?.length > 0)
            temp?.others?.forEach(item => tempArr.push(item))

        console.log("tempArr inside", tempArr)
        setProductImages(tempArr)
        if (tempArr?.length > 0)
            setactiveImage(tempArr[0])
    }, [color, product])

    const [bag, setBag] = useState({})

    function increment(item) {
        setBag((prevBag) => {
            // Check if the item exists in the bag
            const existingItem = prevBag[item._id];
            if (existingItem) {
                // If the item exists, update the quantity
                const updatedItem = {
                    ...existingItem,
                    quantity: existingItem.quantity + 1,
                };
                setMinQty((prevMinQty) => ({
                    ...prevMinQty,
                    [item._id]: updatedItem?.quantity,
                }));
                return {
                    ...prevBag,
                    [item._id]: updatedItem,
                };
            } else {
                // If the item doesn't exist, add it to the bag
                const newItem = {
                    product: product?._id,
                    color: item?._id,
                    quantity: 1,
                };
                setMinQty((prevMinQty) => ({
                    ...prevMinQty,
                    [item._id]: newItem?.quantity,
                }));
                return {
                    ...prevBag,
                    [item._id]: newItem,
                };
            }
        });
    }

    function decrement(item) {
        setBag((prevBag) => {
            // Check if the item exists in the bag
            const existingItem = prevBag[item?._id];
            if (existingItem) {
                // If the item exists and the quantity is greater than 1, decrease the quantity
                if (existingItem.quantity > 1) {
                    const updatedItem = {
                        ...existingItem,
                        quantity: existingItem.quantity - 1,
                    };
                    setMinQty((prevMinQty) => ({
                        ...prevMinQty,
                        [item._id]: updatedItem?.quantity,
                    }));
                    return {
                        ...prevBag,
                        [item._id]: updatedItem,
                    };
                } else {
                    // If the item exists and the quantity is 1, remove the item from the bag
                    const { [item._id]: removedItem, ...newBag } = prevBag;
                    setMinQty((prevMinQty) => ({
                        ...prevMinQty,
                        [item._id]: 0,
                    }));
                    return newBag;
                }
            } else {
                // If the item doesn't exist, do nothing
                return prevBag;
            }
        });
    }

    function handleQuantityChange(e, item) {
        const newQuantity = parseInt(e.target.value);

        console.log("newQuantity",newQuantity)
        let tempTier = "tier1";
        if (newQuantity < 100) {
          tempTier = "tier1";
        } else if (newQuantity < 300) {
          tempTier = "tier2";
        } else if (newQuantity > 300){
          tempTier = "tier3";
        }
        console.log("tempTier",tempTier)

        setBag((prevBag) => {
            const existingItem = prevBag[item._id];
            if (existingItem) {
                const updatedItem = {
                    ...existingItem,
                    quantity: newQuantity,
                };
                setMinQty((prevMinQty) => ({
                    ...prevMinQty,
                    [item._id]: updatedItem?.quantity,
                }));
                if (newQuantity === 0) {
                    const { [item._id]: removedItem, ...newBag } = prevBag;
                    return newBag;
                } else {
                    return {
                        ...prevBag,
                        [item._id]: updatedItem,
                    };
                }
            } else {
                if (newQuantity === 0) {
                    return prevBag;
                } else {
                    const newItem = {
                        product: product?._id,
                        color: item?._id,
                        quantity: newQuantity,
                        wholeSaleTier:tempTier
                    };
                    setMinQty((prevMinQty) => ({
                        ...prevMinQty,
                        [item._id]: newItem?.quantity,
                    }));
                    return {
                        ...prevBag,
                        [item._id]: newItem,
                    };
                }
            }
        });
    }

    useEffect(() => {
        console.log("bag", bag)
    }, [bag])

    const [cartLoading, setCartLoading] = useState(false)
    const [cartLoadingItem, setCartLoadingItem] = useState(null)
    const addToCartFun = (item) => {
        const tempQty = product?.quantity?.find(qitem => qitem?.color === item?._id)
        // console.log("product",product?.quantity)
        // console.log("quantity",tempQty?.quantity)
        // console.log("item",item)
        if (tempQty?.quantity < bag[item?._id]?.quantity) {
            enqueueSnackbar(`We have only ${tempQty?.quantity} pieces available for this color`, {
                variant: "error",
            });
            return
        }
        // console.log("minQty", minQty)
        const itemMinQty = minQty[item._id];
        if (itemMinQty >= product?.wholeSaleMinQuantity) {

            if (bag[item?._id]) {
                setCartLoading(true)
                setCartLoadingItem(item?._id)
                addTocart(bag[item?._id]).then(res => {
                    console.log(res)
                    const newBag = { ...bag };
                    delete newBag[item._id];
                    setMinQty((prevMinQty) => ({
                        ...prevMinQty,
                        [item._id]: 0,
                    }));
                    setBag(newBag);
                    setCartLoading(true)
                    setUpdateCart(true);
                    enqueueSnackbar("Your product has been added to cart successfully.", {
                        variant: "success",
                    });
                })
                    .catch(err => {
                        enqueueSnackbar("Something went wrong.", {
                            variant: "error",
                        });
                    })
                    .finally(res => {
                        setCartLoading(false)
                        setCartLoadingItem(null)
                    })
            }
            else {
                enqueueSnackbar("Please select quantity.", {
                    variant: "error",
                });
            }
        }
        else {
            enqueueSnackbar("Minimum quantity has to be " + product?.wholeSaleMinQuantity + " for this product", {
                variant: "error",
            });
        }
    }

    return (
        <div className="product-list">
            <div className="product-box">
                <div className="row">
                    {/* LEFTSIDE */}
                    <div className="col-xl-5 col-sm-12">
                        <div className="row images-list">
                            <div className="col-xl-12 col-sm-12">
                                <div className="primary-img">
                                    <img src={`${IMG_URL}${activeImage}`} alt="Buy" />
                                </div>
                            </div>
                            <div className="col-xl-12 col-sm-12">
                                <Swiper
                                    modules={[Scrollbar, Navigation, Pagination]}
                                    navigation={true}
                                    slidesPerView={5}
                                    spaceBetween={10}
                                >
                                    {productImages?.map((imgLink, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <div className="product-detail-img-slider">
                                                    <img
                                                        src={`${IMG_URL}${imgLink}`}
                                                        onClick={() => { setactiveImage(imgLink) }}
                                                        alt={"Buy " + imgLink}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}

                                </Swiper>
                            </div>
                        </div>
                    </div>

                    {/* RIGHTSIDE */}
                    <div className="col-xl-7 col-sm-12">
                        <div className="details">

                            {
                                loggedIn ?
                                    <>
                                        {/* PRODUCT NAME */}
                                        <h5>
                                            {product?.name}{" "}
                                            <span className="sku">
                                                (<b>SKU:</b> {product?.sku})
                                            </span>
                                        </h5>

                                        {/* SUGGESTED RETAIL PRICE */}
                                        <div className="d-flex align-items-center justify-content-between resp-flex">
                                            <h5 className="suggested-retail-price">
                                                <b>Suggested Retail Price:</b> ${product?.wholeSaleAmount?.suggestedPrice ?
                                                    product?.wholeSaleAmount?.suggestedPrice : product?.amount}
                                            </h5>
                                            <p className="minimum-order">
                                                <b>Minimum Order:</b> {product?.wholeSaleMinQuantity} Pieces
                                            </p>
                                        </div>

                                    </>
                                    : <>
                                      <h5>
                                            {product?.name}{" "}
                                            <span className="sku">
                                                (<b>SKU:</b> {product?.sku})
                                            </span>
                                        </h5>
                                        <div className="suggested-retail-price-blur-box">
                                            <div>

                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between resp-flex margin-top-negative">
                                            <h5 className="suggested-retail-price">
                                                <b>Suggested Retail Price:</b> $19.99
                                            </h5>
                                            <p className="minimum-order">
                                                <b>Minimum Order:</b> 15 Pieces
                                            </p>
                                        </div>
                                    </>
                            }




                            {/* DESCRIPTION AND INGREDIENTS */}
                            <Accordion className="mt-2">
                                <Accordion.Item
                                    eventKey="description"
                                    className="product-detail-description"
                                >
                                    <Accordion.Header className="product-detail-description-header">
                                        DESCRIPTION
                                    </Accordion.Header>
                                    <Accordion.Body className="product-detail-description-body">
                                        <p>
                                            {ReactHtmlParser(product?.description)}
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item
                                    eventKey="ingredients"
                                    className="product-detail-description"
                                >
                                    <Accordion.Header className="product-detail-description-header">
                                        INGREDIENTS
                                    </Accordion.Header>
                                    <Accordion.Body className="product-detail-description-body">
                                        <p>
                                            {ReactHtmlParser(product?.ingredients)}
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            {/* PRODUCT QUANTITY AND PRICE */}



                            {
                                loggedIn ? <table className="product-price">
                                    <thead className="price-table-head">
                                        <th>PRODUCT QUANTITY</th>
                                        <th>PRICE PER UNIT</th>
                                    </thead>
                                    <tbody className="price-table-body">
                                        <tr>
                                            <td>1 - 99</td>
                                            <td>${product?.wholeSaleAmount?.tier1 ? product?.wholeSaleAmount?.tier1 : product?.wholeSaleAmount}</td>
                                        </tr>
                                        <tr>
                                            <td>100 - 199</td>
                                            <td>${product?.wholeSaleAmount?.tier2 ? product?.wholeSaleAmount?.tier2 : product?.wholeSaleAmount}</td>
                                        </tr>
                                        <tr style={{ borderBottom: "0px solid transparent" }}>
                                            <td>200+</td>
                                            <td>
                                                <a
                                                    href="tel:+13103609883"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    Call us
                                                </a>{" "}
                                                for quote
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> :
                                    <table className="product-price">
                                        <thead className="price-table-head">
                                            <th>PRODUCT QUANTITY</th>
                                            <th>PRICE PER UNIT</th>
                                        </thead>
                                        <tbody className="price-table-body">
                                            <tr>
                                                <td>1 - 99</td>
                                                <td>${product?.wholeSaleAmount?.tier1}</td>
                                            </tr>
                                            <tr>
                                                <td>100 - 200</td>
                                                <td>${product?.wholeSaleAmount?.tier2}</td>
                                            </tr>
                                            <tr style={{ borderBottom: "0px solid transparent" }}>
                                                <td>200+</td>
                                                <td>
                                                    <a
                                                        href="tel:+13103609883"
                                                        style={{ textDecoration: "none" }}
                                                    >
                                                        Call us
                                                    </a>{" "}
                                                    for quote
                                                </td>
                                            </tr>
                                        </tbody>
                                        <div className="blur-box">
                                            <div>
                                                <img
                                                    src={LockIcon}
                                                    alt="Please login to access the prices"
                                                />
                                                <p>Please login to your account access the wholesale prices.</p>
                                                <p className="mt-0">If you don't have an account, <Link to='/registration-landing' style={{ textDecoration: 'none' }}>Create an account now</Link></p>
                                            </div>
                                        </div>
                                    </table>
                            }


                            {/* SHADES SELECTION */}
                            <div className="shades-selection">
                                {
                                    product?.color?.map((item, i) => {
                                        return (
                                            <div className="shade-row mt-15" key={i}>
                                                <div className="shade-present">
                                                    <div
                                                        onClick={() => setColor(item)}
                                                        className="shade-color"
                                                        style={{ backgroundColor: item?.code }}
                                                    />
                                                    <p>{item?.name}</p>
                                                </div>
                                                <div className="quantity-counter">
                                                    {/* <Button className="minus-counter" onClick={() => decrement(item)}>
                                                        -
                                                    </Button>
                                                    <div className="count">
                                                        <p>{bag[item?._id] ? bag[item?._id].quantity : 0}</p>
                                                    </div>
                                                    <Button className="plus-counter" onClick={() => increment(item)}>
                                                        +
                                                    </Button> */}
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        value={bag[item._id] ? bag[item._id].quantity : 0}
                                                        onChange={(e) => handleQuantityChange(e, item)}
                                                    // min="1"
                                                    />
                                                </div>
                                            { loggedIn ? <Button disabled={cartLoading && cartLoadingItem === item?._id} className="add-to-cart" onClick={() => addToCartFun(item)}>{
                                                    (cartLoading && cartLoadingItem === item?._id) ? "Adding to Cart" : "Add to Cart"}</Button> : <Button disabled={true} className="add-to-cart" >{
                                                        (cartLoading && cartLoadingItem === item?._id) ? "Adding to Cart" : "Add to Cart"}</Button>}
                                                
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductImage