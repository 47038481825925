import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from 'react-bootstrap';
import { Provider } from "react-redux";
import { SnackbarProvider } from 'notistack';
import store from '../redux/store';

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";

/* PAGES */
import Layout from '../layout/index';
import Press from "../pages/press";
import Affiliate from "../pages/affiliate";
import Returns from "../pages/returns";
import TermsOfUse from "../pages/terms-of-use";
import PrivacyPolicy from "../pages/privacy-policy";
import ContactUs from "../pages/contact-us";
import NewArrivals from "../pages/new-arrivals";
import ShopProducts from "../pages/shop";
import ShopBrows from '../pages/brow';
import ShopEyes from '../pages/eye';
import ShopLips from '../pages/lips';
import ShopFace from '../pages/face';
import ShopSets from "../pages/sets";
import ShopBestsellers from '../pages/bestsellers';
import DIYBrows from "../pages/diy-brows";
import Account from "../pages/account";
import Cart from "../pages/cart";
import Checkout from "../pages/checkout";
import AboutUs from "../pages/about-us";
import UserDashboard from '../pages/dashboard';
import Wishlist from "../pages/wishlist";
import ProductDetail from "../pages/product-detail";
import ReturnOrders from "../pages/return-orders";
import ThankYou from '../pages/thank-you';
import BrowQuiz from "../pages/brow-quiz";
import OrderCancelled from '../pages/order-cancelled';
import NoPageFound from "../pages/404";
import OrderDetail from "../pages/orderDetail";
import NewShopPage from "../pages/shop/new-shop";
import ProductDistributor from "../pages/account/registration-pages/product-distributor";
import BeautyProfessional from "../pages/account/registration-pages/beauty-professional";
import PrivateLabel from "../pages/account/registration-pages/private-label";
import RegistrationLanding from "../pages/account/registration-landing";
import ResaleCertificates from "../pages/resale-certificates";
import WholesaleShop from "../pages/shop/wholesale-shop";
import LoginPage from "../pages/account/new-index"
import BrowKit from "../pages/brow-kit";
import DisplayCase from "../pages/display-case";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const WebsiteRoutes = () => {
  return (
    <Provider store={store}>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ThemeProvider
          breakpoints={["xl", "lg", "md", "sm"]}
          minBreakpoint="sm"
        >
          <div className="app">
            <Router>
              <ScrollToTop />
              <Layout>
                <Routes>
                  <Route path="/" element={<RegistrationLanding />} />
                  <Route path="/product-detail" element={<ProductDetail />} />
                  <Route path="/press" element={<Press />} />
                  <Route path="/affiliate-sign-up" element={<Affiliate />} />
                  <Route path="/returns" element={<Returns />} />
                  <Route path="/terms-of-use" element={<TermsOfUse />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route path="/new-arrivals" element={<NewArrivals />} />
                  <Route path="/shop" element={<ShopProducts />} />
                  <Route path="/new-shop" element={<NewShopPage />} />
                  <Route path="/products/:id" element={<ShopProducts />} />
                  <Route path="/brow-collection" element={<ShopBrows />} />
                  <Route path="/eye-collection" element={<ShopEyes />} />
                  <Route path="/lips-collection" element={<ShopLips />} />
                  <Route path="/face-collection" element={<ShopFace />} />
                  <Route path="/sets-collection" element={<ShopSets />} />
                  <Route path="/bestsellers" element={<ShopBestsellers />} />
                  <Route path="/diy-brows" element={<DIYBrows />} />
                  <Route path="/account" element={<Account />} />
                  <Route path="/cart" element={<Cart />} />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/about" element={<AboutUs />} />
                  <Route path="/dashboard" element={<UserDashboard />} />
                  <Route path="/wishlist" element={<Wishlist />} />
                  <Route path="/return-orders" element={<ReturnOrders />} />
                  <Route path="/thank-you/:id" element={<ThankYou />} />
                  <Route path="/brow-quiz" element={<BrowQuiz />} />
                  <Route path="/order-cancel" element={<OrderCancelled />} />
                  <Route path="/order-detail/:id" element={<OrderDetail />} />
                  <Route path="/registration-landing" element={<RegistrationLanding />} />
                  <Route path="/resale-certificates" element={<ResaleCertificates />} />
                  <Route path="/wholesale-shop" element={<WholesaleShop />} />
                  <Route path="/salon-kit" element={<BrowKit />} />
                  <Route path="/display-case" element={<DisplayCase />} />
                  <Route
                    path="/product-distributor"
                    element={<ProductDistributor />}
                  />
                  <Route
                    path="/login"
                    element={<LoginPage />}
                  />
                  <Route
                    path="/beauty-professional"
                    element={<BeautyProfessional />}
                  />
                  <Route path="/private-label" element={<PrivateLabel />} />
                  <Route path="/404" element={<NoPageFound />} />
                  <Route path="*" exact={true} element={<NoPageFound />} />
                </Routes>
              </Layout>
            </Router>
          </div>
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  );
};

export default WebsiteRoutes;