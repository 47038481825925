/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Accordion, Button, Modal, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import Rating from "@mui/material/Rating";
import { AiOutlineStar, AiFillStar, AiFillDelete } from "react-icons/ai";
import ReactPaginate from "react-paginate";

import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { IMG_URL } from "../../config";
import { getProductById, postRating, getRating } from "../../service/api";
import { addTocart } from "../../service/api";
import SpinnerComponent from "../../components/Spinner";
import ReactHtmlParser from "react-html-parser";
import StarIcon from "@mui/icons-material/Star";
import { useSnackbar } from "notistack";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";

/* SWIPER STYLES */
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";

/* IMAGES IMPORT */
import Detail01 from "../../assets/product-detail/detail-02.webp";
import Detail02 from "../../assets/product-detail/detail-01.png";
import Detail03 from "../../assets/product-detail/detail-03.webp";
import Detail04 from "../../assets/product-detail/detail-04.webp";
import Detail05 from "../../assets/product-detail/detail-05.webp";
import { useUpdateCart } from "../../hooks/useUpdateCart";
import actions from "../../redux/products/actions";
import ProductDetailLoading from "../../components/detail-loading";

const options = [
    { value: "Date", label: "Date" },
    { value: "Rating", label: "Rating" },
    { value: "Helpfulness", label: "Helpfulness" },
];

function BrowKit() {
  const [count, setCount] = useState(1);
    const [reviewModal, setReviewModal] = useState(false);
    const [images, setImages] = useState([]);
    const [tempImg, settempImg] = useState([]);
    const { selectedProductId, selectedProduct, selectedColorId, cart } =
        useSelector((state) => state.products);
    const [apiCall, setApiCall] = useState("");


    const [file, setFile] = useState(null);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    let previewUrl = null;
    if (file) {
        previewUrl = URL.createObjectURL(file);
    }

    /* QUANTITY INCREMENT FUNCTION */
    function increment() {
        setCount(function (prevCount) {
            return (prevCount += 1);
        });
    }

    /* QUANTITY DECREMENT FUNCTION */
    function decrement() {
        setCount(function (prevCount) {
            if (prevCount > wholeSaleMinQuantity) {
                return (prevCount -= 1);
            } else {
                return (prevCount = wholeSaleMinQuantity);
            }
        });
    }

    const [ratings, setRatings] = useState([]);
    const [rpage, setrPage] = useState(1);
    const [rPageSize, setrPageSize] = useState(5);
    const [totalRPage, setTotalrPage] = useState(0);
    const [sortBy, setSortBy] = useState("DATE");
    const [avgReview, setAvgReview] = useState(0)
    const [wholeSaleMinQuantity, setWholeSaleMinQuantity] = useState(0)

  

    /* IMAGE ONCHANGE FUNCTION */
    const onChange = (imageList) => {
        setImages(imageList);
    };

    const [activeImage, setActiveImage] = useState(null);
    const [productDetail, setProductDetail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [colorIdIn, setcolorIdIn] = useState(null);
    const { loggedIn } = useSelector((state) => state.user);
    const { enqueueSnackbar } = useSnackbar();
    const { setUpdateCart } = useUpdateCart();
    const dispatch = useDispatch();

    /* USE EFFECT FUNCTION */
    useEffect(() => {
        setLoading(true);
        setcolorIdIn("647e3cfc6aea560ceac57293");
        getProductById("647e3cb36aea560ceac5728a")
            .then((res) => {
                // console.log("res", res);
                setProductDetail(res?.data);
                setWholeSaleMinQuantity(res?.data?.productData?.wholeSaleMinQuantity)
                setCount(wholeSaleMinQuantity)
            })
            .finally((res) => {
                setLoading(false);
            });
        window.scrollTo(0, 0);
    }, [selectedProduct, selectedProductId, selectedColorId]);

    const [showSelectedColor, setShowSelectedColor] = useState(null);
    const [selectedImg, setSelectedImg] = useState("");

    /* USE EFFECT FUNCTION */
    useEffect(() => {

        let data1 = productDetail?.productData?.quantity?.filter(
            (colorId) => colorId?.color?._id === colorIdIn
        );
        // console.log("data1--->", data1);
        if (data1) setShowSelectedColor(data1[0]);
        let data2 = productDetail?.productData?.productImage?.filter(
            (colorId) => colorId?.color?._id === colorIdIn
        );
        // console.log("data1--->", data2);

        if (data2) {
            setSelectedImg(data2[0]);
            setActiveImage(data2[0]?.front[0]);
        }
        return () => {
            settempImg([]);
        };
    }, [colorIdIn, productDetail]);

    const [rating, setRating] = useState(0);
    const [price, setPrice] = useState(0);
    const [qty, setQty] = useState(0);

    const [ratingInput, setRatingInput] = useState({
        nickName: "",
        summary: "",
        review: "",
        email: "",
    });

    /* RATINGS CHANGE FUNCTION */
    const onChangeRating = (e) => {
        setRatingInput({ ...ratingInput, [e.target.name]: e.target.value });
    };

    /* RATINGS FUNCTION */
    const saveRating = () => {
        if (!rating) {
            enqueueSnackbar(" Please rate the product", { variant: "error" });
            return;
        }
        if (!price) {
            enqueueSnackbar(" Please rate pricing", { variant: "error" });
            return;
        }
        if (!qty) {
            enqueueSnackbar(" Please rate quality", { variant: "error" });
            return;
        }

        if (!ratingInput?.nickName) {
            enqueueSnackbar(" Please enter name", { variant: "error" });
            return;
        }
        if (!ratingInput?.summary) {
            enqueueSnackbar(" Please enter summary", { variant: "error" });
            return;
        }
        if (!ratingInput?.email) {
            enqueueSnackbar(" Please enter email", { variant: "error" });
            return;
        }
        if (!ratingInput?.review) {
            enqueueSnackbar(" Please enter review", { variant: "error" });
            return;
        }

        setReviewModal(!reviewModal)
        // console.log("ratingInput", ratingInput)
        // console.log("rating", rating)
        // console.log("price", price)
        // console.log("qty", qty)
        const formData = new FormData()
        formData.append("nickName", ratingInput?.nickName)
        formData.append("summary", ratingInput?.summary)
        formData.append("review", ratingInput?.review)
        formData.append("email", ratingInput?.email)
        formData.append("quality", qty)
        formData.append("price", price)
        formData.append("rating", rating)
        formData.append("productId", selectedProductId)
        // images.map(file => { formData.append("image", file?.file) })
        formData.append('image', file)
        postRating(formData).then(res => {
            if (res?.status)
                enqueueSnackbar(" Rating posted successfully", { variant: 'success' });
            // const obj = {}
            // obj.productId = selectedProductId
            // obj.page = rpage
            // obj.sizePerPage = rPageSize
            // obj.sortBy = sortBy
            // getRating(obj).then(res => {
            //   console.log("rating re--->", res)
            //   setRatings(res?.data?.docs)
            //   setTotalrPage(res?.data?.totalDocs)
            // })
            // console.log(res)
            setReviewModal(false)
            setRatingInput({
                ...ratingInput,
                nickName: "",
                summary: "",
                review: "",
                email: ""
            })
            setImages([])
            setFile(null)
            setQty(wholeSaleMinQuantity)
            setPrice(0)
            setRating(0)
        })
            .catch(err => {
                // console.log(err)
                enqueueSnackbar(" Something went wrong", { variant: 'erro' });
                setReviewModal(true)
            })

    }

    /* ADD TO CART FUNCTION */

    const handleAddToCart = (tag) => {
       if (count === 0) {
            enqueueSnackbar("Add the Quantity you want to buy.", {
                variant: "error",
            });
            return;
        }
        setApiCall(true);
        if (loggedIn) {
            let body = {
                product: "647e3cb36aea560ceac5728a",
                color: colorIdIn,
                quantity: count,
            };
            addTocart(body).then((res) => {
                setApiCall(false);
                enqueueSnackbar(res?.message, { variant: "success" });
                if (res?.status) {
                    setCount(1);
                    setUpdateCart(true);
                }
            });
        } else {
            if (
                cart.filter(
                    (obj) =>
                        obj.product?._id === selectedProduct?._id &&
                        obj.color?._id === colorIdIn
                )?.length > 0
            ) {
                let data = cart.filter(
                    (obj) =>
                        obj.product?._id !== selectedProduct?._id &&
                        obj.color?._id !== colorIdIn
                );
                let product = cart.filter(
                    (obj) =>
                        obj.product?._id === selectedProduct?._id &&
                        obj.color?._id === colorIdIn
                )[0];
                product.quantity = product.quantity + count;

                data.push(product);
                actions.setCartData(data);
                setApiCall(false);
                // console.log(data)
                enqueueSnackbar("Your product has been added to cart successfully.", {
                    variant: "success",
                });
            } else {
                setApiCall(false);
                setCount(1);
                enqueueSnackbar("Your product has been added to cart successfully.", {
                    variant: "success",
                });
                dispatch(
                    actions.setCartData([
                        ...cart,
                        {
                            product: selectedProduct,
                            quantity: count,
                            color: selectedProduct.color.filter(
                                (obj) => obj?._id === colorIdIn
                            )[0],
                            amount: selectedProduct?.amount,
                        },
                    ])
                );
            }
        }
    };

    /* RETURN DATE FUNCTION */
    const returnDate = (date) => {
        let objectDate = new Date(date);
        let day = objectDate.getDate();
        let month = objectDate.getMonth();
        let year = objectDate.getFullYear();
        return day + "/" + month + 1 + "/" + year;
    };
    return (
        <div>
            <Container>
                <Row className='kit-row'>
                    <Col lg="6" className='kit-image-col'>

                        <img src={'https://storage.googleapis.com/cdn-browbarbyreema-test/product/Poster.jpg'}
                            className="kit-image" alt="Display Kit"
                        />
                    </Col>
                    <Col lg="6" className='kit-description px-4 pt-4 pb-4'>
                        <h4><strong> SMOOTH OPERATOR DISPLAY CASE</strong> <small style={{ float: 'right', fontSize: '42px !important' }}>$100.00</small></h4>
                        <h6>Award winning starter Pro kit </h6>
                  
                        <div className='one-price'>
                            <p>1x Smooth Operator Display Case</p>
                            <p>$100.00</p>
                        </div>
                        <div className='pt-3'>
                            <Col lg="6" className='add-to-cart'>

                                <Col lg="5">
                                    <div className='quantity-field float-start' >
                                        <button
                                            className="value-button decrease-button"
                                            onClick={decrement}
                                        >-</button>
                                        <div class="number">{count}</div>
                                        <button
                                            className="value-button increase-button"
                                            onClick={increment}
                                        >+
                                        </button>
                                    </div>
                                </Col>
                                <Col lg="7">
                                    <Button className='kit-btn'  onClick={handleAddToCart}
                                            disabled={apiCall}>
                                        Add to Cart
                                    </Button>
                                </Col>
                            </Col>
                        </div>
                        <div className='pt-4 pb-1'>
                            {/* <h4>Retail Value: <strong>$1050.00</strong></h4>
                            <h4>Wholesale Value: <strong>$2,612.00</strong></h4> */}
                            <p>
                            Price includes acrylic display and display poster
                            </p>
                        </div>
                        <div className='details pt-2'>
                            <span>Features: </span>
                            <ul>
                                <li>1/8th Cosmetic Acrylic base display </li>
                                <li>Measures 12 “ wide x 9” long x 3 “ deep</li>
                                <li>14 pt card stock back display poster</li>
                            </ul>
                            {/* <span>DOES INCLUDE COSMETIC</span> */}
                            <hr />
                            <p className=''>
                                <span>Category: </span>Display Case
                            </p>
                            {/* <p>
                                <span>Details: </span>Completely customize the display card – your words, photos, layout, etc. We provide proof for your approval. Fast turnaround.
                            </p> */}

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BrowKit
