/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Button, Form, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { State, Country } from "country-state-city";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/user/actions';
import { registrationForm, attachDocument } from "../../../service/api";
import { IMG_URL } from "../../../config";
/* ICON IMPORTS */
import { AiFillEye, AiFillEyeInvisible, AiOutlineInfoCircle } from "react-icons/ai";
import UilTrash from "@iconscout/react-unicons/icons/uil-trash";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
const PrivateLabel = () => {
  const statesList = [];
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  /* STATES */
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [loading, setLoading] = useState(false)
  const [stepBtn, setStepBtn] = useState('step-1');

  /* STATES LIST */
  Object.entries(
    State.getStatesOfCountry("US").map((key) => {
      statesList.push({ value: key.name, label: key.name });
    })
  );

  /* COUNTRIES LIST */
  const [countriesList, setCountriesList] = useState([{ value: 'USA', label: 'USA' }]);

  useEffect(() => {
    const countries = Country.getAllCountries().map((country) => ({
      value: country.name,
      label: country.name,
    }));
    const tempCount = [...countriesList, ...countries]

    tempCount.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
    setCountriesList(tempCount);
  }, []);

  /* BUSINESS ACTIVITY OPTIONS */
  const businessActivityOptions = [
    {
      value: "Sales",
      label: "Sales",
    },
    { value: "Marketing", label: "Marketing" },
    { value: "Retail Store", label: "Retail Store" },
    { value: "Wholesale", label: "Wholesale" },
    { value: "Distribution", label: "Distribution" },
    { value: "Promoter", label: "Promoter" },
    { value: "Other", label: "Other" },
  ];

  // ToolTip
  const tooltip = (
    <Tooltip id="tooltip">
      Please provide the referral ID if you have been assigned a sales representative.
    </Tooltip>
  );

  const dispatch = useDispatch()
  const { registrationDetails } = useSelector(state => state.user)

  const [input, setInput] = useState({
    formType: "",
    companyName: "",
    websiteURL: "",
    companyEmailAddress: "",
    companyEmailAddressConfirm: "",
    resaleLicenseNumber: "",
    federalTaxId: "",
    primaryBusiness: "",
    address: "",
    addressLine1: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    title: "",
    email: "",
    emailConfirm: "",
    password: "",
    passwordConfirm: "",
    passwordHint: "",
    referralId: "",
    otherPrimaryBusiness: ""
  })
  const [checkTerms, setCheckTerms] = useState(false);
  const [inputError, setInputError] = useState({
    formType: false,
    companyName: false,
    websiteURL: false,
    companyEmailAddress: false,
    companyEmailAddressConfirm: false,
    resaleLicenseNumber: false,
    federalTaxId: false,
    primaryBusiness: false,
    address: false,
    addressLine1: false,
    city: false,
    state: false,
    zipCode: false,
    country: false,
    firstName: false,
    lastName: false,
    phoneNumber: false,
    title: false,
    email: false,
    emailConfirm: false,
    password: false,
    passwordConfirm: false,
    passwordHint: false,
    checkTerms: false,
    otherPrimaryBusiness: false
  });

  const onInputChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value })
    setInputError({ ...inputError, [e.target.name]: false })
  }


  useEffect(() => {
    if (registrationDetails?.formType === "privateLabel") {
      setInput({ ...input, ...registrationDetails })
      setCheckTerms(registrationDetails?.terms)
    }
  }, [registrationDetails])

  useEffect(() => {
    console.log("input", input)
    console.log("registrationDetails", registrationDetails)

  }, [input])

  function isEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isUrl(url) {
    // Regular expression for URL validation
    const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return urlRegex.test(url);
  }

  function isValidPhoneNumber(number) {
    const pattern = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    return pattern.test(number);
  }

  const [newsletterSignup, setnewsletterSignup] = useState(false)
  const callApiFun = () => {

    // Create a copy of the input and inputError objects
    const updatedInput = { ...input };
    const updatedInputError = { ...inputError };
    console.log("checkTerms", checkTerms)
    //Check terms
    if (!checkTerms) {
      updatedInputError.checkTerms = true;
    } else {
      updatedInputError.checkTerms = false;
    }

    // Check if the primaryBusiness field is empty
    if (!updatedInput.primaryBusiness) {
      updatedInputError.primaryBusiness = true;
    } else {
      updatedInputError.primaryBusiness = false;
    }

    // Check if the companyName field is empty
    if (!updatedInput.companyName) {
      updatedInputError.companyName = true;
    } else {
      updatedInputError.companyName = false;
    }
    console.log(isUrl(updatedInput.websiteURL))

    // Check if the websiteURL field is a valid URL
    if (!updatedInput.websiteURL || !isUrl(updatedInput.websiteURL)) {
      updatedInputError.websiteURL = true;
    } else {
      updatedInputError.websiteURL = false;
    }
    // Check if the companyEmailAddress field is a valid email address
    if (!updatedInput.companyEmailAddress || !isEmail(updatedInput.companyEmailAddress)) {

      updatedInputError.companyEmailAddress = true;
    } else {
      updatedInputError.companyEmailAddress = false;
    }

    // Check if the companyEmailAddressConfirm field matches the companyEmailAddress field
    if (updatedInput.companyEmailAddressConfirm !== updatedInput.companyEmailAddress) {
      updatedInputError.companyEmailAddressConfirm = true;
    } else {
      updatedInputError.companyEmailAddressConfirm = false;
    }

    // Check if the country field is empty
    if (!updatedInput.country) {
      updatedInputError.country = true;
    } else {
      updatedInputError.country = false;
    }

    // Check if the addressLine1 field is empty
    if (!updatedInput.address) {
      updatedInputError.address = true;
    } else {
      updatedInputError.address = false;
    }

    // Check if the state field is empty
    if (!updatedInput.state) {
      updatedInputError.state = true;
    } else {
      updatedInputError.state = false;
    }

    // Check if the city field is empty
    if (!updatedInput.city) {
      updatedInputError.city = true;
    } else {
      updatedInputError.city = false;
    }

    // Check if the zipCode field is empty
    if (!updatedInput.zipCode) {
      updatedInputError.zipCode = true;
    } else {
      updatedInputError.zipCode = false;
    }



    // Check if the firstName field is empty
    if (!updatedInput.firstName) {
      updatedInputError.firstName = true;
    } else {
      updatedInputError.firstName = false;
    }

    // Check if the lastName field is empty
    if (!updatedInput.lastName) {
      updatedInputError.lastName = true;
    } else {
      updatedInputError.lastName = false;
    }

    // Check if the phoneNumber field is empty
    if (!updatedInput.phoneNumber) {
      updatedInputError.phoneNumber = true;
    } else {
      updatedInputError.phoneNumber = false;
    }

    // Check if the phoneNumber field is empty
    if (!updatedInput.title) {
      updatedInputError.title = true;
    } else {
      updatedInputError.title = false;
    }

    // Check if the email field is a valid email address
    if (!updatedInput.email || !isEmail(updatedInput.email)) {
      updatedInputError.email = true;
    } else {
      updatedInputError.email = false;
    }

    // Check if the emailConfirm field matches the email field
    if (updatedInput.emailConfirm !== updatedInput.email) {
      updatedInputError.emailConfirm = true;
    } else {
      updatedInputError.emailConfirm = false;
    }

    // Check if the password field is at least 8 characters long
    if (!updatedInput.password) {
      updatedInputError.password = true;
    } else {
      updatedInputError.password = false;
    }

    // Check if the password field is at least 8 characters long
    if (updatedInput.passwordConfirm !== updatedInput.password) {
      updatedInputError.passwordConfirm = true;
    } else {
      updatedInputError.passwordConfirm = false;
    }


    // Check if there are any errors in the updatedInputError object
    const hasErrors = Object.values(updatedInputError).some(error => error === true);

    // If there are errors, don't call the API
    if (hasErrors) {
      console.log("updatedInputError", updatedInputError)
      setInputError(updatedInputError);
      return;
    }
    console.log("input", input)

    if (registrationDetails?.formType === "privateLabel" && !registrationDetails?.registered && !registrationDetails?.registrationId) {
      const formData = new FormData();

      formData.append("formType", registrationDetails?.formType);
      formData.append("companyName", input.companyName);
      formData.append("websiteURL", input.websiteURL);
      formData.append("companyEmailAddress", input.companyEmailAddress);
      if (input?.primaryBusiness === "Other") {
        formData.append("primaryBusiness", input?.otherPrimaryBusiness);
      } else {
        formData.append("primaryBusiness", input?.primaryBusiness);
      }
      formData.append("address", input.address);
      if (input?.addressLine1)
        formData.append("addressLine1", input.addressLine1);
      formData.append("city", input.city);
      formData.append("state", input.state);
      formData.append("zipCode", input.zipCode);
      formData.append("country", input.country);
      formData.append("firstName", input.firstName);
      formData.append("lastName", input.lastName);
      formData.append("phoneNumber", input.phoneNumber);
      if (input.title) {
        formData.append("title", input.title);
      }

      formData.append("email", input.email);
      formData.append("password", input.password);

      formData.append("newsLetter", newsletterSignup);
      formData.append("referralId", input.referralId);

      setLoading(true);
      registrationForm(formData).then(res => {
        console.log("res?.data", res)
        enqueueSnackbar("Your details have been saved successfully.", {
          variant: "success",
        });
        if (res?.status)
          dispatch(actions.setRegistraionDetails({ ...registrationDetails, ...input, registered: true, registrationId: res?.data?._id, formType: registrationDetails?.formType ? registrationDetails?.formType : "privateLabel", terms: true }))
        // dispatch(actions.setLoggedIn(true))
        setStepBtn("step-2")
      })
        .catch((e) => {
          console.log("e", e)
          console.log(e?.response?.data?.message)
          if (e?.response?.data?.message?.details?.length > 0)

            enqueueSnackbar(e?.response?.data?.message, {
              variant: "error",
            });
          else
            enqueueSnackbar("Something went wrong", {
              variant: "error",
            });
        })
        .finally((res) => {
          setLoading(false);
        });
    }
    else {
      setStepBtn("step-2")
    }

  }

  const [w9, setW9] = useState(null);
  const [w9Preview, setW9Preview] = useState(null);
  const [w9FileName, setW9FileName] = useState('W-9 Form');
  const [w9Error, setW9Error] = useState(false);


  const [w8, setW8] = useState(null);
  const [w8Preview, setW8Preview] = useState(null);
  const [w8FileName, setW8FileName] = useState('W-8 Form');
  const [w8Error, setW8Error] = useState(false);

  const [w9Loading, setW9Loading] = useState(false)

  useEffect(() => {
    if (w9 && w9FileName) {
      const formdata = new FormData();
      formdata.append('documentFile', w9);
      formdata.append('fileName', w9FileName);
      setW9Loading(true)
      attachDocument(registrationDetails?.registrationId, formdata).then((res) => {
        console.log(res);
        setW9Preview(res?.data?.fileDetails?.documentURL)
        setW9FileName(res?.data?.fileDetails?.documentName)
        enqueueSnackbar("File uploaded successfully", {
          variant: "success",
        });
        setW9Error(false)
      }).catch(err => {
        enqueueSnackbar(err?.response?.data?.message?.details[0]?.message, {
          variant: "error",
        });
      })
        .finally(res => {
          setW9Loading(false)
        })
    }
  }, [w9]);

  const [w8Loading, setW8Loading] = useState(false)

  useEffect(() => {
    if (w8 && w8FileName) {
      const formdata = new FormData();
      formdata.append('documentFile', w8);
      formdata.append('fileName', w8FileName);
      setW8Loading(true)
      attachDocument(registrationDetails?.registrationId, formdata).then((res) => {
        console.log(res);
        setW8Preview(res?.data?.fileDetails?.documentURL)
        setW8FileName(res?.data?.fileDetails?.documentName)
        enqueueSnackbar("File uploaded successfully", {
          variant: "success",
        });
        setW8Error(false)
      }).catch(err => {
        enqueueSnackbar(err?.response?.data?.message?.details[0]?.message, {
          variant: "error",
        });
      })
        .finally(res => {
          setW8Loading(false)
        })
    }
  }, [w8]);
  const allowedFileTypes = ["jpg", "jpeg", "png", "webp", "gif", "doc", "pdf"];

  const handleW9Change = (event) => {
    const file = event.target.files[0];

    const fileName = file.name.toLowerCase();
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);

    if (!w9FileName) {
      enqueueSnackbar("Please enter file name first", {
        variant: "error",
      });
      return;
    }

    if (!allowedFileTypes.includes(fileType)) {
      enqueueSnackbar("Invalid file type. Please upload a file of type: " + allowedFileTypes.join(", "), {
        variant: "error",
      });
      return;
    }

    setW9(file);
    setW9Preview(null);
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setFeinNumberPreview(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };

  const handleW8Change = (event) => {
    const file = event.target.files[0];

    const fileName = file.name.toLowerCase();
    const fileType = fileName.substring(fileName.lastIndexOf(".") + 1);

    if (!w8FileName) {
      enqueueSnackbar("Please enter file name first", {
        variant: "error",
      });
      return;
    }

    if (!allowedFileTypes.includes(fileType)) {
      enqueueSnackbar("Invalid file type. Please upload a file of type: " + allowedFileTypes.join(", "), {
        variant: "error",
      });
      return;
    }

    setW8(file);
    setW8Preview(null);
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     setFeinNumberPreview(reader.result);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };

  const handleW9Delete = () => {
    setW9(null);
    setW9Preview(null)
    document.getElementById("w9").value = "";
    setW9Error(true)
  };

  const handleW8Delete = () => {
    setW8(null);
    setW8Preview(null)
    document.getElementById("w8").value = "";
    setW8Error(true)
  };
  return (
    <section className="wholesale-page">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Customer Registration | Browbar By Reema</title>
      </Helmet>

      <div className="container">
        <div className="page-wrap">
          <h1>WHOLESALE ACCOUNT APPLICATION</h1>

          {/* FORM STEPS */}
          <div className="col-xl-12 col-sm-12">
            <div className="row mt-3">
              <div className="col-xl-4 col-sm-12">
                <div className="step-box">
                  <div
                    className={`step-number ${stepBtn === "step-1" ? "active" : ""
                      }`}
                  >
                    <h5>1</h5>
                  </div>
                  <div className="step-details">
                    <h5>Fill out Application</h5>
                    <p>Complete the form below.</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-4 col-sm-12">
                <div className="step-box">
                  <div className="step-number">
                    <h5>2</h5>
                  </div>
                  <div className="step-details">
                    <h5>Provide Resale Certificate</h5>
                    <p>Send in required documents.</p>
                    <span>
                      (You will need a{" "}
                      <Link to="/resale-certificates">resale certificate</Link>{" "}
                      <br />
                      and valid sales tax license)
                    </span>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-4 col-sm-12">
                <div className="step-box">
                  <div
                    className={`step-number ${stepBtn === "step-2" ? "active" : ""
                      }`}
                  >
                    <h5>2</h5>
                  </div>
                  <div className="step-details">
                    <h5>Provide W-9/W-8 Form</h5>
                    <p>Send in required documents.</p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-12">
                <div className="step-box">
                  <div
                    className={`step-number ${stepBtn === "step-3" ? "active" : ""
                      }`}
                  >
                    <h5>3</h5>
                  </div>
                  <div className="step-details">
                    <h5>Account Review</h5>
                    <p>
                      You'll hear back from us in
                      <br />
                      1-2 business days.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-12"></div>
            </div>
          </div>

          {/* FORM DETAILS */}
          <div className="col-xl-12 col-sm-12">
            <div className="details">
              <form>
                <div className="row">
                  {/* CRITERIA */}
                  <div className="col-xl-12 col-sm-12">
                    <h5>YOU MUST MEET THE FOLLOWING CRITERIA</h5>
                    <ol className="requirements-list">
                      <li>
                        You are a product distributor or salon (If this is not
                        you? You may still qualify, but need to apply
                        <Link to="">here</Link>).
                      </li>
                      {/* <li>You are a US or Canadian resident.</li> */}
                      {/* <li>You have a valid sales tax license.</li> */}
                    </ol>
                  </div>

                  {stepBtn === "step-1" &&
                    <>

                      {/* COMPANY INFORMATION */}
                      <div className="col-xl-12 col-sm-12">
                        <div className="form">
                          <h5>COMPANY INFORMATION</h5>
                          <div className="row">
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Company Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={input?.companyName}
                                  name="companyName"
                                  onChange={(e) => onInputChange(e)}
                                  id="companyName"
                                  placeholder="Company Name"

                                />
                                {inputError?.companyName && <small className="validation-error">
                                  Company Name is required
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Website <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={input?.websiteURL}
                                  name="websiteURL"
                                  onChange={(e) => onInputChange(e)}
                                  id="website"
                                  placeholder="www.domain.com"
                                />
                                {inputError?.websiteURL && <small className="validation-error">
                                  websiteURL is required
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Company Email{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={input?.companyEmailAddress}
                                  name="companyEmailAddress"
                                  onChange={(e) => onInputChange(e)}
                                  id="companyEmail"
                                  placeholder="placeholder@domain.com"
                                />
                                {inputError?.companyEmailAddress && <small className="validation-error">
                                  Company Email is required
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Confirm Company Email{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={input?.companyEmailAddressConfirm}
                                  name="companyEmailAddressConfirm"
                                  onChange={(e) => onInputChange(e)}
                                  id="companyEmail"
                                  placeholder="placeholder@domain.com"
                                />
                                {inputError?.companyEmailAddressConfirm && <small className="validation-error">
                                  The email addresses doesn't match
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-4 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  What is your primary business activity?{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                  options={businessActivityOptions}
                                  className="react-select mb-15"
                                  classNamePrefix="select-control"
                                  placeholder="Select Business Activity"
                                  isSearchable
                                  value={businessActivityOptions.find(option => option.value === input.primaryBusiness)}
                                  onChange={(selectedOption) => {
                                    setInput({
                                      ...input,
                                      primaryBusiness: selectedOption.value
                                    });
                                    setInputError({
                                      ...inputError,
                                      primaryBusiness: false
                                    });
                                  }}
                                />
                                {inputError?.primaryBusiness && <small className="validation-error">
                                  Please select atleast one option
                                </small>}
                              </div>
                            </div>
                            {input?.primaryBusiness === "Other" ? <div className="col-xl-3 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Primary Business Activity Other Option
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={input?.otherPrimaryBusiness}
                                  name="otherPrimaryBusiness"
                                  onChange={(e) => onInputChange(e)}
                                  id="otherPrimaryBusiness"
                                  placeholder="Primary Business Activity Other Option"
                                />
                              </div>
                            </div> : false}
                            <div className="col-xl-3 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Referral ID

                                  <span> <OverlayTrigger placement="right" overlay={tooltip}>
                                    <Button style={{ background: 'transparent', border: 'none', color: 'black', padding: '0px', marginTop: '-5px' }} ><AiOutlineInfoCircle /></Button>
                                  </OverlayTrigger></span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={input?.referralId}
                                  name="referralId"
                                  onChange={(e) => onInputChange(e)}
                                  id="referralId"
                                  placeholder="Referral ID"
                                />
                              </div>
                            </div>



                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Address <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={input?.address}
                                  name="address"
                                  onChange={(e) => onInputChange(e)}
                                  id="address"
                                  placeholder="Address"
                                />
                                {inputError?.address && <small className="validation-error">
                                  Address is required
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Address (Unit #)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={input?.addressLine1}
                                  name="addressLine1"
                                  onChange={(e) => onInputChange(e)}
                                  id="addressUnit"
                                  placeholder="Address (Unit #)"
                                />
                              </div>
                            </div>
                            <div className="col-xl-3 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Country <span style={{ color: "red" }}>*</span>
                                </label>
                                <Select
                                  options={countriesList}
                                  className="react-select mb-15"
                                  classNamePrefix="select-control"
                                  placeholder="Select Country"
                                  isSearchable
                                  value={countriesList.find(option => option.value === input.country)}
                                  onChange={(selectedOption) => {
                                    setInput({
                                      ...input,
                                      country: selectedOption.value
                                    });
                                    setInputError({
                                      ...inputError,
                                      country: false
                                    });
                                  }}

                                />
                                {inputError?.country && <small className="validation-error">
                                  country is required
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-3 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Province/State{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                {/* <Select
                                  options={statesList}
                                  className="react-select mb-15"
                                  classNamePrefix="select-control"
                                  placeholder="Select Province/State"
                                  isSearchable
                                  value={statesList.find(option => option.value === input.state)}
                                  onChange={(selectedOption) => {
                                    setInput({
                                      ...input,
                                      state: selectedOption.value
                                    });
                                    setInputError({
                                      ...inputError,
                                      state: false
                                    });
                                  }}
                                /> */}
                                <input
                                  type="text"
                                  className="form-control"
                                  value={input?.state}
                                  name="state"
                                  onChange={(e) => onInputChange(e)}
                                  id="state"
                                  placeholder="State"
                                />
                                {inputError?.state && <small className="validation-error">
                                  Please entered province/state
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-3 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  City <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={input?.city}
                                  name="city"
                                  onChange={(e) => onInputChange(e)}
                                  id="city"
                                  placeholder="City"
                                />
                                {inputError?.city && <small className="validation-error">
                                  City is required
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-3 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Zip/Postal{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={input?.zipCode}
                                  name="zipCode"
                                  onChange={(e) => onInputChange(e)}
                                  id="zipCode"
                                  placeholder="Zip/Postal Code"
                                />
                                {inputError?.zipCode && <small className="validation-error">
                                  ZIP/Postal Code is required
                                </small>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* CONTACT INFORMATION */}
                      <div className="col-xl-12 col-sm-12">
                        <div className="form">
                          <h5>CONTACT INFORMATION</h5>
                          <div className="row">
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  First Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={input?.firstName}
                                  name="firstName"
                                  onChange={(e) => onInputChange(e)}
                                  id="fName"
                                  placeholder="First Name"
                                />
                                {inputError?.firstName && <small className="validation-error">
                                  First Name is required
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Last Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={input?.lastName}
                                  name="lastName"
                                  onChange={(e) => onInputChange(e)}
                                  id="lName"
                                  placeholder="Last Name"
                                />
                                {inputError?.lastName && <small className="validation-error">
                                  Last Name is required
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Phone <span style={{ color: "red" }}>*</span>
                                </label>
                                <PhoneInput
                                  value={input?.phoneNumber}
                                  onChange={(e) => {
                                    setInput({ ...input, 'phoneNumber': e })
                                    setInputError({ ...inputError, 'phoneNumber': false })
                                  }}
                                  placeholder="Phone Number"
                                  defaultCountry="US"
                                  className="phone-number-select"
                                />
                                {inputError?.phoneNumber && <small className="validation-error">
                                  Phone number should be valid
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Title <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={input?.title}
                                  name="title"
                                  onChange={(e) => onInputChange(e)}
                                  id="title"
                                  placeholder="Title"
                                />
                                {inputError?.title && <small className="validation-error">
                                  Title is required
                                </small>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* WEB ACCOUNT INFORMATION */}
                      <div className="col-xl-12 col-sm-12">
                        <div className="form">
                          <h5>WEB ACCOUNT INFORMATION</h5>
                          <div className="row">
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Email Address (this will be your account login
                                  ID, or username){" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={input?.email}
                                  name="email"
                                  onChange={(e) => onInputChange(e)}
                                  id="accountEmail"
                                  placeholder="placeholder@domain.com"
                                />
                                {inputError?.email && <small className="validation-error">
                                  Email Address is required
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Confirm Email Address{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="email"
                                  className="form-control"
                                  value={input?.emailConfirm}
                                  name="emailConfirm"
                                  onChange={(e) => onInputChange(e)}
                                  id="accountEmail"
                                  placeholder="placeholder@domain.com"
                                />
                                {inputError?.emailConfirm && <small className="validation-error">
                                  The email address doesn't match
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Password <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type={showLoginPassword ? "text" : "password"}
                                  className="form-control"
                                  value={input?.password}
                                  name="password"
                                  onChange={(e) => onInputChange(e)}
                                  id="accountPassword"
                                  placeholder="Password"
                                />
                                <Button
                                  className="password-icon"
                                  onClick={() =>
                                    setShowLoginPassword(!showLoginPassword)
                                  }
                                >
                                  {showLoginPassword ? (
                                    <AiFillEyeInvisible />
                                  ) : (
                                    <AiFillEye />
                                  )}
                                </Button>
                                {inputError?.password && <small className="validation-error">
                                  Password is required
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Confirm Password{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type={showLoginPassword ? "text" : "password"}
                                  className="form-control"
                                  value={input?.passwordConfirm}
                                  name="passwordConfirm"
                                  onChange={(e) => onInputChange(e)}
                                  id="accountPassword"
                                  placeholder="Password"
                                />
                                <Button
                                  className="password-icon"
                                  onClick={() =>
                                    setShowLoginPassword(!showLoginPassword)
                                  }
                                >
                                  {showLoginPassword ? (
                                    <AiFillEyeInvisible />
                                  ) : (
                                    <AiFillEye />
                                  )}
                                </Button>
                                {inputError?.passwordConfirm && <small className="validation-error">
                                  The passwords do not match
                                </small>}
                              </div>
                            </div>
                            <div className="col-xl-6 col-sm-12">
                              <Form.Check
                                label="Sign up for the latest news, discounts, and more"
                                className="conditions-box"
                                checked={newsletterSignup}
                                onChange={(e) => setnewsletterSignup(e.target.checked)}
                              />
                            </div>
                            {/* <div className="col-xl-6 col-sm-12">
                              <div className="form-group">
                                <label className="form-label">
                                  Password Hint (Enter a password hint to help you
                                  remember your password){" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="passwordHint"
                                  placeholder="Hint"
                                  value={input?.passwordHint}
                                  name="passwordHint"
                                  onChange={(e) => onInputChange(e)}
                                />
                                {inputError?.passwordHint && <small className="validation-error">
                                  Password Hint is required
                                </small>}
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>

                      {/* TERMS AND CONDITIONS */}
                      <div className="col-xl-12 col-sm-12">
                        <div className="terms-conditions">
                          <h5>TERMS &amp; CONDITIONS</h5>
                          <p>
                            By proceeding I acknowledge that I have read and agree
                            to the following terms and conditions:
                          </p>
                          <div className="terms-conditions-box">
                            <h5>
                              ALL SALES ARE SUBJECT TO THE FOLLOWING TERMS AND
                              CONDITIONS EXCEPT FOR FACE MASK PRODUCTS, WHICH ARE
                              DEFINED AND ADDRESSED BELOW:
                            </h5>
                            <div className="mt-2">
                              <h5>PRICES</h5>
                              <p>
                                All prices are subject to change without notice to
                                customer. All orders will be billed at the prices
                                prevailing at the time the order is placed.
                                Customers must notify Browbar By Reema in writing
                                within 30 days after receipt of the subject invoice
                                of any price disputes. If customer fails to notify
                                Browbar By Reema within such that 30-day period, any
                                and all claims or controversies brought by customer
                                and relating to such price dispute shall be deemed
                                time barred and waived.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>ORDERS</h5>
                              <p>
                                All orders are subject to acceptance by Browbar by
                                Reema. Browbar by Reema, in its sole discretion,
                                shall determine the channels of distribution of its
                                products. Browbar by Reema reserves all of its right
                                to refuse to supply product to customers and certain
                                customer locations. Only those customer internet
                                sites authorized in writing by Browbar by Reema may
                                sell and advertise Browbar by Reema products.
                                Browbar by Reema also reserves all rights to
                                prohibit Browbar by Reema products from being
                                advertised and or sold via the internet. Browbar by
                                Reema may immediately terminate the account of any
                                customer or customer internet site selling Browbar
                                by Reema products without written authorization, and
                                may bring any legal or other action for breach of
                                these terms and conditions. All sales of close-outs,
                                irregulars, seconds or b-grade products are final
                                and cannot be returned. All back orders will be
                                shipped F.O.B. Shipping point. No customer
                                deductions are permited for freight, parcel post,
                                charges, or like charges.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>TERMS OF PAYMENT</h5>
                              <p>
                                Payment for ordered goods is due and payable
                                pursuant to the time period specified in the
                                applicable invoice and these terms and conditions. A
                                service charge of 1½ % per month or the maximum
                                permitted by law will be charged on all payments for
                                goods not received within the number of days
                                specified on the invoice. Customer shall be
                                responsible for all costs, expenses, collections
                                agency commissions, and reasonable attorneys fees
                                that Browbar by Reema may incur in the collection of
                                any past due invoices. Browbar by Reema, in its sole
                                discretion, may terminate customer’s account at any
                                time should customer breach any of the terms and
                                conditions contained herein.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>RESTRICTIONS ON SALE</h5>
                              <p>
                                Customer is prohibited from selling Browbar by Reema
                                products other than through a Browbar by Reema
                                approved channel of distribution. Violation of this
                                condition may result in immediate termination of
                                customer’s account.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>CANCELLATIONS</h5>
                              <p>
                                All requests to cancel orders must be submitted in
                                writing to Browbar by Reema management at least 45
                                days before the start ship date and must be approved
                                by Browbar by Reema in writing. Cancellations may
                                affect volume discounts and may be subject to
                                processing fees. Special or custom orders or special
                                make up (collectively, “smu“) product orders may not
                                be cancelled. Browbar by Reema will not accept
                                returns for unapproved cancellations.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>NO TRANSHIPPING</h5>
                              <p>
                                Unauthorized transshipment or resale of any Browbar
                                by Reema merchandise to any person other than the
                                end consumer is strictly prohibited, may result in
                                the cancellation of existing orders, the termination
                                of customer’s business relationship with Browbar by
                                Reema, and/or legal action for, including but not
                                limited to, breach of these terms and conditions.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>CLAIMS</h5>
                              <p>
                                Customer must report all merchandise shortages,
                                overages, or damages to the Browbar by Reema
                                customer service department within 7 days of
                                merchandise receipt. To report shortages, overages,
                                or damages, customer must provide Browbar by Reema
                                with an invoice number or order number, as well as a
                                list of products at issue, itemized by product code,
                                size and quantity.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>INSPECTION BEFORE PRINTING</h5>
                              <p>
                                Customer must inspect all merchandise before
                                printing. Browbar by Reema will not accept returns
                                of any printed or otherwise decorated merchandise,
                                or any washed merchandise under any circumstances.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>RETURN POLICY</h5>
                              <p>
                                All returns are subject to re-stocking charges of
                                not less than 20%, must be requested within 7 days
                                of merchandise receipt, and must be pre-approved in
                                writing by Browbar by Reema customer service. If
                                approval is obtained, customer must notify Browbar
                                by Reema customer service of its intent to return
                                authorized product. All return requests must be
                                accompanied by an order number or invoice number,
                                the return authorization number (ra #) and label,
                                both of which must be attached to the carton or
                                other packaging containing the returned product.
                                Customer is responsible for all freight and shipping
                                charges on items returned that are not the result of
                                Browbar by Reema error. All products must be
                                returned in the same condition in which received.
                                Upon receipt and inspection of returned product, a
                                credit will be issued in the amount equal to the
                                invoice price of such returned product, less any
                                applicable discount. If no invoice number is
                                provided, Browbar by Reema will issue customer a
                                credit based on the then current product pricing. No
                                returns of smu product will be approved by Browbar
                                by Reema. In addition, no returns of relabeled,
                                printed, embroided, decorated or washed garmets will
                                be approved by Browbar by Reema.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>DEFECTIVE MERCHANDISE</h5>
                              <p>
                                All defective merchandise must be reported to
                                Browbar by Reema customer service department within
                                7 days of merchandise receipt. Defective merchandise
                                refunds will be handled on a case-by-case basis, at
                                Browbar by Reema’s sole discretion.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>PRODUCT WARRANTY</h5>
                              <p>
                                Browbar by Reema does not make, and hereby
                                disclaims, any and all warranties, express or
                                implied, including without limitation any warranties
                                of merchantability or fitness for a particular
                                purpose.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>LIMITATION OF LIABILITY</h5>
                              <p>
                                In no event shall Browbar by Reema be liable to the
                                customer for any loss of profit, interruption of
                                business, or any special, indirect, consequential or
                                incidental damages arising in contract (including
                                breach of warranty), in tort (including strict
                                liability or negligence) or otherwise, even if
                                Browbar by Reema has been notified or otherwise made
                                aware of the possibility of such damages.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>FAIR LABOR STANDARDS ACT</h5>
                              <p>
                                Browbar by Reema hereby certifies that the
                                merchandise covered by any Browbar by Reema invoice
                                was manufactured in compliance with the requirements
                                of the fair labor standards act of 1938, as amended.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>FORCE MAJEURE</h5>
                              <p>
                                Browbar by Reema shall not be liable for any failure
                                or delay in delivery or in its performance under
                                these terms and conditions or otherwise due to any
                                cause beyond Browbar by Reema reasonable control,
                                including, without limitation, any act of war or
                                civil insurrection, national emergencies, acts of
                                god, fire, explosion, storm, earthquake, flood,
                                embargo, riot, sabotage, industry-wide strikes,
                                lockouts, labor difficulties, supplier failures,
                                unavailability or shortages of materials, or
                                governmental acts (collectively, “force majeure“).
                                Where any product scheduled for delivery becomes
                                unavailable as a result of a force majeure event,
                                Browbar by Reema may, at its sole discretion,
                                substitute such product with a comparable product.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>GOVERNING LAW; VENUE</h5>
                              <p>
                                These terms and conditions shall be deemed to have
                                been entered into in the state of california and
                                shall in all respects be interpreted, enforced and
                                governed under the internal laws of the state of
                                california without regard to principles of conflicts
                                of law that would apply any other state law.
                                Customer hereby agrees and irrevocably consents to
                                submit to the exclusive jurisdiction and venue of
                                the courts of the state of california and of the
                                united states of america located in the state of
                                california, for any and all claims arising from or
                                related to these termes and conditions. Each party
                                hereby waives their right to a jury trial in any
                                such controversy. Any action by customer for breach
                                of contract must be commenced within one year from
                                the merchandise ship date.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>GENERAL</h5>
                              <p>
                                All purchases and sales of Browbar by Reema’s
                                products to customer shall be made solely on these
                                terms and conditions. These terms and conditions
                                shall be applicable in full to all such purchases
                                and sales. All terms and conditions contained in any
                                purchase order or similar order form from customer
                                are hereby rejected by Browbar by Reema in all
                                respects. Any modification, supplement, or amendment
                                of these terms and conditions shall be made in
                                writing by Browbar by Reema authorized management
                                representative only. Any term or provision of these
                                terms and conditions that is invalid or
                                unenforceable in any situation shall not affect the
                                validity or enforceability of the remaining terms
                                and conditions or the validity or enforceability of
                                the offending term or condition in any other
                                situation. Acceptance of these terms and conditions
                                shall be confirmed by electronic signature copy by
                                pdf, jpeg file or comparable format or by facsmilie,
                                and any such electronic signature copy shall
                                constitute an original for all purposes. These terms
                                and conditions and Browbar by Reema’s invoice
                                contain the entire agreement between Browbar by
                                Reema and customer, and supersedes all previous
                                contracts or agreements between Browbar by Reema and
                                customer. Customer agrees that Browbar by Reema is
                                not bound by any representation, promise, condition,
                                inducement or warranty, express or implied, not
                                included above.
                              </p>
                            </div>
                            <h5 className="mt-2">
                              ALL SALES OF ANY FACE MASK, FACE SHIELD, FACE COVER,
                              OR SIMILAR PRODUCT (“FACE MASK PRODUCTS“) ARE SUBJECT
                              TO THE FOLLOWING TERMS AND CONDITIONS (“FACE MASK
                              TERMS“):
                            </h5>
                            <div className="mt-2">
                              <h5>ACCEPTANCE AND CONTRACT</h5>
                              <p>
                                Browbar by Reema's acceptance of customer's order
                                for any face mask product is expressly conditioned
                                upon customer's acceptance of the face mask terms.
                                The face mask terms hereof shall constitute a
                                binding contract between Browbar by Reema and
                                customer concerning the face mask products sold
                                hereunder. None of these face mask terms may be
                                supplemented, modified, superseded, amended or
                                otherwise altered, except by a written instrument
                                signed by an authorized signatory of Browbar by
                                Reema. Failure of Browbar by Reema to object to any
                                terms or conditions which may be contained in any
                                document or form of customer shall not be construed
                                as a waiver of these face mask terms, nor as an
                                acceptance of any such terms and conditions.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>PRICES</h5>
                              <p>
                                All prices are subject to change without notice to
                                Customer. Orders are billed at the prices prevailing
                                at the time the order is placed. The price set forth
                                in a Customer's invoice shall govern that order.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>TERMS OF PAYMENT</h5>
                              <p>
                                Unless otherwise set forth in the applicable
                                invoice, payment for ordered Face Mask Products is
                                due and payable prior to any shipment by Browbar by
                                Reema of any of the ordered Face Mask Products.
                                Browbar by Reema reserves the right to cancel any
                                order for which payment has not been received by
                                Browbar by Reema. A service charge of 1½ % per month
                                or the maximum permitted by law, if lower than 1½ %
                                per month, will be charged on all payments for Face
                                Mask Products not received within the number of days
                                specified on the invoice. Customer shall be
                                responsible for all costs, expenses, collections
                                agency commissions, and reasonable attorney's fees
                                that Browbar by Reema may incur in the collection of
                                any past due invoices. Browbar by Reema, in its sole
                                discretion, may terminate Customer's account at any
                                time should Customer breach any of the terms and
                                conditions contained herein.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>SHIPPING</h5>
                              <p>
                                All Face Mask Products sold hereunder are F.O.B.
                                unless otherwise stated in the applicable invoice.
                                All claims for shipping loss or damage are
                                Customer's responsibility. Delivery dates are not
                                guaranteed and Browbar by Reema has no liability for
                                damages that may be incurred due to any delay in
                                shipment of Face Mask Products hereunder. Taxes are
                                excluded unless otherwise stated in the applicable
                                invoice.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>RESTRICTIONS ON RESALE</h5>
                              <p>
                                Customer is prohibited from reselling the ordered
                                Face Mask Products other than through a Browbar by
                                Reema approved channel of distribution or as
                                otherwise approved in writing by Browbar by Reema.
                                If Customer resells the Face Mask Products to a
                                reseller, Customer shall ensure that such reseller
                                complies with the Face Mask Terms set forth herein.
                                Customer shall ensure that all resellers of the Face
                                Mask Products include in all resale documentation
                                prominent disclosures or postings of the information
                                set forth in the SPECIFIC DISCLAIMER of these Face
                                Mask Terms. Violation of the provisions of the Face
                                Mask Terms may result in immediate termination of
                                Customer's account.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>CLAIMS</h5>
                              <p>
                                Customer must report all shortages of ordered Face
                                Mask Products to the Browbar by Reema customer
                                service department within 7 days of receipt of
                                ordered Face Mask Products.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>NO RETURNS</h5>
                              <p>
                                All Face Mask Products are sold "as-is" and are not
                                returnable absent prior written approval by Browbar
                                by Reema.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>AS-IS; NO WARRANTY</h5>
                              <p>
                                The Face Mask Products are provided "as-is" without
                                warranties of any kind and without limiting the
                                foregoing, Browbar by Reema hereby disclaims any and
                                all warranties, express or implied, relating to the
                                Face Mask Products, including without limitation any
                                warranties of merchantability or fitness for a
                                particular purpose. Customer acknowledges that they
                                have not relied on any warranty or representation in
                                placing the order for the purchase of Face Mask
                                Products.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>LIMITATION OF LIABILITY</h5>
                              <p>
                                In no event shall Browbar by Reema be responsible or
                                liable to Customer (or claims of any third party
                                against Customer) for any loss of profit,
                                interruption of business, loss of goodwill, loss of
                                business opportunity or any special, indirect,
                                collateral, punitive or exemplary, incidental or
                                consequential damages arising in contract (including
                                breach of warranty), in tort (including strict
                                liability or negligence) or otherwise, even if
                                Browbar by Reema has been advised of the possibility
                                of such damages. In no event shall Browbar by
                                Reema's liability to Customer exceed the amounts
                                paid by Customer for the Face Mask Products.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>FORCE MAJEURE</h5>
                              <p>
                                Browbar by Reema shall not be liable for any failure
                                or delay in delivery or in its performance under
                                these Face Mask Terms or otherwise due to any cause
                                beyond Browbar by Reema reasonable control,
                                including, without limitation, any act of war or
                                civil insurrection, national emergencies, pandemics,
                                acts of God, fire, explosion, storm, earthquake,
                                flood, embargo, riot, sabotage, industry-wide
                                strikes, lockouts, labor difficulties, supplier
                                failures, unavailability or shortages of materials,
                                or governmental acts.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>INDEMNIFICATION</h5>
                              <p>
                                Customer agrees to defend, indemnify and hold
                                Browbar by Reema, its directors, officers,
                                employees, agents and affiliates harmless from any
                                and all claims, including, but not limited to, any
                                third-party claims, liabilities, damages, costs and
                                expenses, including reasonable attorneys' fees, in
                                any way arising from, related to or in connection
                                with Customer's violation of the Face Mask Terms,
                                including without limitation Customer's failure to
                                ensure resellers comply with the Face Mask Terms
                                including the posting of SPECIFIC DISCLAIMER in
                                resale documentation.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>GOVERNING LAW; DISPUTE RESOLUTION</h5>
                              <p>
                                These Face Mask Terms shall be governed by,
                                construed and enforced in accordance with the laws
                                of the State of California, without giving effect to
                                any conflict of law provisions. Any dispute relating
                                in any way to these Face Mask Terms, the applicable
                                orders, invoices or to any purchase of Face Mask
                                Products shall be submitted to confidential, binding
                                arbitration in Los Angeles, California. Customer
                                hereby consents to exclusive jurisdiction and venue
                                in any state or federal court in Los Angeles,
                                California. Any arbitration under these Face Mask
                                Terms shall be conducted under the prevailing rules
                                of the American Arbitration Association. The
                                arbitrator's award shall be binding and may be
                                entered as a judgment in any court of competent
                                jurisdiction. To the fullest extent permitted by
                                applicable law, Browbar by Reema and Customer agree
                                that any dispute resolution proceedings will be
                                conducted only on an individual basis and not in a
                                class, consolidated or representative action. If
                                this class action waiver is found to be void or
                                unenforceable, the Face Mask Terms and any disputes
                                relating thereto shall be resolved in state or
                                federal court rather than in arbitration. Customer
                                further agrees that it will not be a member of any
                                putative or actual class in a class action brought
                                by anyone else against Browbar by Reema, nor will
                                Customer seek to become a class representative.
                                Customer further agrees that in any action Customer
                                initiates against Browbar by Reema, any relief
                                Customer seeks will be confined to relief on
                                Customer's own behalf.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>TRADEMARKS AND MARKETING</h5>
                              <p>
                                Browbar by Reema hereby authorizes Customer to use
                                Browbar by Reema's trademarks, logos, service marks,
                                trade names and service names (collectively the
                                "Marks") for the limited purpose of marketing and
                                selling the Face Mask Products under the Face Mask
                                Terms. Browbar by Reema reserves the right to review
                                and approve all uses of the Marks in Customer's
                                advertising and promotion of the Face Mask Products
                                prior to Customer's use thereof. Such approval will
                                not limit Customer's obligation to comply with all
                                applicable laws and will not be deemed an
                                endorsement or approval of any advertising content.
                                Customer will not use the Marks or any other name or
                                symbol in a way that may imply that Customer is an
                                agency or branch of Browbar by Reema. Customer will
                                not use any other name or symbol that is in any way
                                confusingly similar to the Marks. Customer agrees
                                that any rights or purported rights in any Marks
                                owned or licensed by Browbar by Reema (including
                                without limitation any URL or Internet domain name
                                that makes use of any Mark) acquired through
                                Customer's use shall belong solely to Browbar by
                                Reema. Upon expiration or termination of the Face
                                Mask Terms, Customer agrees to immediately
                                discontinue the use of the Marks in any manner
                                whatsoever.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>CONFIDENTIALITY</h5>
                              <p>
                                For purposes of the Face Mask Terms, "Confidential
                                Information" shall mean any information disclosed by
                                Browbar by Reema in connection with the potential or
                                actual purchase of the Face Mask Products which by
                                its nature is considered proprietary and
                                confidential, including but not limited to, all
                                pricing information for the Face Mask Products and
                                other information about the details of Customer's
                                order.
                              </p>
                              <p>
                                Confidential Information shall not include any
                                information which was (a) publicly available at the
                                time of disclosure, (b) became available without
                                breach of these Face Mask Terms by Customer, (c) was
                                in Customer's possession prior to the disclosure, as
                                evidenced by Customer's written records, or (d) was
                                rightfully acquired by Customer from a third party
                                who was lawfully in possession of the information
                                and was under no obligation to maintain its
                                confidentiality.
                              </p>
                              <p>
                                Customer agrees to maintain the confidentiality of
                                the Confidential Information, and agrees not to
                                disclose any of the foregoing to any third party
                                without Browbar by Reema's prior written consent.
                                Notwithstanding the foregoing, Customer may disclose
                                Confidential Information to its financial, tax and
                                legal advisors, provided such financial, tax and
                                legal advisors are obligated to maintain the
                                confidentiality of the Confidential Information,
                                either by written agreement or due to the nature of
                                their relationship with Customer.
                              </p>
                            </div>
                            <div className="mt-2">
                              <h5>SPECIFIC DISCLAIMER</h5>
                              <p>
                                Customer understands and agrees that the Face Mask
                                Products should not be used (i) in any surgical
                                setting or where significant exposure to liquid,
                                bodily or other hazardous fluids, may be expected;
                                (ii) in a clinical setting where the infection risk
                                level through inhalation exposure is high; or (iii)
                                in the presence of a high intensity heat source or
                                flammable gas. Customer further understands and
                                agrees that no warranties of any kind, either
                                express or implied, are made that the Face Mask
                                Products prevent infection or the transmission of
                                viruses or diseases. Except as specifically stated
                                in writing by Browbar by Reema, the Face Mask
                                Products are not surgical masks or filtering
                                facepiece respirators and no claims or
                                representations are made as to their (w) fluid
                                resistance, (x) particulate or bacteria filtration
                                efficiency, (y) differential pressure or (z)
                                flammability.
                              </p>
                            </div>
                          </div>
                          <div className="pt-2">
                            <Form.Group controlId="formBasicCheckbox">
                              <Form.Check
                                label="I have read and agreed to the Terms and Conditions"
                                className="conditions-box"
                                checked={checkTerms}
                                onChange={(e) => setCheckTerms(e.target.checked)}
                              />
                              {inputError?.checkTerms && (
                                <small className="validation-error">
                                  You have to accept the terms and conditions
                                </small>
                              )}
                            </Form.Group>


                          </div>
                        </div>
                      </div>

                      {/* SUBMISSION */}
                      <div className="col-xl-12 col-sm-12">
                        <div className="d-flex justify-content-between">
                          <Link to="/registration-landing">
                            <Button className="cancel-btn">CANCEL</Button>
                          </Link>
                          <Button
                            disabled={loading}
                            className="proceed-btn"
                            onClick={() => {
                              callApiFun()
                            }}
                          >
                            {loading ? "Loading..." : "AGREE & CONTINUE"}
                          </Button>
                        </div>
                      </div>

                    </>}
                </div>
              </form>
            </div>
          </div>

          {/* DOCUMENTS */}
          <div
            className={`documents-form ${stepBtn === "step-2" ? "d-block" : "d-none"
              }`}
          >
            <div className="col-xl-12 col-sm-12">
              <div className="details mt-0">
                <form>
                  <div className="row">
                    {/* DOCUMENTS LIST AND SUBMISSION */}
                    <div className="col-xl-12 col-sm-12">
                      <div className="form">
                        <div className="row mt-1">
                          <div className="col-xl-12 col-sm-12">
                            <div className="section-heading">
                              <h5 className="documents-heading">DOCUMENTS</h5>
                            </div>
                          </div>
                          {registrationDetails?.country === "United States" ? <div className="col-xl-6 col-sm-12">
                            <div className="form-group mt-15">
                              <label className="form-label">Please Upload your W-9 Form {w9Error && <small className="validation-error">
                                Document Required
                              </small>}</label>

                              <div className="file-input">
                                <input
                                  type="text"
                                  placeholder="Document Name"
                                  name="documentName"
                                  id="documentName"
                                  value={w9FileName}
                                  className="form-control small-width"
                                  onChange={(event) => setW9FileName(event.target.value)}
                                />

                              </div>

                              <input
                                type="file"
                                className="file-control"
                                name="w9"
                                id="w9"
                                onChange={handleW9Change}
                              />
                              {w9Loading ?
                                <div style={{ padding: '20px' }}>
                                  <Spinner />
                                </div> :
                                <div style={{ width: '180px' }}>

                                  {w9Preview && (
                                    <div style={{ marginTop: '10px' }}>
                                      {w9Preview.endsWith('.pdf') ? (
                                        // eslint-disable-next-line jsx-a11y/iframe-has-title
                                        <iframe src={IMG_URL + w9Preview} width="120px%" height="120px"></iframe>
                                      ) : (
                                        <img src={IMG_URL + w9Preview} alt={w9?.name} width={120} height={120} style={{ borderRadius: '2px' }} />
                                      )}
                                    </div>
                                  )}


                                  {w9 && w9Preview && (
                                    <div>
                                      <a style={{
                                        fontSize: '12px',
                                        color: '#1111eb'
                                      }} href={URL.createObjectURL(w9)} target="_blank" rel="noreferrer">
                                        {w9?.name}
                                      </a>{" "}
                                      <Button className="delete-btn-img" onClick={handleW9Delete}>
                                        <UilTrash color="red" size="18" />
                                      </Button>
                                    </div>
                                  )}
                                </div>}
                            </div>
                          </div> : <div className="col-xl-6 col-sm-12">
                            <div className="form-group mt-15">
                              <label className="form-label">Please Upload your W-8 Form {w8Error && <small className="validation-error">
                                Document Required
                              </small>}</label>

                              <div className="file-input">
                                <input
                                  type="text"
                                  placeholder="Document Name"
                                  name="documentName"
                                  id="documentName"
                                  value={w8FileName}
                                  className="form-control small-width"
                                  onChange={(event) => setW8FileName(event.target.value)}
                                />

                              </div>

                              <input
                                type="file"
                                className="file-control"
                                name="w8"
                                id="w8"
                                onChange={handleW8Change}
                              />
                              {w8Loading ?
                                <div style={{ padding: '20px' }}>
                                  <Spinner />
                                </div> :
                                <div style={{ width: '180px' }}>

                                  {w8Preview && (
                                    <div style={{ marginTop: '10px' }}>
                                      {w8Preview.endsWith('.pdf') ? (
                                        // eslint-disable-next-line jsx-a11y/iframe-has-title
                                        <iframe src={IMG_URL + w8Preview} width="120px%" height="120px"></iframe>
                                      ) : (
                                        <img src={IMG_URL + w8Preview} alt={w8?.name} width={120} height={120} style={{ borderRadius: '2px' }} />
                                      )}
                                    </div>
                                  )}


                                  {w8 && w8Preview && (
                                    <div>
                                      <a style={{
                                        fontSize: '12px',
                                        color: '#1111eb'
                                      }}
                                        href={URL.createObjectURL(w8)} target="_blank" rel="noreferrer">
                                        {w8?.name}
                                      </a>{" "}
                                      <Button className="delete-btn-img" onClick={handleW8Delete}>
                                        <UilTrash color="red" size="18" />
                                      </Button>
                                    </div>
                                  )}
                                </div>}
                            </div>
                          </div>}

                        </div>
                      </div>
                    </div>

                    {/* SUBMISSION */}
                    <div className="col-xl-12 col-sm-12">
                      <div className="d-flex justify-content-between mt-2">
                        <Button
                          className="cancel-btn"
                          onClick={() => setStepBtn("step-1")}
                        >
                          BACK TO APPLICATION FORM
                        </Button>
                        <Button
                          className="proceed-btn"
                          onClick={() => {
                            if (registrationDetails?.country === "United States") {
                              if (!w9?.name) {
                                setW9Error(true)
                              } else {
                                setW9Error(false)
                              }
                            } else {
                              if (!w8?.name) {
                                setW8Error(true)
                              } else {
                                setW8Error(false)
                              }
                            }

                            if (registrationDetails?.country === "United States") {
                              if (!w9Error) {
                                setStepBtn("step-3")
                              }
                            } else {
                              if (!w8Error) {
                                setStepBtn("step-3")
                              }
                            }

                          }}
                        // onClick={() => setStepBtn("step-3")}
                        >
                          SUBMIT &amp; REVIEW
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* ACCOUNT REVIEW */}
          {stepBtn === "step-3" && <div
            className={`account-review ${stepBtn === "step-3" ? "d-block" : "d-none"
              }`}
          >
            <div className="col-xl-12 col-sm-12">
              <div className="details mt-0">
                <form>
                  {/* COMPANY INFORMATION */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="form">
                      <h5>COMPANY INFORMATION</h5>
                      <div className="row">
                        <div className="col-xl-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Company Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={input?.companyName}
                              name="companyName"
                              onChange={(e) => onInputChange(e)}
                              id="companyName"
                              placeholder="Company Name"

                            />
                            {inputError?.companyName && <small className="validation-error">
                              Company Name is required
                            </small>}
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Website <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={input?.websiteURL}
                              name="websiteURL"
                              onChange={(e) => onInputChange(e)}
                              id="website"
                              placeholder="www.domain.com"
                            />
                            {inputError?.websiteURL && <small className="validation-error">
                              websiteURL is required
                            </small>}
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Company Email{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              disabled
                              type="email"
                              className="form-control"
                              value={input?.companyEmailAddress}
                              name="companyEmailAddress"
                              onChange={(e) => onInputChange(e)}
                              id="companyEmail"
                              placeholder="placeholder@domain.com"
                            />
                            {inputError?.companyEmailAddress && <small className="validation-error">
                              Company Email is required
                            </small>}
                          </div>
                        </div>

                        <div className="col-xl-4 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              What is your primary business activity?{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              isDisabled={true}
                              options={businessActivityOptions}
                              className="react-select mb-15"
                              classNamePrefix="select-control"
                              placeholder="Select Business Activity"
                              isSearchable
                              value={businessActivityOptions.find(option => option.value === input.primaryBusiness)}
                              onChange={(selectedOption) => {
                                setInput({
                                  ...input,
                                  primaryBusiness: selectedOption.value
                                });
                                setInputError({
                                  ...inputError,
                                  primaryBusiness: false
                                });
                              }}
                            />
                            {inputError?.primaryBusiness && <small className="validation-error">
                              Please select atleast one option
                            </small>}
                          </div>
                        </div>



                        <div className="col-xl-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Address <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={input?.address}
                              name="address"
                              onChange={(e) => onInputChange(e)}
                              id="address"
                              placeholder="Address"
                            />
                            {inputError?.address && <small className="validation-error">
                              Address is required
                            </small>}
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Address (Unit #)
                            </label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={input?.addressLine1}
                              name="addressLine1"
                              onChange={(e) => onInputChange(e)}
                              id="addressUnit"
                              placeholder="Address (Unit #)"
                            />
                          </div>
                        </div>

                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Country <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              isDisabled={true}
                              options={countriesList}
                              className="react-select mb-15"
                              classNamePrefix="select-control"
                              placeholder="Select Country"
                              isSearchable
                              value={countriesList.find(option => option.value === input.country)}
                              onChange={(selectedOption) => {
                                setInput({
                                  ...input,
                                  country: selectedOption.value
                                });
                                setInputError({
                                  ...inputError,
                                  country: false
                                });
                              }}

                            />
                            {inputError?.country && <small className="validation-error">
                              country is required
                            </small>}
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Province/State{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            {/* <Select
                                  options={statesList}
                                  className="react-select mb-15"
                                  classNamePrefix="select-control"
                                  placeholder="Select Province/State"
                                  isSearchable
                                  value={statesList.find(option => option.value === input.state)}
                                  onChange={(selectedOption) => {
                                    setInput({
                                      ...input,
                                      state: selectedOption.value
                                    });
                                    setInputError({
                                      ...inputError,
                                      state: false
                                    });
                                  }}
                                /> */}
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={input?.state}
                              name="state"
                              onChange={(e) => onInputChange(e)}
                              id="state"
                              placeholder="state"
                            />
                            {inputError?.state && <small className="validation-error">
                              Please entered province/state
                            </small>}
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              City <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={input?.city}
                              name="city"
                              onChange={(e) => onInputChange(e)}
                              id="city"
                              placeholder="City"
                            />
                            {inputError?.city && <small className="validation-error">
                              City is required
                            </small>}
                          </div>
                        </div>
                        <div className="col-xl-3 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Zip/Postal{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              disabled
                              type="number"
                              className="form-control"
                              value={input?.zipCode}
                              name="zipCode"
                              onChange={(e) => onInputChange(e)}
                              id="zipCode"
                              placeholder="Zip/Postal Code"
                            />
                            {inputError?.zipCode && <small className="validation-error">
                              ZIP/Postal Code is required
                            </small>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* CONTACT INFORMATION */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="form">
                      <h5>CONTACT INFORMATION</h5>
                      <div className="row">
                        <div className="col-xl-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              First Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={input?.firstName}
                              name="firstName"
                              onChange={(e) => onInputChange(e)}
                              id="fName"
                              placeholder="First Name"
                            />
                            {inputError?.firstName && <small className="validation-error">
                              First Name is required
                            </small>}
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Last Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={input?.lastName}
                              name="lastName"
                              onChange={(e) => onInputChange(e)}
                              id="lName"
                              placeholder="Last Name"
                            />
                            {inputError?.lastName && <small className="validation-error">
                              Last Name is required
                            </small>}
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Phone <span style={{ color: "red" }}>*</span>
                            </label>
                            <PhoneInput
                              disabled
                              value={input?.phoneNumber}
                              onChange={(e) => {
                                setInput({ ...input, 'phoneNumber': e })
                                setInputError({ ...inputError, 'phoneNumber': false })
                              }}
                              placeholder="Phone Number"
                              defaultCountry="US"
                              className="phone-number-select"
                            />
                            {inputError?.phoneNumber && <small className="validation-error">
                              Phone number should be valid
                            </small>}
                          </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Title <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              disabled
                              type="text"
                              className="form-control"
                              value={input?.title}
                              name="title"
                              onChange={(e) => onInputChange(e)}
                              id="title"
                              placeholder="Title"
                            />
                            {inputError?.title && <small className="validation-error">
                              Title is required
                            </small>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* WEB ACCOUNT INFORMATION */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="form">
                      <h5>WEB ACCOUNT INFORMATION</h5>
                      <div className="row">
                        <div className="col-xl-6 col-sm-12">
                          <div className="form-group">
                            <label className="form-label">
                              Email Address (this will be your account login
                              ID, or username){" "}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              disabled
                              type="email"
                              className="form-control"
                              value={input?.email}
                              name="email"
                              onChange={(e) => onInputChange(e)}
                              id="accountEmail"
                              placeholder="placeholder@domain.com"
                            />
                            {inputError?.email && <small className="validation-error">
                              Email Address is required
                            </small>}
                          </div>
                        </div>


                        <div className="col-xl-12 col-sm-12">
                          <Form.Check
                            label="Sign up for the latest news, discounts, and more"
                            className="conditions-box"
                            checked={newsletterSignup}

                          />

                          {/* {inputError?.checkTerms && (
                            <small className="validation-error">
                              You have to accept the terms and contisions
                            </small>
                          )} */}
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* TERMS AND CONDITIONS */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="terms-conditions">
                      <h5>TERMS &amp; CONDITIONS</h5>
                      <p>
                        By proceeding I acknowledge that I have read and agree
                        to the following terms and conditions:
                      </p>
                      <div className="terms-conditions-box">
                        <h5>
                          ALL SALES ARE SUBJECT TO THE FOLLOWING TERMS AND
                          CONDITIONS EXCEPT FOR FACE MASK PRODUCTS, WHICH ARE
                          DEFINED AND ADDRESSED BELOW:
                        </h5>
                        <div className="mt-2">
                          <h5>PRICES</h5>
                          <p>
                            All prices are subject to change without notice to
                            customer. All orders will be billed at the prices
                            prevailing at the time the order is placed.
                            Customers must notify Browbar By Reema in writing
                            within 30 days after receipt of the subject invoice
                            of any price disputes. If customer fails to notify
                            Browbar By Reema within such that 30-day period, any
                            and all claims or controversies brought by customer
                            and relating to such price dispute shall be deemed
                            time barred and waived.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>ORDERS</h5>
                          <p>
                            All orders are subject to acceptance by Browbar by
                            Reema. Browbar by Reema, in its sole discretion,
                            shall determine the channels of distribution of its
                            products. Browbar by Reema reserves all of its right
                            to refuse to supply product to customers and certain
                            customer locations. Only those customer internet
                            sites authorized in writing by Browbar by Reema may
                            sell and advertise Browbar by Reema products.
                            Browbar by Reema also reserves all rights to
                            prohibit Browbar by Reema products from being
                            advertised and or sold via the internet. Browbar by
                            Reema may immediately terminate the account of any
                            customer or customer internet site selling Browbar
                            by Reema products without written authorization, and
                            may bring any legal or other action for breach of
                            these terms and conditions. All sales of close-outs,
                            irregulars, seconds or b-grade products are final
                            and cannot be returned. All back orders will be
                            shipped F.O.B. Shipping point. No customer
                            deductions are permited for freight, parcel post,
                            charges, or like charges.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>TERMS OF PAYMENT</h5>
                          <p>
                            Payment for ordered goods is due and payable
                            pursuant to the time period specified in the
                            applicable invoice and these terms and conditions. A
                            service charge of 1½ % per month or the maximum
                            permitted by law will be charged on all payments for
                            goods not received within the number of days
                            specified on the invoice. Customer shall be
                            responsible for all costs, expenses, collections
                            agency commissions, and reasonable attorneys fees
                            that Browbar by Reema may incur in the collection of
                            any past due invoices. Browbar by Reema, in its sole
                            discretion, may terminate customer’s account at any
                            time should customer breach any of the terms and
                            conditions contained herein.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>RESTRICTIONS ON SALE</h5>
                          <p>
                            Customer is prohibited from selling Browbar by Reema
                            products other than through a Browbar by Reema
                            approved channel of distribution. Violation of this
                            condition may result in immediate termination of
                            customer’s account.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>CANCELLATIONS</h5>
                          <p>
                            All requests to cancel orders must be submitted in
                            writing to Browbar by Reema management at least 45
                            days before the start ship date and must be approved
                            by Browbar by Reema in writing. Cancellations may
                            affect volume discounts and may be subject to
                            processing fees. Special or custom orders or special
                            make up (collectively, “smu“) product orders may not
                            be cancelled. Browbar by Reema will not accept
                            returns for unapproved cancellations.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>NO TRANSHIPPING</h5>
                          <p>
                            Unauthorized transshipment or resale of any Browbar
                            by Reema merchandise to any person other than the
                            end consumer is strictly prohibited, may result in
                            the cancellation of existing orders, the termination
                            of customer’s business relationship with Browbar by
                            Reema, and/or legal action for, including but not
                            limited to, breach of these terms and conditions.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>CLAIMS</h5>
                          <p>
                            Customer must report all merchandise shortages,
                            overages, or damages to the Browbar by Reema
                            customer service department within 7 days of
                            merchandise receipt. To report shortages, overages,
                            or damages, customer must provide Browbar by Reema
                            with an invoice number or order number, as well as a
                            list of products at issue, itemized by product code,
                            size and quantity.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>INSPECTION BEFORE PRINTING</h5>
                          <p>
                            Customer must inspect all merchandise before
                            printing. Browbar by Reema will not accept returns
                            of any printed or otherwise decorated merchandise,
                            or any washed merchandise under any circumstances.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>RETURN POLICY</h5>
                          <p>
                            All returns are subject to re-stocking charges of
                            not less than 20%, must be requested within 7 days
                            of merchandise receipt, and must be pre-approved in
                            writing by Browbar by Reema customer service. If
                            approval is obtained, customer must notify Browbar
                            by Reema customer service of its intent to return
                            authorized product. All return requests must be
                            accompanied by an order number or invoice number,
                            the return authorization number (ra #) and label,
                            both of which must be attached to the carton or
                            other packaging containing the returned product.
                            Customer is responsible for all freight and shipping
                            charges on items returned that are not the result of
                            Browbar by Reema error. All products must be
                            returned in the same condition in which received.
                            Upon receipt and inspection of returned product, a
                            credit will be issued in the amount equal to the
                            invoice price of such returned product, less any
                            applicable discount. If no invoice number is
                            provided, Browbar by Reema will issue customer a
                            credit based on the then current product pricing. No
                            returns of smu product will be approved by Browbar
                            by Reema. In addition, no returns of relabeled,
                            printed, embroided, decorated or washed garmets will
                            be approved by Browbar by Reema.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>DEFECTIVE MERCHANDISE</h5>
                          <p>
                            All defective merchandise must be reported to
                            Browbar by Reema customer service department within
                            7 days of merchandise receipt. Defective merchandise
                            refunds will be handled on a case-by-case basis, at
                            Browbar by Reema’s sole discretion.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>PRODUCT WARRANTY</h5>
                          <p>
                            Browbar by Reema does not make, and hereby
                            disclaims, any and all warranties, express or
                            implied, including without limitation any warranties
                            of merchantability or fitness for a particular
                            purpose.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>LIMITATION OF LIABILITY</h5>
                          <p>
                            In no event shall Browbar by Reema be liable to the
                            customer for any loss of profit, interruption of
                            business, or any special, indirect, consequential or
                            incidental damages arising in contract (including
                            breach of warranty), in tort (including strict
                            liability or negligence) or otherwise, even if
                            Browbar by Reema has been notified or otherwise made
                            aware of the possibility of such damages.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>FAIR LABOR STANDARDS ACT</h5>
                          <p>
                            Browbar by Reema hereby certifies that the
                            merchandise covered by any Browbar by Reema invoice
                            was manufactured in compliance with the requirements
                            of the fair labor standards act of 1938, as amended.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>FORCE MAJEURE</h5>
                          <p>
                            Browbar by Reema shall not be liable for any failure
                            or delay in delivery or in its performance under
                            these terms and conditions or otherwise due to any
                            cause beyond Browbar by Reema reasonable control,
                            including, without limitation, any act of war or
                            civil insurrection, national emergencies, acts of
                            god, fire, explosion, storm, earthquake, flood,
                            embargo, riot, sabotage, industry-wide strikes,
                            lockouts, labor difficulties, supplier failures,
                            unavailability or shortages of materials, or
                            governmental acts (collectively, “force majeure“).
                            Where any product scheduled for delivery becomes
                            unavailable as a result of a force majeure event,
                            Browbar by Reema may, at its sole discretion,
                            substitute such product with a comparable product.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>GOVERNING LAW; VENUE</h5>
                          <p>
                            These terms and conditions shall be deemed to have
                            been entered into in the state of california and
                            shall in all respects be interpreted, enforced and
                            governed under the internal laws of the state of
                            california without regard to principles of conflicts
                            of law that would apply any other state law.
                            Customer hereby agrees and irrevocably consents to
                            submit to the exclusive jurisdiction and venue of
                            the courts of the state of california and of the
                            united states of america located in the state of
                            california, for any and all claims arising from or
                            related to these termes and conditions. Each party
                            hereby waives their right to a jury trial in any
                            such controversy. Any action by customer for breach
                            of contract must be commenced within one year from
                            the merchandise ship date.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>GENERAL</h5>
                          <p>
                            All purchases and sales of Browbar by Reema’s
                            products to customer shall be made solely on these
                            terms and conditions. These terms and conditions
                            shall be applicable in full to all such purchases
                            and sales. All terms and conditions contained in any
                            purchase order or similar order form from customer
                            are hereby rejected by Browbar by Reema in all
                            respects. Any modification, supplement, or amendment
                            of these terms and conditions shall be made in
                            writing by Browbar by Reema authorized management
                            representative only. Any term or provision of these
                            terms and conditions that is invalid or
                            unenforceable in any situation shall not affect the
                            validity or enforceability of the remaining terms
                            and conditions or the validity or enforceability of
                            the offending term or condition in any other
                            situation. Acceptance of these terms and conditions
                            shall be confirmed by electronic signature copy by
                            pdf, jpeg file or comparable format or by facsmilie,
                            and any such electronic signature copy shall
                            constitute an original for all purposes. These terms
                            and conditions and Browbar by Reema’s invoice
                            contain the entire agreement between Browbar by
                            Reema and customer, and supersedes all previous
                            contracts or agreements between Browbar by Reema and
                            customer. Customer agrees that Browbar by Reema is
                            not bound by any representation, promise, condition,
                            inducement or warranty, express or implied, not
                            included above.
                          </p>
                        </div>
                        <h5 className="mt-2">
                          ALL SALES OF ANY FACE MASK, FACE SHIELD, FACE COVER,
                          OR SIMILAR PRODUCT (“FACE MASK PRODUCTS“) ARE SUBJECT
                          TO THE FOLLOWING TERMS AND CONDITIONS (“FACE MASK
                          TERMS“):
                        </h5>
                        <div className="mt-2">
                          <h5>ACCEPTANCE AND CONTRACT</h5>
                          <p>
                            Browbar by Reema's acceptance of customer's order
                            for any face mask product is expressly conditioned
                            upon customer's acceptance of the face mask terms.
                            The face mask terms hereof shall constitute a
                            binding contract between Browbar by Reema and
                            customer concerning the face mask products sold
                            hereunder. None of these face mask terms may be
                            supplemented, modified, superseded, amended or
                            otherwise altered, except by a written instrument
                            signed by an authorized signatory of Browbar by
                            Reema. Failure of Browbar by Reema to object to any
                            terms or conditions which may be contained in any
                            document or form of customer shall not be construed
                            as a waiver of these face mask terms, nor as an
                            acceptance of any such terms and conditions.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>PRICES</h5>
                          <p>
                            All prices are subject to change without notice to
                            Customer. Orders are billed at the prices prevailing
                            at the time the order is placed. The price set forth
                            in a Customer's invoice shall govern that order.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>TERMS OF PAYMENT</h5>
                          <p>
                            Unless otherwise set forth in the applicable
                            invoice, payment for ordered Face Mask Products is
                            due and payable prior to any shipment by Browbar by
                            Reema of any of the ordered Face Mask Products.
                            Browbar by Reema reserves the right to cancel any
                            order for which payment has not been received by
                            Browbar by Reema. A service charge of 1½ % per month
                            or the maximum permitted by law, if lower than 1½ %
                            per month, will be charged on all payments for Face
                            Mask Products not received within the number of days
                            specified on the invoice. Customer shall be
                            responsible for all costs, expenses, collections
                            agency commissions, and reasonable attorney's fees
                            that Browbar by Reema may incur in the collection of
                            any past due invoices. Browbar by Reema, in its sole
                            discretion, may terminate Customer's account at any
                            time should Customer breach any of the terms and
                            conditions contained herein.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>SHIPPING</h5>
                          <p>
                            All Face Mask Products sold hereunder are F.O.B.
                            unless otherwise stated in the applicable invoice.
                            All claims for shipping loss or damage are
                            Customer's responsibility. Delivery dates are not
                            guaranteed and Browbar by Reema has no liability for
                            damages that may be incurred due to any delay in
                            shipment of Face Mask Products hereunder. Taxes are
                            excluded unless otherwise stated in the applicable
                            invoice.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>RESTRICTIONS ON RESALE</h5>
                          <p>
                            Customer is prohibited from reselling the ordered
                            Face Mask Products other than through a Browbar by
                            Reema approved channel of distribution or as
                            otherwise approved in writing by Browbar by Reema.
                            If Customer resells the Face Mask Products to a
                            reseller, Customer shall ensure that such reseller
                            complies with the Face Mask Terms set forth herein.
                            Customer shall ensure that all resellers of the Face
                            Mask Products include in all resale documentation
                            prominent disclosures or postings of the information
                            set forth in the SPECIFIC DISCLAIMER of these Face
                            Mask Terms. Violation of the provisions of the Face
                            Mask Terms may result in immediate termination of
                            Customer's account.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>CLAIMS</h5>
                          <p>
                            Customer must report all shortages of ordered Face
                            Mask Products to the Browbar by Reema customer
                            service department within 7 days of receipt of
                            ordered Face Mask Products.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>NO RETURNS</h5>
                          <p>
                            All Face Mask Products are sold "as-is" and are not
                            returnable absent prior written approval by Browbar
                            by Reema.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>AS-IS; NO WARRANTY</h5>
                          <p>
                            The Face Mask Products are provided "as-is" without
                            warranties of any kind and without limiting the
                            foregoing, Browbar by Reema hereby disclaims any and
                            all warranties, express or implied, relating to the
                            Face Mask Products, including without limitation any
                            warranties of merchantability or fitness for a
                            particular purpose. Customer acknowledges that they
                            have not relied on any warranty or representation in
                            placing the order for the purchase of Face Mask
                            Products.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>LIMITATION OF LIABILITY</h5>
                          <p>
                            In no event shall Browbar by Reema be responsible or
                            liable to Customer (or claims of any third party
                            against Customer) for any loss of profit,
                            interruption of business, loss of goodwill, loss of
                            business opportunity or any special, indirect,
                            collateral, punitive or exemplary, incidental or
                            consequential damages arising in contract (including
                            breach of warranty), in tort (including strict
                            liability or negligence) or otherwise, even if
                            Browbar by Reema has been advised of the possibility
                            of such damages. In no event shall Browbar by
                            Reema's liability to Customer exceed the amounts
                            paid by Customer for the Face Mask Products.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>FORCE MAJEURE</h5>
                          <p>
                            Browbar by Reema shall not be liable for any failure
                            or delay in delivery or in its performance under
                            these Face Mask Terms or otherwise due to any cause
                            beyond Browbar by Reema reasonable control,
                            including, without limitation, any act of war or
                            civil insurrection, national emergencies, pandemics,
                            acts of God, fire, explosion, storm, earthquake,
                            flood, embargo, riot, sabotage, industry-wide
                            strikes, lockouts, labor difficulties, supplier
                            failures, unavailability or shortages of materials,
                            or governmental acts.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>INDEMNIFICATION</h5>
                          <p>
                            Customer agrees to defend, indemnify and hold
                            Browbar by Reema, its directors, officers,
                            employees, agents and affiliates harmless from any
                            and all claims, including, but not limited to, any
                            third-party claims, liabilities, damages, costs and
                            expenses, including reasonable attorneys' fees, in
                            any way arising from, related to or in connection
                            with Customer's violation of the Face Mask Terms,
                            including without limitation Customer's failure to
                            ensure resellers comply with the Face Mask Terms
                            including the posting of SPECIFIC DISCLAIMER in
                            resale documentation.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>GOVERNING LAW; DISPUTE RESOLUTION</h5>
                          <p>
                            These Face Mask Terms shall be governed by,
                            construed and enforced in accordance with the laws
                            of the State of California, without giving effect to
                            any conflict of law provisions. Any dispute relating
                            in any way to these Face Mask Terms, the applicable
                            orders, invoices or to any purchase of Face Mask
                            Products shall be submitted to confidential, binding
                            arbitration in Los Angeles, California. Customer
                            hereby consents to exclusive jurisdiction and venue
                            in any state or federal court in Los Angeles,
                            California. Any arbitration under these Face Mask
                            Terms shall be conducted under the prevailing rules
                            of the American Arbitration Association. The
                            arbitrator's award shall be binding and may be
                            entered as a judgment in any court of competent
                            jurisdiction. To the fullest extent permitted by
                            applicable law, Browbar by Reema and Customer agree
                            that any dispute resolution proceedings will be
                            conducted only on an individual basis and not in a
                            class, consolidated or representative action. If
                            this class action waiver is found to be void or
                            unenforceable, the Face Mask Terms and any disputes
                            relating thereto shall be resolved in state or
                            federal court rather than in arbitration. Customer
                            further agrees that it will not be a member of any
                            putative or actual class in a class action brought
                            by anyone else against Browbar by Reema, nor will
                            Customer seek to become a class representative.
                            Customer further agrees that in any action Customer
                            initiates against Browbar by Reema, any relief
                            Customer seeks will be confined to relief on
                            Customer's own behalf.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>TRADEMARKS AND MARKETING</h5>
                          <p>
                            Browbar by Reema hereby authorizes Customer to use
                            Browbar by Reema's trademarks, logos, service marks,
                            trade names and service names (collectively the
                            "Marks") for the limited purpose of marketing and
                            selling the Face Mask Products under the Face Mask
                            Terms. Browbar by Reema reserves the right to review
                            and approve all uses of the Marks in Customer's
                            advertising and promotion of the Face Mask Products
                            prior to Customer's use thereof. Such approval will
                            not limit Customer's obligation to comply with all
                            applicable laws and will not be deemed an
                            endorsement or approval of any advertising content.
                            Customer will not use the Marks or any other name or
                            symbol in a way that may imply that Customer is an
                            agency or branch of Browbar by Reema. Customer will
                            not use any other name or symbol that is in any way
                            confusingly similar to the Marks. Customer agrees
                            that any rights or purported rights in any Marks
                            owned or licensed by Browbar by Reema (including
                            without limitation any URL or Internet domain name
                            that makes use of any Mark) acquired through
                            Customer's use shall belong solely to Browbar by
                            Reema. Upon expiration or termination of the Face
                            Mask Terms, Customer agrees to immediately
                            discontinue the use of the Marks in any manner
                            whatsoever.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>CONFIDENTIALITY</h5>
                          <p>
                            For purposes of the Face Mask Terms, "Confidential
                            Information" shall mean any information disclosed by
                            Browbar by Reema in connection with the potential or
                            actual purchase of the Face Mask Products which by
                            its nature is considered proprietary and
                            confidential, including but not limited to, all
                            pricing information for the Face Mask Products and
                            other information about the details of Customer's
                            order.
                          </p>
                          <p>
                            Confidential Information shall not include any
                            information which was (a) publicly available at the
                            time of disclosure, (b) became available without
                            breach of these Face Mask Terms by Customer, (c) was
                            in Customer's possession prior to the disclosure, as
                            evidenced by Customer's written records, or (d) was
                            rightfully acquired by Customer from a third party
                            who was lawfully in possession of the information
                            and was under no obligation to maintain its
                            confidentiality.
                          </p>
                          <p>
                            Customer agrees to maintain the confidentiality of
                            the Confidential Information, and agrees not to
                            disclose any of the foregoing to any third party
                            without Browbar by Reema's prior written consent.
                            Notwithstanding the foregoing, Customer may disclose
                            Confidential Information to its financial, tax and
                            legal advisors, provided such financial, tax and
                            legal advisors are obligated to maintain the
                            confidentiality of the Confidential Information,
                            either by written agreement or due to the nature of
                            their relationship with Customer.
                          </p>
                        </div>
                        <div className="mt-2">
                          <h5>SPECIFIC DISCLAIMER</h5>
                          <p>
                            Customer understands and agrees that the Face Mask
                            Products should not be used (i) in any surgical
                            setting or where significant exposure to liquid,
                            bodily or other hazardous fluids, may be expected;
                            (ii) in a clinical setting where the infection risk
                            level through inhalation exposure is high; or (iii)
                            in the presence of a high intensity heat source or
                            flammable gas. Customer further understands and
                            agrees that no warranties of any kind, either
                            express or implied, are made that the Face Mask
                            Products prevent infection or the transmission of
                            viruses or diseases. Except as specifically stated
                            in writing by Browbar by Reema, the Face Mask
                            Products are not surgical masks or filtering
                            facepiece respirators and no claims or
                            representations are made as to their (w) fluid
                            resistance, (x) particulate or bacteria filtration
                            efficiency, (y) differential pressure or (z)
                            flammability.
                          </p>
                        </div>
                      </div>
                      <div className="pt-2">
                        <Form.Group controlId="formBasicCheckbox">
                          <Form.Check
                            label="I have read and agreed to the Terms and Conditions"
                            className="conditions-box"
                            checked={checkTerms}
                            onChange={(e) => setCheckTerms(e.target.checked)}
                          />
                          {inputError?.checkTerms && (
                            <small className="validation-error">
                              You have to accept the terms and conditions
                            </small>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  {/* SUBMISSION */}
                  <div className="col-xl-12 col-sm-12">
                    <div className="d-flex justify-content-between">

                      <Button className="cancel-btn" onClick={() => {
                        setStepBtn('step-1')
                      }}>BACK</Button>

                      {/* <Button
                        disabled={loading}
                        className="proceed-btn"
                        onClick={() => {
                          navigate('/')
                        }}
                      >
                        {loading ? "Loading..." : "AGREE & PROCEED"}
                      </Button> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>}

          <div
            className={`account-review ${stepBtn === "step-4" ? "d-block" : "d-none"
              }`}
          >

            <div className="col-xl-12 col-sm-12">

              <div className="details mt-3">
                <div className="form">
                  <p className="justify-content-center text-center align-content-center" ><img width={'60px'} src={require("../../../assets/icons/checked.png")} alt={'success icon'} /></p>
                  <h3 className="justify-content-center text-center align-content-center">Your Details have been saved successfully.</h3>
                  <p className="justify-content-center text-center align-content-center">You'll hear back from us in
                    1-2 business days.</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivateLabel;
