import React from "react";

const ResaleCertificates = () => {
    return (
      <section className="resale-certificates">
        <div className="container">
          <div className="certificates-text">
            <h1>RESALE CERTIFICATES</h1>
            <p>
              Please send in a signed and fully completed resale certificate for{" "}
              <b style={{ textDecoration: "underline" }}>every ship-to state</b>{" "}
              to{" "}
              <a
                href="mailto:wholesale@browbarbyreema.com"
                style={{ color: "#000", fontWeight: "400" }}
              >
                wholesale@browbarbyreema.com
              </a>
              . If your state is not listed on a multi-state form, you cannot
              use it.
            </p>
            <p>
              Please complete all fields and follow all of the following
              guidelines.{" "}
              <b>
                Resale certificates not meeting these guidelines will not be
                processed.
              </b>
            </p>
            <ul>
              <li>
                All resale certificates must be made out to Browbar By Reema as
                the vendor/seller
              </li>
              <li>
                Name of Buyer fields should be completed using the company name
                on your Browbar By Reema account and resale license
              </li>
              <li>
                A statement including "For resale" must be included in any
                general descriptions of items/property being sold
              </li>
              <li>
                If your company is registered in multiple states and/or orders
                are shipped to multiple states, complete the{" "}
                <a href="/" style={{ color: "#000", fontWeight: "400" }}>
                  "Multi-State Uniform Resale Certificate"
                </a>
              </li>
            </ul>
            <p>
              The following blank resale certificates are provided for your
              convenience. This is not intended to be tax advice. If you have
              any questions regarding how to complete a resale certificate or
              what resale certificate is appropriate for your state, please
              contact your state’s Department of Revenue directly.
            </p>
          </div>
          {/* <div className="certificates-link">
            <h5>BLANK RESALE CERTIFICATES</h5>
            <div className="row mt-2">
                <div className="col-xl-3 col-sm-12">
                    <div className="certificate">
                        <p>California</p>
                    </div>
                </div>
            </div>
          </div> */}
        </div>
      </section>
    );
};

export default ResaleCertificates;