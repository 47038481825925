/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import actions from '../../redux/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import CloseButton from 'react-bootstrap/CloseButton';
import {
  Modal, Row, Col, FormGroup
} from "reactstrap";
import {
  register,
  login,
  resendOtp,
  verifyOtp,
  loginVerifyEmail,
  forgotPassword,
} from "../../service/api";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";

/* IMAGE IMPORTS */
import AccountBenefits from '../../assets/banners/account-benefits.webp';
import makeup_login from '../../assets/makeup_login.jpg';

/* ICON IMPORTS */
import ProductDistributorIcon from '../../assets/icons/product-distributor.png';
import PrivateReseller from '../../assets/icons/private-reseller.png';
import PrivateLabel from '../../assets/icons/private-label.png';
import RetailWebsite from '../../assets/icons/retail-website.png';

const RegistrationLanding = () => {
  const [distributorToggle, setDistributorToggle] = useState(false);
  const [resellerToggle, setResellerToggle] = useState(false);
  const [privateLabelToggle, setPrivateLabelToggle] = useState(false);
  const [retailToggle, setRetailToggle] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showLoginPassword, setShowLoginPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()

  const { registrationDetails, loggedIn } = useSelector(state => state.user)

  const [loginInput, setLoginInput] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [inputLogin, setInputLogin] = useState({
    name: false,
    email: false,
    password: false,
  });


  const [phoneNumber, setPhoneNumber] = useState(0);
  const [otp, setOtp] = useState(null);
  const [otpError, setOtpError] = useState(false);

  const loginTabs = {
    LOGIN: "LOGIN",
    VERIFY: "VERIFY",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    SEND_OTP: "SEND_OTP",
    SEND_PASSWORD: "SEND_PASSWORD",
  };

  const [loginActiveTab, setLoginActiveTab] = useState(loginTabs.LOGIN);

  const onLogchange = (e) => {
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
    setInputLogin({ ...inputLogin, [e.target.name]: false });
  };

  function emailValidation(body) {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(body) === false) {
      return false;
    }
    return true;
  }

  const [loading, setLoading] = useState(false);

  const loginForm = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!loginInput?.password) {
      setInputLogin({ ...inputLogin, password: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    submitForm.append("password", loginInput.password);
    submitForm.append("role", "63bbbbbd72d26924462631bb");
    setLoading(true);
    login(submitForm)
      .then((res) => {
        if (res?.status) {
          enqueueSnackbar(res?.message, { variant: "success" });
          dispatch(actions.setUser(res?.data?.userData));
          dispatch(actions.setToken(res?.data?.token));

          localStorage.setItem("authenticated", true);
          localStorage.setItem("token", res?.data?.token);

          if (!res?.data?.userData?.isEmailVerified) {
            loginVerifyEmail({ email: loginInput.email }).then((res) => {
              console.log("verify email--->", res);
            });
            setLoginActiveTab(loginTabs.VERIFY);
            return;
          } else {
            dispatch(actions.setLoggedIn(true));
            setModalOpen(false)
            sessionStorage.setItem('alreadyShow', 'alredy shown');
            console.log("navigate--->");
          }
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const resendLoginOtpFun = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    setLoading(true);
    resendOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const sendOtp = (e) => {
    e.preventDefault();
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true);
      return;
    }
    const submitForm = new FormData();
    submitForm.append("code", otp);
    console.log("send otp");
    setLoading(true);
    verifyOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          navigate("/");
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setOtp("");
        setLoading(false);
      });
  };

  const forgotPasswordFun = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!loginInput?.password) {
      // enqueueSnackbar("Please enter your password");
      setInputLogin({ ...inputLogin, password: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    submitForm.append("password", loginInput.password);
    submitForm.append("role", "63bbbbbd72d26924462631bb");
    setLoading(true);
    forgotPassword(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          setLoginActiveTab(loginTabs.LOGIN);
          setLoginInput({ ...loginInput, name: "", email: "", password: "" });
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const [otpMatch, setOtpMatch] = useState(null);
  const sendOtpToMail = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    // if (!loginInput?.password) {
    //   // enqueueSnackbar("Please enter your password");
    //   setInputLogin({ ...inputLogin, password: true })
    //   return
    // }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    // submitForm.append("password", loginInput.password);
    setLoading(true);
    loginVerifyEmail(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          setOtpMatch(res?.data);
          setLoginActiveTab(loginTabs.SEND_OTP);
          // setLoginInput({ ...loginInput, name: '', email: '', password: '' })
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message);
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const verifyOtpToChangePassword = (e) => {
    e.preventDefault();
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true);
      return;
    }
    if (otp === otpMatch) {
      setLoginActiveTab(loginTabs.SEND_PASSWORD);
      enqueueSnackbar("OTP verified successfully", { variant: "success" });
    } else {
      enqueueSnackbar("The OTP enetered is incorrect", { variant: "error" });
    }
  };

  useState(() => {
    var is_modal_show = sessionStorage.getItem('alreadyShow');
    console.log(is_modal_show)
    if (is_modal_show != 'alredy shown' && !loggedIn) {
      setModalOpen(true)
      sessionStorage.setItem('alreadyShow', 'alredy shown');
    }
  }, [modalOpen, loggedIn])
  return (

    <section className="registration-landing">
      {/* BANNER */}
      <div className="banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-12 col-sm-12">
              <h5>Apply for a Wholesale Account</h5>
              <h1>
                JOIN THE FASTEST
                <br />
                GROWING MAKEUP BRAND
              </h1>
            </div>
            <div className="col-xl-12 col-sm-12">
              <div className="row mt-3 mb-3">
                <div className="col-xl-3 col-sm-12">
                  <div className="category-box">
                    <div className="icon">
                      <img
                        src={ProductDistributorIcon}
                        alt=" Do you have a brand salon, a large business or beauty professional? If this
                        sounds like you, register here."
                      />
                    </div>
                    <div
                      className={`text ${distributorToggle ? "d-block" : "d-none"
                        }`}
                    >
                      <h5>
                        Do you have a brand salon, a large business or beauty professional? If this
                        sounds like you, register here.
                      </h5>
                    </div>
                    <div
                      className={`text ${distributorToggle ? "d-none" : "d-block"
                        }`}
                    >
                      <h5>
                        If you are a distributor, brand salon or retail business, then register here.
                      </h5>
                    </div>
                    {/* <Button
                      className="learn-more"
                      onClick={() => setDistributorToggle(!distributorToggle)}
                    >
                      {distributorToggle ? "Show Less" : "Learn more"}
                    </Button> */}
                    <Button
                      className="register-now"
                      onClick={() => {
                        dispatch(actions.setRegistraionDetails({ formType: 'distributor' }))
                        navigate("/product-distributor")
                      }}
                    >
                      REGISTER NOW
                    </Button>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-12">
                  <div className="category-box">
                    <div className="icon">
                      <img
                        src={PrivateReseller}
                        alt="Are you a Beauty Professional or private reseller without a reseller's license ?"
                      />
                    </div>
                    <div
                      className={`text ${resellerToggle ? "d-block" : "d-none"
                        }`}
                    >
                      <h5>
                        Do you have a small business ? If this sounds like you,
                        register here.
                      </h5>
                    </div>
                    <div
                      className={`text ${resellerToggle ? "d-none" : "d-block"
                        }`}
                    >
                      <h5>
                        If you are a beauty professional or store proprietor, then register here.
                      </h5>
                    </div>
                    {/* <Button
                      className="learn-more"
                      onClick={() => setResellerToggle(!resellerToggle)}
                    >
                      {resellerToggle ? "Show Less" : "Learn more"}
                    </Button> */}
                    <Button
                      className="register-now"
                      onClick={() => {
                        dispatch(actions.setRegistraionDetails({ formType: 'privateSeller' }))
                        navigate("/beauty-professional")
                      }}
                    >
                      REGISTER NOW
                    </Button>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-12">
                  <div className="category-box">
                    <div className="icon">
                      <img
                        src={PrivateLabel}
                        alt="Are you looking to create a private label ?"
                      />
                    </div>
                    <div
                      className={`text ${privateLabelToggle ? "d-block" : "d-none"
                        }`}
                    >
                      <h5>
                        Do you have a brand salon or a large business? If this
                        sounds like you, register here.
                      </h5>
                    </div>
                    <div
                      className={`text ${privateLabelToggle ? "d-none" : "d-block"
                        }`} style={{ paddingBottom: '20px' }}
                    >
                      <h5>
                        If you are looking to launch your own brand, then register here.
                      </h5>
                    </div>
                    {/* <Button
                      className="learn-more"
                      onClick={() => {

                        setPrivateLabelToggle(!privateLabelToggle)
                      }}
                    >
                      {privateLabelToggle ? "Show Less" : "Learn more"}
                    </Button> */}
                    <Button
                      className="register-now"
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        dispatch(actions.setRegistraionDetails({ formType: 'privateLabel' }))
                        navigate("/private-label")
                      }}
                    >
                      REGISTER NOW
                    </Button>
                  </div>
                </div>
                <div className="col-xl-3 col-sm-12">
                  <div className="category-box">
                    <div className="icon">
                      <img
                        src={RetailWebsite}
                        alt="Not a business, but looking to shop our products ?"
                      />
                    </div>
                    <div
                      className={`text ${retailToggle ? "d-block" : "d-none"
                        }`}
                    >
                      <h5>
                        You don't own a business but still want to shop our products? If this
                        sounds like you, visit our retail website.
                      </h5>
                    </div>
                    <div
                      className={`text ${retailToggle ? "d-none" : "d-block"
                        }`}
                    >
                      <h5>
                        Not a business, but looking to shop our products
                      </h5>
                    </div>
                    {/* <Button
                      className="learn-more"
                      onClick={() => setRetailToggle(!retailToggle)}
                    >
                      {retailToggle ? "Show Less" : "Learn more"}
                    </Button> */}
                    <a
                      href="https://browbarbyreema.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        className="register-now"
                        style={{ marginTop: "30px" }}
                      >
                        VISIT OUR RETAIL WEBSITE
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-4 col-sm-12">
              <div className="retail-box">
                <div className="box-header">
                  <h5>Not a business, but looking to shop ?</h5>
                </div>
                <div className="box-body">
                  <Link className="retail-link">
                    <p>
                      Shop Retail{" "}
                      <span>
                        <ExternalLink color="#000" size="16" />
                      </span>
                    </p>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="overlay" />
      </div>

      {/* ACCOUNT HOLDER BENEFITS */}
      <div className="account-holder-benefits">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-sm-12 " >
              <img src={AccountBenefits}  alt="Account Benefits" className='justify-content-md-center mx-auto d-block' />
            </div>
            <div className="col-xl-6 col-sm-12">
              <div className="benefits-text">
                <h4>HOW TO GET STARTED</h4>
                {/* <h5>READY TO GET STARTED</h5> */}
                <p>
                  Fill out the account application form and our wholesale sales
                  representatives will get in touch with you within one business
                  day. We believe that your success is our success. We’ve helped
                  hundreds of clients grow their retail sales and become more
                  profitable
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ'S */}
      <div className="faqs">
        <div className="container">
          <div className="row">
            <h4>FAQS</h4>
            <div className="col-xl-8 col-sm-12">
              <div className="faq-box">
                <h5 className="question">
                  What is a resale license or exemption certificate?
                </h5>
                <p className="answer">
                  A resale license or exemption certificate is a state-specific
                  document that purchasers provide to their vendors when
                  purchasing product for resale, so that they may be exempt from
                  paying sales tax.
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-sm-12">
              <div className="faq-box">
                <h5 className="question">
                  Do I need a resale license to open a wholesale account?
                </h5>
                <p className="answer">
                  Beauty professionals are welcomed to register for a
                  Makers Account; however, only licensed resellers and
                  promotional product distributors are eligible for waived sales
                  tax.
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-sm-12">
              <div className="faq-box">
                <h5 className="question">
                  If I have a state-issued resale license, do I also need to
                  complete a resale/exemption certificate?
                </h5>
                <p className="answer">
                  Yes, with the exception of a few states. This certifies that
                  you are purchasing your wholesale product with the intent to
                  resell. For more information, contact your state's Department
                  of Revenue.
                </p>
              </div>
            </div>
            <div className="col-xl-8 col-sm-12">
              <div className="faq-box">
                <h5 className="question">
                  How long will it take for my account to be approved?
                </h5>
                <p className="answer">
                  Once all valid documents are received, your account will be
                  approved within 2 business days.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay" />
      </div>
      {/* <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <div className='modal-body'>
          <Row className='align-items-center'>
            <Col lg="6" sm="12">
              <img src={makeup_login} alt="makeup_login" className='makeup_login' />
            </Col>

            {loading ? (
              <Col
                lg="6"
                sm="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: '25px'
                }}
              >
                <Spinner />
              </Col>
            ) : loginActiveTab === loginTabs.LOGIN ? (
              <Col lg="6" sm="12">
                <div className="modal-close">
                  <CloseButton className='close-btn' onClick={() => { setModalOpen(false) }} />
                </div>
                <div className="login_wrap">
                  <h3>Login to your Account</h3>
                  <p>
                    If you are a registered user, please enter your email and
                    password.
                  </p>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        value={loginInput?.email}
                        onChange={onLogchange}
                      />
                      {inputLogin?.email ? (
                        <small
                          className="validation-error"
                          style={{ color: "red", marginBottom: "0px" }}
                        >
                          {inputLogin?.email ? "Email is required" : ""}
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="form-group">
                      <input
                        value={loginInput?.password}
                        onChange={onLogchange}
                        type={showLoginPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="Password"
                      />
                      <Button
                        className="password-icon"
                        onClick={() => setShowLoginPassword(!showLoginPassword)}
                      >
                        {showLoginPassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </Button>
                      {inputLogin?.password ? (
                        <small
                          className="validation-error"
                          style={{ color: "red", marginBottom: "0px" }}
                        >
                          {inputLogin?.password ? "password is required" : ""}
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="forgot-pass">
                      <span
                        onClick={() =>
                          setLoginActiveTab(loginTabs.FORGOT_PASSWORD)
                        }
                      >
                        Forgot Password ?
                      </span>
                    </div>
                    <button className="login-btn" onClick={loginForm}>
                      Log in
                    </button>

                  </form>
                </div>

              </Col>
            ) : loginActiveTab === loginTabs.FORGOT_PASSWORD ? (
              <Col lg="6" sm="12">
                <div className="login_wrap">
                  <h3 className="mb-30">Forgot your password?</h3>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={loginInput?.email}
                        onChange={onLogchange}
                        className="form-control"
                        placeholder="Your Email"
                      />
                      {inputLogin?.email ? (
                        <small
                          className="validation-error"
                          style={{ color: "red", marginBottom: "0px" }}
                        >
                          {inputLogin?.email ? "Email is required" : ""}
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="forgot-pass">
                      <p
                        onClick={() => {
                          setLoginActiveTab(loginTabs.LOGIN);
                        }}
                      >
                        Sign in to your account
                      </p>
                    </div>
                    <button className="login-btn" onClick={sendOtpToMail}>
                      Send OTP
                    </button>
                  </form>
                </div>
              </Col>
            ) : loginActiveTab === loginTabs.SEND_OTP ? (
              <Col lg="6" sm="12">
                <div className="login_wrap">
                  <h3 className="mb-30">Verify Your Account</h3>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        type="number"
                        name="otp"
                        value={loginInput?.otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                        className="form-control"
                        placeholder="Enter OTP"
                      />
                      {otp ? (
                        <small
                          className="validation-error"
                          style={{ color: "red", marginBottom: "0px" }}
                        >
                          {otp === null ? "Please enter OTP" : ""}
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>

                    <span className="forgot-pass">
                      <p onClick={sendOtpToMail}>Resend OTP</p>
                    </span>

                    <button
                      className="login-btn"
                      onClick={verifyOtpToChangePassword}
                    >
                      Verify OTP
                    </button>
                  </form>
                </div>
              </Col>
            ) : loginActiveTab === loginTabs.SEND_PASSWORD ? (
              <Col lg="6" sm="12">
                <div className="login_wrap">
                  <h3 className="mb-30">Enter Credentials</h3>
                  <form className="login-form">
                    <div className="form-group">
                      <input
                        value={loginInput?.password}
                        onChange={onLogchange}
                        type={showLoginPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="Password"
                      />
                      <Button
                        className="password-icon"
                        onClick={() => setShowLoginPassword(!showLoginPassword)}
                      >
                        {showLoginPassword ? (
                          <AiFillEyeInvisible />
                        ) : (
                          <AiFillEye />
                        )}
                      </Button>
                      {inputLogin?.password ? (
                        <small
                          className="validation-error"
                          style={{ color: "red", marginBottom: "0px" }}
                        >
                          {inputLogin?.password ? "password is required" : ""}
                        </small>
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="forgot-pass">
                      <p
                        onClick={() => {
                          setLoginActiveTab(loginTabs.LOGIN);
                        }}
                      >
                        Sign in to your account
                      </p>
                    </div>
                    <button className="login-btn" onClick={forgotPasswordFun}>
                      Change password
                    </button>
                  </form>
                </div>
              </Col>
            ) : (
              <Col lg="6" sm="12">
                <div className="login_wrap">
                  <h3 className="mb-30">Verify Email</h3>

                  <form className="login-form">
                    <div className="form-group">
                      <input
                        value={otp}
                        onChange={(e) => {
                          setOtpError(false);
                          setOtp(e.target.value);
                        }}
                        type="number"
                        name="otp"
                        className="form-control"
                        placeholder="Enter 6 digit OTP"
                      />
                      {otpError ? (
                        <small
                          className="validation-error"
                          style={{ color: "red", marginBottom: "0px" }}
                        >
                          {otpError ? "Please enter OTP" : ""}
                        </small>
                      ) : (
                        <small
                          className="validation-error"
                          style={{ color: "red", marginBottom: "0px" }}
                        >
                          {otpError ? "Please enter OTP" : ""}
                        </small>
                      )}
                      <span className="forgot-pass">
                        <p onClick={resendLoginOtpFun}>Resend OTP</p>
                      </span>
                    </div>
                    <button className="login-btn" onClick={sendOtp}>
                      Verify Email
                    </button>
                  </form>
                </div>
              </Col>
            )}


          </Row>
        </div>
      </Modal> */}
    </section>
  );
}

export default RegistrationLanding;