/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Accordion, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import Rating from "@mui/material/Rating";
import { AiOutlineStar, AiFillStar, AiFillDelete } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import RelatedProducts from "./related-products";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { IMG_URL } from "../../config";
import { getProductById, postRating, getRating } from "../../service/api";
import { addTocart } from "../../service/api";
import SpinnerComponent from "../../components/Spinner";
import ReactHtmlParser from "react-html-parser";
import StarIcon from "@mui/icons-material/Star";
import { useSnackbar } from "notistack";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";

/* SWIPER STYLES */
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";

/* IMAGES IMPORT */
import Detail01 from "../../assets/product-detail/detail-02.webp";
import Detail02 from "../../assets/product-detail/detail-01.png";
import Detail03 from "../../assets/product-detail/detail-03.webp";
import Detail04 from "../../assets/product-detail/detail-04.webp";
import Detail05 from "../../assets/product-detail/detail-05.webp";
import { useUpdateCart } from "../../hooks/useUpdateCart";
import actions from "../../redux/products/actions";
import ProductDetailLoading from "../../components/detail-loading";

const options = [
  { value: "Date", label: "Date" },
  { value: "Rating", label: "Rating" },
  { value: "Helpfulness", label: "Helpfulness" },
];

const ProductDetail = () => {
  const [count, setCount] = useState(1);
  const [reviewModal, setReviewModal] = useState(false);
  const [images, setImages] = useState([]);
  const [tempImg, settempImg] = useState([]);
  const { selectedProductId, selectedProduct, selectedColorId, cart } =
    useSelector((state) => state.products);
  const [apiCall, setApiCall] = useState("");


  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  let previewUrl = null;
  if (file) {
    previewUrl = URL.createObjectURL(file);
  }

  /* QUANTITY INCREMENT FUNCTION */
  function increment() {
    setCount(function (prevCount) {
      return (prevCount += 1);
    });
  }

  /* QUANTITY DECREMENT FUNCTION */
  function decrement() {
    setCount(function (prevCount) {
      if (prevCount > wholeSaleMinQuantity) {
        return (prevCount -= 1);
      } else {
        return (prevCount = wholeSaleMinQuantity);
      }
    });
  }

  const [ratings, setRatings] = useState([]);
  const [rpage, setrPage] = useState(1);
  const [rPageSize, setrPageSize] = useState(5);
  const [totalRPage, setTotalrPage] = useState(0);
  const [sortBy, setSortBy] = useState("DATE");
  const [avgReview, setAvgReview] = useState(0)
  const [wholeSaleMinQuantity, setWholeSaleMinQuantity] = useState(0)

  /* USE EFFECT FUNCTION */
  useEffect(() => {
    const obj = {}
    obj.productId = selectedProductId
    obj.page = rpage
    obj.sizePerPage = rPageSize
    obj.sortBy = sortBy
    getRating(obj).then(res => {
      // console.log("rating re--->", res)
      // console.log("avgrating", res?.data?.productAverageReview[0]?.averageRating)
      setRatings(res?.data?.productData?.docs)
      setTotalrPage(res?.data?.productData?.totalDocs)
      setAvgReview(res?.data?.productAverageReview?.length ?
        res?.data?.productAverageReview[0]?.averageRating?.toFixed(1) : 0)

    })
      .catch(err => {
        // console.log("err", err)
      })
      .catch((err) => {
        // console.log("err", err);
      });
  }, [selectedProductId, rpage, rPageSize]);

  /* PAGE CHANGE FUNCTION */
  const handlePageClick = (data) => {
    // console.log("data", data)
    const obj = {}
    obj.productId = selectedProductId
    obj.page = data.selected + 1
    obj.sizePerPage = rPageSize
    obj.sortBy = sortBy
    getRating(obj).then(res => {
      // console.log("rating re--->", res)
      // console.log("avgrating", res?.data?.productAverageReview[0]?.averageRating)
      setRatings(res?.data?.productData?.docs)
      setTotalrPage(res?.data?.productData?.totalDocs)
      setAvgReview(res?.data?.productAverageReview?.length ?
        res?.data?.productAverageReview[0]?.averageRating?.toFixed(1) : 0)
    })
      .catch(err => {
        // console.log("err", err)
      })
  };

  /* USEEFFECT FUNCTION */
  useEffect(() => {
    // console.log("ratings", ratings);
  }, [ratings]);

  /* IMAGE ONCHANGE FUNCTION */
  const onChange = (imageList) => {
    setImages(imageList);
  };

  const [activeImage, setActiveImage] = useState(null);
  const [productDetail, setProductDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [colorIdIn, setcolorIdIn] = useState(null);
  const { loggedIn } = useSelector((state) => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const { setUpdateCart } = useUpdateCart();
  const dispatch = useDispatch();

  /* USE EFFECT FUNCTION */
  useEffect(() => {
    setLoading(true);
    setcolorIdIn(selectedColorId);
    getProductById(selectedProduct?._id)
      .then((res) => {
        // console.log("res", res);
        setProductDetail(res?.data);
        setWholeSaleMinQuantity(res?.data?.productData?.wholeSaleMinQuantity)
        setCount(wholeSaleMinQuantity)
      })
      .finally((res) => {
        setLoading(false);
      });
    window.scrollTo(0, 0);
  }, [selectedProduct, selectedProductId, selectedColorId]);

  const [showSelectedColor, setShowSelectedColor] = useState(null);
  const [selectedImg, setSelectedImg] = useState("");

  /* USE EFFECT FUNCTION */
  useEffect(() => {
    let data1 = productDetail?.productData?.quantity?.filter(
      (colorId) => colorId?.color?._id === colorIdIn
    );
    // console.log("data1--->", data1);
    if (data1) setShowSelectedColor(data1[0]);
    let data2 = productDetail?.productData?.productImage?.filter(
      (colorId) => colorId?.color?._id === colorIdIn
    );
    // console.log("data1--->", data2);

    if (data2) {
      setSelectedImg(data2[0]);
      setActiveImage(data2[0]?.front[0]);
    }
    return () => {
      settempImg([]);
    };
  }, [colorIdIn, productDetail]);

  const [rating, setRating] = useState(0);
  const [price, setPrice] = useState(0);
  const [qty, setQty] = useState(0);

  const [ratingInput, setRatingInput] = useState({
    nickName: "",
    summary: "",
    review: "",
    email: "",
  });

  /* RATINGS CHANGE FUNCTION */
  const onChangeRating = (e) => {
    setRatingInput({ ...ratingInput, [e.target.name]: e.target.value });
  };

  /* RATINGS FUNCTION */
  const saveRating = () => {
    if (!rating) {
      enqueueSnackbar(" Please rate the product", { variant: "error" });
      return;
    }
    if (!price) {
      enqueueSnackbar(" Please rate pricing", { variant: "error" });
      return;
    }
    if (!qty) {
      enqueueSnackbar(" Please rate quality", { variant: "error" });
      return;
    }

    if (!ratingInput?.nickName) {
      enqueueSnackbar(" Please enter name", { variant: "error" });
      return;
    }
    if (!ratingInput?.summary) {
      enqueueSnackbar(" Please enter summary", { variant: "error" });
      return;
    }
    if (!ratingInput?.email) {
      enqueueSnackbar(" Please enter email", { variant: "error" });
      return;
    }
    if (!ratingInput?.review) {
      enqueueSnackbar(" Please enter review", { variant: "error" });
      return;
    }

    setReviewModal(!reviewModal)
    // console.log("ratingInput", ratingInput)
    // console.log("rating", rating)
    // console.log("price", price)
    // console.log("qty", qty)
    const formData = new FormData()
    formData.append("nickName", ratingInput?.nickName)
    formData.append("summary", ratingInput?.summary)
    formData.append("review", ratingInput?.review)
    formData.append("email", ratingInput?.email)
    formData.append("quality", qty)
    formData.append("price", price)
    formData.append("rating", rating)
    formData.append("productId", selectedProductId)
    // images.map(file => { formData.append("image", file?.file) })
    formData.append('image', file)
    postRating(formData).then(res => {
      if (res?.status)
        enqueueSnackbar(" Rating posted successfully", { variant: 'success' });
      // const obj = {}
      // obj.productId = selectedProductId
      // obj.page = rpage
      // obj.sizePerPage = rPageSize
      // obj.sortBy = sortBy
      // getRating(obj).then(res => {
      //   console.log("rating re--->", res)
      //   setRatings(res?.data?.docs)
      //   setTotalrPage(res?.data?.totalDocs)
      // })
      // console.log(res)
      setReviewModal(false)
      setRatingInput({
        ...ratingInput,
        nickName: "",
        summary: "",
        review: "",
        email: ""
      })
      setImages([])
      setFile(null)
      setQty(wholeSaleMinQuantity)
      setPrice(0)
      setRating(0)
    })
      .catch(err => {
        // console.log(err)
        enqueueSnackbar(" Something went wrong", { variant: 'erro' });
        setReviewModal(true)
      })

  }

  /* ADD TO CART FUNCTION */

  const handleAddToCart = (tag) => {
    if (count < wholeSaleMinQuantity) {
      enqueueSnackbar(`Minimum ${wholeSaleMinQuantity} Quantity to buy. Add More`, {
        variant: "error",
      });
      return;
    } else if (count === 0) {
      enqueueSnackbar("Add the Quantity you want to buy.", {
        variant: "error",
      });
      return;
    }
    setApiCall(true);
    if (loggedIn) {
      let body = {
        product: selectedProduct?._id,
        color: colorIdIn,
        quantity: count,
      };
      addTocart(body).then((res) => {
        setApiCall(false);
        enqueueSnackbar(res?.message, { variant: "success" });
        if (res?.status) {
          setCount(1);
          setUpdateCart(true);
        }
      });
    } else {
      if (
        cart.filter(
          (obj) =>
            obj.product?._id === selectedProduct?._id &&
            obj.color?._id === colorIdIn
        )?.length > 0
      ) {
        let data = cart.filter(
          (obj) =>
            obj.product?._id !== selectedProduct?._id &&
            obj.color?._id !== colorIdIn
        );
        let product = cart.filter(
          (obj) =>
            obj.product?._id === selectedProduct?._id &&
            obj.color?._id === colorIdIn
        )[0];
        product.quantity = product.quantity + count;

        data.push(product);
        actions.setCartData(data);
        setApiCall(false);
        // console.log(data)
        enqueueSnackbar("Your product has been added to cart successfully.", {
          variant: "success",
        });
      } else {
        setApiCall(false);
        setCount(1);
        enqueueSnackbar("Your product has been added to cart successfully.", {
          variant: "success",
        });
        dispatch(
          actions.setCartData([
            ...cart,
            {
              product: selectedProduct,
              quantity: count,
              color: selectedProduct.color.filter(
                (obj) => obj?._id === colorIdIn
              )[0],
              amount: selectedProduct?.amount,
            },
          ])
        );
      }
    }
  };

  /* RETURN DATE FUNCTION */
  const returnDate = (date) => {
    let objectDate = new Date(date);
    let day = objectDate.getDate();
    let month = objectDate.getMonth();
    let year = objectDate.getFullYear();
    return day + "/" + month + 1 + "/" + year;
  };

  return (
    <section className="product-detail ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>Product | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        {/* PRODUCT IMAGES AND DESCRIPTION */}
        {loading ? (
          <ProductDetailLoading />
        ) : (
          <Row className="justify-content-between">
            {/* PRODUCT IMAGES */}
            <Col xl="6" sm="12">
              <Row className="images-list">
                <Col lg="12" sm="12">
                  <div className="primary-img">
                    <img
                      src={`${IMG_URL}${activeImage}`}
                      alt={"Buy " + productDetail?.productData?.name}
                    />
                  </div>
                </Col>
                <Col lg="12" sm="12">
                  <div className="product-small-images">
                    <Swiper
                      modules={[Scrollbar, Navigation, Pagination]}
                      navigation={true}
                      slidesPerView={5}
                      spaceBetween={10}
                    >
                      {tempImg?.length
                        ? tempImg?.map((imgLink, i) => {
                          return (
                            <SwiperSlide key={i}>
                              <div className="product-detail-img-slider">
                                <img
                                  src={`${IMG_URL}${imgLink}`}
                                  onClick={() => {
                                    setActiveImage(imgLink);
                                  }}
                                  alt={
                                    "Buy " + productDetail?.productData?.name
                                  }
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })
                        : null}
                      {selectedImg?.others?.map((imgLink, i) => {
                        return (
                          <SwiperSlide key={i}>
                            <div className="product-detail-img-slider">
                              <img
                                src={`${IMG_URL}${imgLink}`}
                                onClick={() => {
                                  if (tempImg?.length === 0) {
                                    let arr = [];
                                    arr.push(activeImage);
                                    settempImg(arr);
                                  }
                                  setActiveImage(imgLink);
                                }}
                                alt={"Buy " + productDetail?.productData?.name}
                              />
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </Col>
              </Row>
            </Col>

            {/* PRODUCT DESCRIPTION */}
            <Col xl="6" sm="12">
              <div className="product-description">
                <h3>{productDetail?.productData?.name}</h3>

                {/* REVIEWS AND STOCK UPDATE */}
                <div className="d-flex align-items-center">
                  {showSelectedColor?.quantity > 0 ? (
                    <div className="in-stock">In stock</div>
                  ) : (
                    <div className="in-stock">Not In stock</div>
                  )}

                  <Link className="review-link">{totalRPage} reviews</Link>
                </div>

                {/* COLORS LIST */}
                <div className="colors-list">
                  <p>
                    <b>Color: </b>
                    {showSelectedColor?.color?.name}
                  </p>
                  <div className="list">
                    {productDetail?.productData?.quantity?.map((detail) => {
                      return (
                        <div
                          className="color active"
                          style={{ backgroundColor: detail?.color?.code }}
                          onClick={() => {
                            setcolorIdIn(detail?.color?._id);
                          }}
                        />
                      );
                    })}
                  </div>
                </div>

                {/* GOOD TO KNOW */}
                <div className="good-to-know mt-3">
                  <h5>GOOD TO KNOW</h5>
                  <Row className="good-to-know-list">
                    <Col lg="4" sm="12">
                      <div className="list-detail">
                        <img
                          src={require("../../assets/product-detail/free-shipping-icon.webp")}
                          alt="Free Shipping"
                        />
                        <p>Free Shipping on $40+</p>
                      </div>
                    </Col>
                    <Col lg="4" sm="12">
                      <div className="list-detail">
                        <img
                          src={require("../../assets/product-detail/chat-icon.webp")}
                          alt="24 hr chat support"
                        />
                        <p>24 hr chat support</p>
                      </div>
                    </Col>
                    <Col lg="4" sm="12">
                      <div className="list-detail">
                        <img
                          src={require("../../assets/product-detail/satisfaction-icon.webp")}
                          alt="Product satisfaction guaranteed"
                        />
                        <p>Product satisfaction</p>
                      </div>
                    </Col>
                    <Col lg="4" sm="12">
                      <div className="list-detail mt-1">
                        <img
                          src={require("../../assets/product-detail/paraben-icon.webp")}
                          alt="Paraben and cruelty free"
                        />
                        <p>Paraben & cruelty free</p>
                      </div>
                    </Col>
                    <Col lg="4" sm="12">
                      <div className="list-detail mt-1">
                        <img
                          src={require("../../assets/product-detail/women-around-icon.webp")}
                          alt="Women owned and operated"
                        />
                        <p>Women owned and operated</p>
                      </div>
                    </Col>
                    <Col lg="4" sm="12">
                      <div className="list-detail mt-1">
                        <img
                          src={require("../../assets/product-detail/vegan-icon.png")}
                          alt="All products vegan"
                        />
                        <p>All product vegan</p>
                      </div>
                    </Col>
                  </Row>
                </div>

                {/* ADD TO CART */}
                <Row className="mt-3">
                  <Col xl="2" sm="12">
                    <div className="quantity-counter">
                      <Button className="minus-counter" onClick={decrement}>
                        -
                      </Button>
                      <div className="count">
                        <p>{count}</p>
                      </div>
                      <Button className="plus-counter" onClick={increment}>
                        +
                      </Button>
                    </div>
                  </Col>
                  <Col xl="10" sm="12">
                    <Button
                      className="add-to-bag"
                      onClick={handleAddToCart}
                      disabled={apiCall}
                    >
                      Add to Bag - ${productDetail?.productData?.amount}
                    </Button>
                  </Col>
                  {/* <Col xl="12" sm="12">
                    <div className="after-pay-box">
                      <p>
                        or 4 interest-free payments of $4.75 with{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          preserveAspectRatio="xMidYMid meet"
                          width="64"
                          height="26"
                          viewBox="14 12 76 12"
                        >
                          <path
                            class="afterpay-logo-badge-background"
                            fill="#F3F6F6"
                            d="m86.00173,35.9321l-68.00064,0c-9.90375,0 -17.93101,-8.02726 -17.93101,-17.93101l0,0c0,-9.90375 8.02726,-17.93101 17.93101,-17.93101l68.00064,0c9.90375,0 17.931,8.02726 17.931,17.93101l0,0c0.00652,9.89724 -8.02725,17.93101 -17.931,17.93101z"
                          ></path>
                          <g class="afterpay-logo-badge-lockup">
                            <path d="m88.23074,13.52071l-2.25928,-1.29288l-2.29193,-1.31247c-1.51489,-0.86845 -3.40851,0.22201 -3.40851,1.97197l0,0.29384c0,0.16324 0.08489,0.31342 0.22854,0.39178l1.06435,0.60726c0.29383,0.16978 0.6595,-0.0457 0.6595,-0.38525l0,-0.69868c0,-0.34607 0.37219,-0.56155 0.67256,-0.39178l2.0895,1.20147l2.08298,1.19493c0.30037,0.16977 0.30037,0.60727 0,0.77704l-2.08298,1.19494l-2.0895,1.20146c-0.30037,0.16978 -0.67256,-0.0457 -0.67256,-0.39178l0,-0.34607c0,-1.74997 -1.89362,-2.84696 -3.40851,-1.97198l-2.29193,1.31247l-2.25928,1.29289c-1.52142,0.87498 -1.52142,3.07549 0,3.95047l2.25928,1.29289l2.29193,1.31247c1.51489,0.86845 3.40851,-0.22201 3.40851,-1.97198l0,-0.29383c0,-0.16325 -0.08489,-0.31343 -0.22854,-0.39179l-1.06435,-0.60726c-0.29383,-0.16977 -0.6595,0.04571 -0.6595,0.38525l0,0.69868c0,0.34608 -0.37219,0.56156 -0.67256,0.39179l-2.0895,-1.20147l-2.08298,-1.19494c-0.30037,-0.16977 -0.30037,-0.60726 0,-0.77703l2.08298,-1.19494l2.0895,-1.20147c0.30037,-0.16977 0.67256,0.04571 0.67256,0.39179l0,0.34607c0,1.74996 1.89362,2.84695 3.40851,1.97197l2.29193,-1.31247l2.25928,-1.29288c1.52142,-0.88151 1.52142,-3.0755 0,-3.95048z"></path>
                            <path d="m73.4083,13.95167l-5.28907,10.92421l-2.19398,0l1.9785,-4.08107l-3.11467,-6.84314l2.25275,0l1.99809,4.58386l2.18092,-4.58386l2.18746,0z"></path>
                            <path d="m20.52416,17.83032c0,-1.30594 -0.9468,-2.2201 -2.10909,-2.2201s-2.1091,0.93375 -2.1091,2.2201c0,1.2733 0.94681,2.2201 2.1091,2.2201s2.10909,-0.91416 2.10909,-2.2201m0.01959,3.87865l0,-1.00558c-0.57461,0.69868 -1.43,1.12964 -2.44864,1.12964c-2.12869,0 -3.74152,-1.70425 -3.74152,-4.00271c0,-2.27887 1.67813,-4.01577 3.78723,-4.01577c0.99251,0 1.82832,0.43749 2.40293,1.11658l0,-0.97946l1.90668,0l0,7.7573l-1.90668,0z"></path>
                            <path d="m31.72262,19.98513c-0.66603,0 -0.85539,-0.24813 -0.85539,-0.9011l0,-3.44116l1.22758,0l0,-1.6912l-1.22758,0l0,-1.89361l-1.95239,0l0,1.89361l-2.52047,0l0,-0.7705c0,-0.65297 0.24813,-0.9011 0.93375,-0.9011l0.43096,0l0,-1.50184l-0.94027,0c-1.61284,0 -2.37682,0.52891 -2.37682,2.14175l0,1.03822l-1.08393,0l0,1.68467l1.08393,0l0,6.0661l1.95238,0l0,-6.0661l2.52047,0l0,3.80029c0,1.58019 0.60727,2.26581 2.18746,2.26581l1.00557,0l0,-1.72384l-0.38525,0z"></path>
                            <path d="m38.73553,17.13164c-0.13713,-1.00557 -0.95987,-1.61284 -1.92627,-1.61284c-0.95986,0 -1.75649,0.58768 -1.95238,1.61284l3.87865,0zm-3.89824,1.208c0.13712,1.14923 0.95987,1.8022 2.00462,1.8022c0.82275,0 1.45613,-0.38525 1.82832,-1.00558l2.00462,0c-0.46361,1.64549 -1.93932,2.69677 -3.87865,2.69677c-2.34416,0 -3.98965,-1.64548 -3.98965,-3.98965c0,-2.34417 1.7369,-4.03536 4.03536,-4.03536c2.31152,0 3.98965,1.70425 3.98965,4.03536c0,0.16977 -0.01305,0.33955 -0.0457,0.49626l-5.94857,0z"></path>
                            <path d="m53.26414,17.83032c0,-1.2537 -0.94681,-2.2201 -2.1091,-2.2201c-1.16229,0 -2.1091,0.93375 -2.1091,2.2201c0,1.2733 0.94681,2.2201 2.1091,2.2201c1.16229,0 2.1091,-0.95986 2.1091,-2.2201m-6.14446,7.04556l0,-10.92421l1.90667,0l0,1.00558c0.57462,-0.71174 1.43001,-1.14923 2.44865,-1.14923c2.09603,0 3.74152,1.72384 3.74152,4.00271s-1.67814,4.01578 -3.78723,4.01578c-0.97946,0 -1.78261,-0.38526 -2.34417,-1.03823l0,4.08107l-1.96544,0l0,0.00653z"></path>
                            <path d="m62.09231,17.83032c0,-1.30594 -0.94681,-2.2201 -2.1091,-2.2201c-1.16229,0 -2.1091,0.93375 -2.1091,2.2201c0,1.2733 0.94681,2.2201 2.1091,2.2201c1.16229,0 2.1091,-0.91416 2.1091,-2.2201m0.01959,3.87865l0,-1.00558c-0.57462,0.69868 -1.43001,1.12964 -2.44865,1.12964c-2.12868,0 -3.74152,-1.70425 -3.74152,-4.00271c0,-2.27887 1.67813,-4.01577 3.78723,-4.01577c0.99252,0 1.82832,0.43749 2.40294,1.11658l0,-0.97946l1.90667,0l0,7.7573l-1.90667,0z"></path>
                            <path d="m43.67852,14.70912s0.4832,-0.9011 1.67814,-0.9011c0.50931,0 0.8358,0.1763 0.8358,0.1763l0,1.97851s-0.71827,-0.44402 -1.37777,-0.35261c-0.6595,0.09142 -1.0774,0.69215 -1.0774,1.50184l0,4.59038l-1.97197,0l0,-7.75076l1.90667,0l0,0.75744l0.00653,0z"></path>
                          </g>
                        </svg>
                      </p>
                    </div>
                  </Col> */}
                </Row>

                {/* BENEFITS */}
                <div className="benefits-text mt-2">
                  <p>
                    For a limited time enjoy FREE gifts, including the{" "}
                    <Link to="" style={{ color: "#000" }}>
                      Bombshell Brow & Lash Serum
                    </Link>
                    , with orders $40 and up PLUS a FREE{" "}
                    <Link to="" style={{ color: "#000" }}>
                      Golden Gift Bag
                    </Link>{" "}
                    with orders $50 and up! No code needed, enjoy at checkout!
                  </p>
                </div>

                {/* DESCRIPTION */}
                <Accordion className="mt-2" defaultActiveKey="description">
                  <Accordion.Item
                    eventKey="description"
                    className="product-detail-description"
                  >
                    <Accordion.Header className="product-detail-description-header">
                      DESCRIPTION
                    </Accordion.Header>
                    <Accordion.Body className="product-detail-description-body">
                      {ReactHtmlParser(productDetail?.productData?.description)}
                    </Accordion.Body>
                  </Accordion.Item>
                  {productDetail?.productData?.ingredients ? (
                    <Accordion.Item
                      eventKey="ingredients"
                      className="product-detail-description"
                    >
                      <Accordion.Header className="product-detail-description-header">
                        INGREDIENTS
                      </Accordion.Header>
                      <Accordion.Body className="product-detail-description-body">
                        {ReactHtmlParser(
                          productDetail?.productData?.ingredients
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}
                  {productDetail?.productData?.howToUse ? (
                    <Accordion.Item
                      eventKey="ingredients"
                      className="product-detail-description"
                    >
                      <Accordion.Header className="product-detail-description-header">
                        HOW TO USE
                      </Accordion.Header>
                      <Accordion.Body className="product-detail-description-body">
                        {ReactHtmlParser(productDetail?.productData?.howToUse)}
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}
                </Accordion>
              </div>
            </Col>
          </Row>
        )}

        <Col size="12">
          {/* YOUTUBE TUTORIAL */}
          <div className="youtube-tutorial mt-5">
            <h2>BBR TUTORIAL</h2>
            <Row className="mt-2">
              {productDetail?.productData?.youtubeLinks?.map((srcLink) => {
                return (
                  <Col size="4">
                    <iframe
                      className="video-frame"
                      height="300"
                      src={`https://www.youtube.com/embed/${srcLink}`}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Col>

        {/* SHARE YOUR LOOK */}
        <div className="mt-3 instagram-look border-top-section ptb-20">
          <h2>SHARE YOUR LOOK @BROWBARBYREEMA</h2>
          <Row className="justify-content-center">
            <Col xl="6" sm="12">
              <p>
                Share a photo or video of your favorite Brow Bar moment and
                appear in our showcase! Make sure to tag @browbarbyreema!
              </p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xl="3" sm="12">
              <img
                src={require("../../assets/product-detail/instagram-01.jpg")}
                className="instagram-img"
                alt="Instagram Wall"
              />
            </Col>
            <Col xl="3" sm="12">
              <img
                src={require("../../assets/product-detail/instagram-02.jpg")}
                className="instagram-img"
                alt="Instagram Wall"
              />
            </Col>
            <Col xl="3" sm="12">
              <img
                src={require("../../assets/product-detail/instagram-03.jpg")}
                className="instagram-img"
                alt="Instagram Wall"
              />
            </Col>
            <Col xl="3" sm="12">
              <img
                src={require("../../assets/product-detail/instagram-04.jpg")}
                className="instagram-img"
                alt="Instagram Wall"
              />
            </Col>
          </Row>
        </div>

        {/* PRODUCT REVIEWS */}
        <Row className=" mt-3 border-top-section ptb-20">
          <Col xl="12" sm="12">
            <div className="total-reviews">
              <h2>WHAT PEOPLE ARE SAYING</h2>
              <div className="reviews-actions mt-2">
                <Select
                  options={options}
                  classNamePrefix="select-control"
                  placeholder="Sort by"
                  name="sort-by"
                  id="sort-by"
                />
                <div className="review-count">
                  <h3>{avgReview}</h3>
                  <Rating
                    value={avgReview}
                    icon={
                      <StarIcon style={{ color: "black" }} />
                    }
                  />
                  <p>{totalRPage} reviews</p>
                </div>
                <Button
                  className="write-review-btn"
                  onClick={() => setReviewModal(!reviewModal)}
                >
                  Write a review
                </Button>
              </div>

              {/* REVIEWS LIST */}
              <div className="reviews-list">
                {totalRPage ? (
                  <div className="user-review mt-2">
                    {ratings?.map((ratingData) => {
                      return (
                        <Row className="mt-2">
                          <Col xl="3" sm="12">
                            <div className="user-details">
                              <div>
                                <h5>{ratingData?.nickName}</h5>
                                <p>{returnDate(ratingData?.createdAt)}</p>
                              </div>
                              {ratingData?.verifiedByre ? (
                                <div className="verified-buyer">
                                  <img
                                    src={require("../../assets/check.png")}
                                    width="25"
                                    alt="Address Selected"
                                  />
                                  <p>Verified Buyer</p>
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col xl="9" sm="12">
                            <div className="user-review-desc">
                              <div className="ratings-given">
                                <div className="rating-name">
                                  <h5>Rating</h5>
                                  <Rating
                                    value={ratingData?.rating}
                                    icon={
                                      <StarIcon style={{ color: "black" }} />
                                    }
                                  />
                                </div>
                                <div className="rating-name">
                                  <h5>Price</h5>
                                  <Rating
                                    value={ratingData?.price}
                                    icon={
                                      <StarIcon style={{ color: "black" }} />
                                    }
                                  />
                                </div>
                                <div className="rating-name">
                                  <h5>Quality</h5>
                                  <Rating
                                    value={ratingData?.quality}
                                    icon={
                                      <StarIcon style={{ color: "black" }} />
                                    }
                                  />
                                </div>
                              </div>
                              <h5 className="heading">{ratingData?.review}</h5>
                              <p>{ratingData?.summary}</p>
                              {ratingData?.image ? (
                                <div className="client-images-list">
                                  <img
                                    src={`${IMG_URL}${ratingData?.image}`}
                                    className="client-photo"
                                    alt="Review"
                                  />
                                </div>
                              ) : null}
                            </div>
                          </Col>

                          <hr className="mt-2" style={{ width: '95vw', margin: 'auto', borderColor: '#696969' }} />
                        </Row>
                      );
                    })}
                  </div>
                ) : null}

                {/* PAGINATION */}
                <div className="pagination mt-2">
                  {totalRPage ? (
                    <ReactPaginate
                      nextLabel=">"
                      previousLabel="<"
                      pageCount={Math.ceil(totalRPage / rPageSize)}
                      pageRangeDisplayed={10}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      onPageChange={handlePageClick}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {/* YOU MIGHT ALSO LOVE */}
        <RelatedProducts />

        {/* REVIEW MODAL */}
        <Modal
          size="lg"
          show={reviewModal}
          onHide={() => setReviewModal(!reviewModal)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="review-modal-title">
              Write your review
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="review-modal-body">
            <form>
              <Row>
                <Col xl="3" sm="12">
                  <div className="form-group">
                    <h5>Rating</h5>
                    <Rating
                      key={1}
                      icon={<StarIcon style={{ color: "black" }} />}
                      onChange={(e, value) => {
                        setRating(value);
                      }}
                      rating={rating}
                    />
                  </div>
                </Col>
                <Col xl="3" sm="12">
                  <div className="form-group">
                    <h5>Price</h5>
                    <Rating
                      key={2}
                      icon={<StarIcon style={{ color: "black" }} />}
                      onChange={(e, value) => {
                        setPrice(value);
                      }}
                      rating={price}
                    />
                  </div>
                </Col>
                <Col xl="3" sm="12">
                  <div className="form-group">
                    <h5>Quality</h5>
                    <Rating
                      key={3}
                      icon={<StarIcon style={{ color: "black" }} />}
                      rating={qty}
                      onChange={(e, value) => {
                        setQty(value);
                      }}
                    />
                  </div>
                </Col>
                <Col xl="6" sm="12">
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Nickname"
                    name="nickName"
                    id="nickname"
                    value={ratingInput?.nickName}
                    onChange={onChangeRating}
                  />
                </Col>
                <Col xl="6" sm="12">
                  <input
                    type="email"
                    className="form-control mt-2"
                    placeholder="Email Address"
                    name="email"
                    id="email"
                    value={ratingInput?.email}
                    onChange={onChangeRating}
                  />
                </Col>
                <Col xl="12" sm="12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Summary"
                    name="summary"
                    id="summary"
                    value={ratingInput?.summary}
                    onChange={onChangeRating}
                  />
                </Col>
                <Col xl="12" sm="12">
                  <textarea
                    rows="4"
                    className="textarea-control"
                    name="review"
                    id="review"
                    placeholder="Review"
                    value={ratingInput?.review}
                    onChange={onChangeRating}
                  />
                </Col>
                <Col xl="12" sm="12">
                  {/* <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    dataURLKey="data_url"
                    maxNumber={1}
                  >
                    {({ imageList, onImageUpload, onImageRemove }) => (
                      <div className="list-of-images">
                        <div
                          className="uploaded-images-list"
                          onClick={onImageUpload}
                        >
                          <div className="add-image">
                            <p>Add File</p>
                          </div>
                        </div>
                        {imageList.map((image, index) => (
                          <div key={index} className="image-item">
                            <img src={image["data_url"]} alt="Client Image" />
                            <div className="delete" onClick={onImageRemove}>
                              <AiFillDelete className="delete-icon" />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading> */}

                  <div className="list-of-images">
                    <>
                      <div className="add-file">
                        <input
                          type="file"
                          id="file-upload"
                          onChange={handleFileChange}
                          accept="image/*,video/*"
                          multiple={false}
                          style={{ display: "none" }}
                        />
                        <label htmlFor="file-upload">
                          <p style={{
                            padding: '40px 20px',
                            border: '1px solid #E5E5E5',
                            borderRadius: '7px',
                            marginBottom: '0px',
                            fontSize: '13px',
                            color: '#000'
                          }}>Add File</p>
                        </label>
                      </div>
                    </>

                    <div className="image-item">
                      {file && (
                        <div className="image-item">
                          {file.type.startsWith("image/") ? (
                            <div>
                              <img src={previewUrl} alt={file.name} />
                              <div className="delete" >
                                <AiFillDelete className="delete-icon" onClick={() => {
                                  setFile(null);
                                  URL.revokeObjectURL(previewUrl);
                                }} />
                              </div>
                            </div>
                          ) : (
                            <div className="image-item">
                              <video src={previewUrl} controls style={{
                                width: '150px',
                                marginLeft: '10px'
                              }} />
                              <div className="delete" >
                                <AiFillDelete className="delete-icon" onClick={() => {
                                  setFile(null);
                                  URL.revokeObjectURL(previewUrl);
                                }} />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                  </div>


                </Col>
              </Row>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="add-address-btn"
              style={{ backgroundColor: "#000", borderColor: "#000" }}
              onClick={saveRating}
            >
              Submit Review
            </Button>{" "}
            {/* IF USED FOR CREATING NEW ADDRESS, BUTTON TEXT WILL BE ADD ADDRESS */}
          </Modal.Footer>
        </Modal>
      </div>
    </section >
  );
};

export default ProductDetail;
