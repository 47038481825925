import React from "react";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ProductBox from "../../components/product-box";
import eye from "../../data/eye";
import Select from "react-select";

const options = [
  { value: "Position", label: "Position" },
  { value: "Product Name", label: "Product Name" },
  { value: "Price", label: "Price" },
];

const ShopEyes = () => {
  return (
    <section className="eyes ptb-30">
      <Helmet>
        <title>Eye Collection | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <h2>EYE COLLECTION</h2>
        <Row className="justify-content-center">
          <Col lg="12" sm="12">
            <div className="banner">
              <img
                src={require("../../assets/banners/eye-banner.jpg")}
                alt="Brow Collection | Brow Bar by Reema"
              />
            </div>
          </Col>
          <Col lg="6" sm="12">
            <div className="heading">
              <p>
                Draw out your beautiful eyes with Reema's eye makeup collection.
                Easy and flawless eyes have never been so accessible with our
                fluid and precise eye makeup. Enhance your natural beauty today.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="products-list border-top-section ptb-30">
          <div className="container">
            <div className="product-stats">
              <p>We have total 18 products</p>
              <Select
                options={options}
                classNamePrefix="select-control"
                placeholder="Sort by"
                name="sort-by"
                id="sort-by"
              />
            </div>
            <Row>
              {eye.map((product) => {
                return (
                  <Col lg="3" sm="12" key={product.id}>
                    <ProductBox
                      isOutOfStock={product.isOutOfStock}
                      productFrontImg={product.productFrontImg}
                      productBackImg={product.productBackImg}
                      productName={product.productName}
                      productPrice={product.productPrice}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default ShopEyes;
