import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Row, Col, Button, Accordion } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";

/* SWIPER STYLES */
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "react-loading-skeleton/dist/skeleton.css";

const ProductDetailLoading = () => {
    return (
      <div className="product-detail ptb-30">
        <Row className="justify-content-between">
          {/* PRODUCT IMAGES */}
          <Col xl="6" sm="12">
            <Row className="images-list">
              <Col lg="12" sm="12">
                <div className="primary-img">
                  <Skeleton className="main-img-loading" />
                </div>
              </Col>
              <Col lg="12" sm="12">
                <div className="product-small-images">
                  <Swiper
                    modules={[Scrollbar, Navigation, Pagination]}
                    navigation={true}
                    slidesPerView={5}
                    spaceBetween={10}
                  >
                    <SwiperSlide>
                      <div className="product-detail-img-slider">
                        <Skeleton className="small-img-loading" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="product-detail-img-slider">
                        <Skeleton className="small-img-loading" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="product-detail-img-slider">
                        <Skeleton className="small-img-loading" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="product-detail-img-slider">
                        <Skeleton className="small-img-loading" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="product-detail-img-slider">
                        <Skeleton className="small-img-loading" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </Col>
            </Row>
          </Col>

          {/* PRODUCT DESCRIPTION */}
          <Col xl="6" sm="12">
            <div className="product-description">
              <Skeleton className="name-loading" />

              {/* REVIEWS AND STOCK UPDATE */}
              <div className="d-flex align-items-center">
                <Skeleton className="stock-loading mt-1" />
              </div>

              {/* COLORS LIST */}
              <div className="colors-list">
                <p>
                  <b>Color: </b>
                  <Skeleton className="color-name-loading" />
                </p>
                <div className="list">
                  <Skeleton className="color-loading" />
                  <Skeleton className="color-loading" />
                  <Skeleton className="color-loading" />
                </div>
              </div>

              {/* GOOD TO KNOW */}
              <div className="good-to-know mt-3">
                <h5>GOOD TO KNOW</h5>
                <Row className="good-to-know-list">
                  <Col lg="4" sm="12">
                    <div className="list-detail">
                      <img
                        src={require("../assets/product-detail/free-shipping-icon.webp")}
                        alt="Free Shipping"
                      />
                      <p>Free Shipping on $40+</p>
                    </div>
                  </Col>
                  <Col lg="4" sm="12">
                    <div className="list-detail">
                      <img
                        src={require("../assets/product-detail/chat-icon.webp")}
                        alt="24 hr chat support"
                      />
                      <p>24 hr chat support</p>
                    </div>
                  </Col>
                  <Col lg="4" sm="12">
                    <div className="list-detail">
                      <img
                        src={require("../assets/product-detail/satisfaction-icon.webp")}
                        alt="Product satisfaction guaranteed"
                      />
                      <p>Product satisfaction</p>
                    </div>
                  </Col>
                  <Col lg="4" sm="12">
                    <div className="list-detail mt-1">
                      <img
                        src={require("../assets/product-detail/paraben-icon.webp")}
                        alt="Paraben and cruelty free"
                      />
                      <p>Paraben & cruelty free</p>
                    </div>
                  </Col>
                  <Col lg="4" sm="12">
                    <div className="list-detail mt-1">
                      <img
                        src={require("../assets/product-detail/women-around-icon.webp")}
                        alt="Women owned and operated"
                      />
                      <p>Women owned and operated</p>
                    </div>
                  </Col>
                  <Col lg="4" sm="12">
                    <div className="list-detail mt-1">
                      <img
                        src={require("../assets/product-detail/vegan-icon.png")}
                        alt="All products vegan"
                      />
                      <p>All product vegan</p>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* ADD TO CART */}
              <Row className="mt-3">
                <Col xl="2" sm="12">
                  <div className="quantity-counter">
                    <Button className="minus-counter">-</Button>
                    <div className="count">
                      <p>1</p>
                    </div>
                    <Button className="plus-counter">+</Button>
                  </div>
                </Col>
                <Col xl="10" sm="12">
                  <Button className="add-to-bag">Add to Bag</Button>
                </Col>
                {/* <Col xl="12" sm="12">
                  <div className="after-pay-box">
                    <p>
                      or 4 interest-free payments of $4.75 with{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid meet"
                        width="64"
                        height="26"
                        viewBox="14 12 76 12"
                      >
                        <path
                          class="afterpay-logo-badge-background"
                          fill="#F3F6F6"
                          d="m86.00173,35.9321l-68.00064,0c-9.90375,0 -17.93101,-8.02726 -17.93101,-17.93101l0,0c0,-9.90375 8.02726,-17.93101 17.93101,-17.93101l68.00064,0c9.90375,0 17.931,8.02726 17.931,17.93101l0,0c0.00652,9.89724 -8.02725,17.93101 -17.931,17.93101z"
                        ></path>
                        <g class="afterpay-logo-badge-lockup">
                          <path d="m88.23074,13.52071l-2.25928,-1.29288l-2.29193,-1.31247c-1.51489,-0.86845 -3.40851,0.22201 -3.40851,1.97197l0,0.29384c0,0.16324 0.08489,0.31342 0.22854,0.39178l1.06435,0.60726c0.29383,0.16978 0.6595,-0.0457 0.6595,-0.38525l0,-0.69868c0,-0.34607 0.37219,-0.56155 0.67256,-0.39178l2.0895,1.20147l2.08298,1.19493c0.30037,0.16977 0.30037,0.60727 0,0.77704l-2.08298,1.19494l-2.0895,1.20146c-0.30037,0.16978 -0.67256,-0.0457 -0.67256,-0.39178l0,-0.34607c0,-1.74997 -1.89362,-2.84696 -3.40851,-1.97198l-2.29193,1.31247l-2.25928,1.29289c-1.52142,0.87498 -1.52142,3.07549 0,3.95047l2.25928,1.29289l2.29193,1.31247c1.51489,0.86845 3.40851,-0.22201 3.40851,-1.97198l0,-0.29383c0,-0.16325 -0.08489,-0.31343 -0.22854,-0.39179l-1.06435,-0.60726c-0.29383,-0.16977 -0.6595,0.04571 -0.6595,0.38525l0,0.69868c0,0.34608 -0.37219,0.56156 -0.67256,0.39179l-2.0895,-1.20147l-2.08298,-1.19494c-0.30037,-0.16977 -0.30037,-0.60726 0,-0.77703l2.08298,-1.19494l2.0895,-1.20147c0.30037,-0.16977 0.67256,0.04571 0.67256,0.39179l0,0.34607c0,1.74996 1.89362,2.84695 3.40851,1.97197l2.29193,-1.31247l2.25928,-1.29288c1.52142,-0.88151 1.52142,-3.0755 0,-3.95048z"></path>
                          <path d="m73.4083,13.95167l-5.28907,10.92421l-2.19398,0l1.9785,-4.08107l-3.11467,-6.84314l2.25275,0l1.99809,4.58386l2.18092,-4.58386l2.18746,0z"></path>
                          <path d="m20.52416,17.83032c0,-1.30594 -0.9468,-2.2201 -2.10909,-2.2201s-2.1091,0.93375 -2.1091,2.2201c0,1.2733 0.94681,2.2201 2.1091,2.2201s2.10909,-0.91416 2.10909,-2.2201m0.01959,3.87865l0,-1.00558c-0.57461,0.69868 -1.43,1.12964 -2.44864,1.12964c-2.12869,0 -3.74152,-1.70425 -3.74152,-4.00271c0,-2.27887 1.67813,-4.01577 3.78723,-4.01577c0.99251,0 1.82832,0.43749 2.40293,1.11658l0,-0.97946l1.90668,0l0,7.7573l-1.90668,0z"></path>
                          <path d="m31.72262,19.98513c-0.66603,0 -0.85539,-0.24813 -0.85539,-0.9011l0,-3.44116l1.22758,0l0,-1.6912l-1.22758,0l0,-1.89361l-1.95239,0l0,1.89361l-2.52047,0l0,-0.7705c0,-0.65297 0.24813,-0.9011 0.93375,-0.9011l0.43096,0l0,-1.50184l-0.94027,0c-1.61284,0 -2.37682,0.52891 -2.37682,2.14175l0,1.03822l-1.08393,0l0,1.68467l1.08393,0l0,6.0661l1.95238,0l0,-6.0661l2.52047,0l0,3.80029c0,1.58019 0.60727,2.26581 2.18746,2.26581l1.00557,0l0,-1.72384l-0.38525,0z"></path>
                          <path d="m38.73553,17.13164c-0.13713,-1.00557 -0.95987,-1.61284 -1.92627,-1.61284c-0.95986,0 -1.75649,0.58768 -1.95238,1.61284l3.87865,0zm-3.89824,1.208c0.13712,1.14923 0.95987,1.8022 2.00462,1.8022c0.82275,0 1.45613,-0.38525 1.82832,-1.00558l2.00462,0c-0.46361,1.64549 -1.93932,2.69677 -3.87865,2.69677c-2.34416,0 -3.98965,-1.64548 -3.98965,-3.98965c0,-2.34417 1.7369,-4.03536 4.03536,-4.03536c2.31152,0 3.98965,1.70425 3.98965,4.03536c0,0.16977 -0.01305,0.33955 -0.0457,0.49626l-5.94857,0z"></path>
                          <path d="m53.26414,17.83032c0,-1.2537 -0.94681,-2.2201 -2.1091,-2.2201c-1.16229,0 -2.1091,0.93375 -2.1091,2.2201c0,1.2733 0.94681,2.2201 2.1091,2.2201c1.16229,0 2.1091,-0.95986 2.1091,-2.2201m-6.14446,7.04556l0,-10.92421l1.90667,0l0,1.00558c0.57462,-0.71174 1.43001,-1.14923 2.44865,-1.14923c2.09603,0 3.74152,1.72384 3.74152,4.00271s-1.67814,4.01578 -3.78723,4.01578c-0.97946,0 -1.78261,-0.38526 -2.34417,-1.03823l0,4.08107l-1.96544,0l0,0.00653z"></path>
                          <path d="m62.09231,17.83032c0,-1.30594 -0.94681,-2.2201 -2.1091,-2.2201c-1.16229,0 -2.1091,0.93375 -2.1091,2.2201c0,1.2733 0.94681,2.2201 2.1091,2.2201c1.16229,0 2.1091,-0.91416 2.1091,-2.2201m0.01959,3.87865l0,-1.00558c-0.57462,0.69868 -1.43001,1.12964 -2.44865,1.12964c-2.12868,0 -3.74152,-1.70425 -3.74152,-4.00271c0,-2.27887 1.67813,-4.01577 3.78723,-4.01577c0.99252,0 1.82832,0.43749 2.40294,1.11658l0,-0.97946l1.90667,0l0,7.7573l-1.90667,0z"></path>
                          <path d="m43.67852,14.70912s0.4832,-0.9011 1.67814,-0.9011c0.50931,0 0.8358,0.1763 0.8358,0.1763l0,1.97851s-0.71827,-0.44402 -1.37777,-0.35261c-0.6595,0.09142 -1.0774,0.69215 -1.0774,1.50184l0,4.59038l-1.97197,0l0,-7.75076l1.90667,0l0,0.75744l0.00653,0z"></path>
                        </g>
                      </svg>
                    </p>
                  </div>
                </Col> */}
              </Row>

              <div className="benefits-text mt-2">
                <p>
                  For a limited time enjoy FREE gifts, including the{" "}
                  <Link to="" style={{ color: "#000" }}>
                    Bombshell Brow & Lash Serum
                  </Link>
                  , with orders $40 and up PLUS a FREE{" "}
                  <Link to="" style={{ color: "#000" }}>
                    Golden Gift Bag
                  </Link>{" "}
                  with orders $50 and up! No code needed, enjoy at checkout!
                </p>
              </div>

              <Accordion className="mt-2" defaultActiveKey="description">
                <Accordion.Item
                  eventKey="description"
                  className="product-detail-description"
                >
                  <Accordion.Header className="product-detail-description-header">
                    DESCRIPTION
                  </Accordion.Header>
                  <Accordion.Body className="product-detail-description-body">
                    <Skeleton className="description-loading" />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
        </Row>
      </div>
    );
};

export default ProductDetailLoading;