/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { addTocart, postWishList } from "../service/api";
import { useSelector, useDispatch } from "react-redux";
import actions from "../redux/products/actions";
import { IMG_URL } from "../config";
import { useSnackbar } from "notistack";
import { useUpdateCart } from "../hooks/useUpdateCart";

const ProductBox = (props) => {
  // console.log(props);
  const [wishlistActive, setWishlistActive] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [flip, setFlip] = useState(false);
  const products = useSelector(state => state.products)
  const user = useSelector(state => state.user)
  const [apiCall, setApiCall] = useState(false);
  const { setUpdateCart } = useUpdateCart();

  /* WISHLIST FUNCTION */
  const wishListFun = (e) => {
    e.stopPropagation();
    if (!user?.token) {
      // console.log("open modal")
      dispatch(actions.setModal(true))
      return
    }
    dispatch(actions.setWishlistTrigger("start"))
    // console.log(props)
    if (wishlistActive) {
      setWishlistActive(false)
      const formData = new FormData()
      formData.append("product", props?.productId)
      formData.append("color", props?.colorId)
      postWishList(formData).then(res => {
        // console.log("res", res)
        dispatch(actions.setWishlistTrigger("stop"))
        if (props?.refresh) {
          props?.reload()
        }
      })

      dispatch(actions.setWishList(products.wishList - 1))
    }
    else {
      const formData = new FormData()
      formData.append("product", props?.productId)
      formData.append("color", props?.colorId)
      postWishList(formData).then(res => {
        // console.log("res", res)
        dispatch(actions.setWishlistTrigger("stop"))
        if (props?.refresh) {
          props?.reload()
        }
      })

      dispatch(actions.setWishList(products.wishList + 1))
      setWishlistActive(true)
    }
  }

  useEffect(() => {
    if (props?.wishlistObj)
      setWishlistActive(true)
  }, [])

  /* ADD TO CART FUNTION */
  const handleAddtoCart = () => {
    if (props.isOutOfStock !== true) {
      if (user.loggedIn) {
        let body = {
          product: props?.productId,
          color: props.colorId,
          quantity: props?.product?.wholeSaleMinQuantity,
        };
        setApiCall(true);

        addTocart(body).then((res) => {
          setApiCall(false);
          enqueueSnackbar(res?.message, { variant: "success" });
          if (res?.status) {
            //  if (tag === "buyNow") {
            //    navigate("/cart");
            //  }
            setUpdateCart(true);
          }
        });
      } else {
        // console.log(products)
        // console.log(
        //   "products.cart.filter",
        //   props?.productId,
        //   props?.colorId,
        //   products.cart, products.cart.filter(
        //     (obj) =>
        //       obj.product?._id === props?.productId &&
        //       (obj.color?._id ? obj.color?._id : obj?.color) === props?.colorId
        //   )
        // );
        if (
          products.cart.filter(
            (obj) =>
              obj.product?._id === props?.productId &&
              (obj.color?._id ? obj.color?._id : obj.color) === props?.colorId
          )?.length > 0
        ) {
          // console.log("colorID " + props?.colorId)
          // console.log("product --, " + props?.product)
          let data = products.cart.filter(
            (obj) =>
              obj.product?._id === props?.productId &&
              (obj.color?._id ? obj.color?._id : obj.color) === props?.colorId
          );
          let product = products.cart.filter(
            (obj) =>
              obj.product?._id === props?.productId &&
              (obj.color?._id ? obj.color?._id : obj.color) === props?.colorId
          )[0];
          product.quantity = product.quantity + 1;
          data.push(product);
          actions.setCartData(data);
        } else {
          let findColorID = props?.colorId;

          for (let i = 0; i < props?.product?.color.length; i++) {
            if (props?.product.color[i]._id === props?.colorId) {
              findColorID = props?.product.color[i]
            }
          }
          // console.log("colorID " + props?.colorId)
          // console.log("findColorID " + findColorID._id)
          // console.log("product --, " + props?.product.color[0]._id)
          setApiCall(false);
          enqueueSnackbar("Your product has been added to cart successfully.", {
            variant: "success",
          });

          dispatch(
            actions.setCartData([
              ...products.cart,
              {
                product: props?.product,
                quantity: props?.product?.wholeSaleMinQuantity,
                color: findColorID,
                amount: props?.product?.wholeSaleAmount,
              },
            ])
          );
        }
      }
    }
  };

  return (
    <div className="product-card">
      <div className="product-img">
        <span
          onClick={() => {
            dispatch(actions.setWholesaleSelectedProduct(props?.product))
            dispatch(actions.shopAll(false))
            dispatch(actions.setMenu(false))
            dispatch(actions.setSinglePdt(true))
            navigate('/wholesale-shop')
          }}
          style={{ cursor: 'pointer' }}
        >
          <img
            src={IMG_URL + props.productFrontImg}
            className="front-img"
            alt={"Buy " + props.productName}
          />
          <img
            src={IMG_URL + props.productBackImg}
            className="back-img"
            alt={"Buy " + props.productName}
          />
        </span>
      </div>
      <div className="product-desc">
        <span
          onClick={() => {
            dispatch(actions.setSelectedProductId(props?.productId));
            dispatch(actions.setSelectedColorId(props?.colorId));
            dispatch(actions.setSelectedProduct(props?.product));
            navigate("/product-detail");
          }}
          style={{ textDecoration: "none", color: "#000", cursor: 'pointer' }}
        >
          <h5>{props.productName}</h5>
        </span>
        <div className="ratings">
          <img
            src={require("../assets/testimonials/star-icon.png")}
            width="15"
            alt="5 Star Rating"
          />
          <img
            src={require("../assets/testimonials/star-icon.png")}
            width="15"
            alt="5 Star Rating"
          />
          <img
            src={require("../assets/testimonials/star-icon.png")}
            width="15"
            alt="5 Star Rating"
          />
          <img
            src={require("../assets/testimonials/star-icon.png")}
            width="15"
            alt="5 Star Rating"
          />
          <img
            src={require("../assets/testimonials/star-icon.png")}
            width="15"
            alt="5 Star Rating"
          />
          <p>{props?.ratings}</p>
        </div>
        <Button
          className="add-to-bag"
          disabled={props.isOutOfStock === true || apiCall ? true : false}
          onClick={() => handleAddtoCart()}
        >
          <h5>{props.isOutOfStock === true ? "Out of Stock" : "Add To Bag"}</h5>
          <h5>${props.productPrice}</h5>
        </Button>
      </div>
      <div
        className="wishlist-option"
        onClick={(e) =>
          wishListFun(e, props?.id, props?.colorId, props?.wishList)
        }
      >
        <AiOutlineHeart
          className={
            wishlistActive ? "wishlist-icon d-none" : "wishlist-icon d-block"
          }
        />
        <AiFillHeart
          className={
            wishlistActive ? "wishlist-icon d-block" : "wishlist-icon d-none"
          }
        />
      </div>
    </div>
  );
};

export default ProductBox;