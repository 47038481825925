import React from "react";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ProductBox from "../../components/product-box";
import face from "../../data/face";
import Select from "react-select";

const options = [
  { value: "Position", label: "Position" },
  { value: "Product Name", label: "Product Name" },
  { value: "Price", label: "Price" },
];

const ShopFace = () => {
  return (
    <section className="eyes ptb-30">
      <Helmet>
        <title>Face Collection | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <h2>FACE COLLECTION</h2>
        <Row className="justify-content-center">
          <Col lg="12" sm="12">
            <div className="banner">
              <img
                src={require("../../assets/banners/face-banner.jpg")}
                alt="Face Collection | Brow Bar by Reema"
              />
            </div>
          </Col>
          <Col lg="6" sm="12">
            <div className="heading">
              <p>
                Dazzle with Reema's glimmering palettes - for eyes, cheeks, and
                contour. Create the perfect look with our curated shimmers and
                colors, whether you're going out, out and about, or killing it
                at work.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="products-list border-top-section ptb-30">
          <div className="container">
            <div className="product-stats">
              <p>We have total 17 products</p>
              <Select
                options={options}
                classNamePrefix="select-control"
                placeholder="Sort by"
                name="sort-by"
                id="sort-by"
              />
            </div>
            <Row>
              {face.map((product) => {
                return (
                  <Col lg="3" sm="12" key={product.id}>
                    <ProductBox
                      isOutOfStock={product.isOutOfStock}
                      productFrontImg={product.productFrontImg}
                      productBackImg={product.productBackImg}
                      productName={product.productName}
                      productPrice={product.productPrice}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </Row>
      </div>
    </section>
  );
};

export default ShopFace;
