/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import RangeSlider from 'react-range-slider-input';
import DataTable from 'react-data-table-component';
import sampleData from "./sampleData";
import { Select as MUISelect, MenuItem, FormControl, OutlinedInput } from '@mui/material';
import Select from 'react-select';

/* ICON IMPORTS */
import UilStar from "@iconscout/react-unicons/icons/uil-star";
import UilCart from "@iconscout/react-unicons/icons/uil-shopping-cart";

const options = [
  { value: 'Price', label: 'Price' },
  { value: 'Product Name', label: 'Product Name' }
];

const NewShopPage = () => {
    /* TABLE COLUMNS */
    const columns = [
      {
        name: "Image",
        width: "150px",
        selector: (row) => (
          <img src={row.image} alt="Product" className="table-img" />
        ),
      },
      {
        name: "Name",
        width: "200px",
        selector: (row) => <h5 className="product-name">{row.name}</h5>,
      },
      {
        name: "Category",
        width: "200px",
        selector: (row) => <p className="product-category">{row.category}</p>,
      },
      {
        name: "SKU",
        width: "120px",
        selector: (row) => <p className="product-sku">{row.sku}</p>,
      },
      {
        name: "Colors",
        width: "200px",
        selector: () => (
          <FormControl fullWidth>
            <MUISelect
              className="custom-color-options"
              inputProps={{ "aria-label": "Without label" }}
              input={<OutlinedInput />}
            >
              <MenuItem value="Berry Diva">
                <div className="color-options">
                  <div
                    className="color-code"
                    style={{ backgroundColor: "#7A3B85" }}
                  />
                  <p>Berry Diva</p>
                </div>
              </MenuItem>
              <MenuItem value="BH Nude">
                <div className="color-options">
                  <div
                    className="color-code"
                    style={{ backgroundColor: "#C89390" }}
                  />
                  <p>BH Nude</p>
                </div>
              </MenuItem>
              <MenuItem value="Drama Queen">
                <div className="color-options">
                  <div
                    className="color-code"
                    style={{ backgroundColor: "#9E995F" }}
                  />
                  <p>Drama Queen</p>
                </div>
              </MenuItem>
              <MenuItem value="Rodeo Vamp">
                <div className="color-options">
                  <div
                    className="color-code"
                    style={{ backgroundColor: "#403332" }}
                  />
                  <p>Rodeo Vamp</p>
                </div>
              </MenuItem>
              <MenuItem value="Sapphire Siren">
                <div className="color-options">
                  <div
                    className="color-code"
                    style={{ backgroundColor: "#21366E" }}
                  />
                  <p>Sapphire Siren</p>
                </div>
              </MenuItem>
            </MUISelect>
          </FormControl>
        ),
      },
      {
        name: "Quantity",
        width: "150px",
        selector: () => (
          <input
            type="text"
            className="form-control"
            placeholder="Quantity"
            name="qty"
            id="qty"
          />
        ),
      },
      {
        name: "Actions",
        width: "100px",
        selector: () => (
          <Button className="qty-btn">
            <UilCart color="#FFF" size="18" />
          </Button>
        ),
      },
    ];
  
    return (
      <section className="new-shop ptb-30">
        <Helmet>
          <title>Wholesaasdle Products | Browbar By Reema</title>
        </Helmet>

        <div className="container">
          <h2>SHOP PRODUCTS</h2>

          {/* PAGE HEADINGS */}
          <div className="page-heading">
            <h5>You have total 22 products.</h5>
            <Select options={options} className="react-select" classNamePrefix="sort-by" placeholder="Sort by" menuIsOpen />
          </div>

          {/* PRODUCTS LIST */}
          <Row className="products-list mt-2">
            {/* FILTERS */}
            <Col xl="2" sm="12">
              <Card className="filters-card">
                <Card.Header className="filters-card-header">
                  <h5>Filters</h5>
                  <Button className="clear-filters-btn">Clear Filters</Button>
                </Card.Header>
                <Card.Body className="filters-card-body">
                  <div className="filter-category">
                    <h5>Categories</h5>
                    <div className="category-checkbox">
                      <Form.Check
                        className="checkbox-control"
                        label="Sets Collection"
                      />
                    </div>
                    <div className="category-checkbox">
                      <Form.Check
                        className="checkbox-control"
                        label="Face Collection"
                      />
                    </div>
                    <div className="category-checkbox">
                      <Form.Check
                        className="checkbox-control"
                        label="Lips Collection"
                      />
                    </div>
                    <div className="category-checkbox">
                      <Form.Check
                        className="checkbox-control"
                        label="Eyes Collection"
                      />
                    </div>
                    <div className="category-checkbox">
                      <Form.Check
                        className="checkbox-control"
                        label="Brow Collection"
                      />
                    </div>
                    <div className="category-checkbox">
                      <Form.Check
                        className="checkbox-control"
                        label="New Collection"
                      />
                    </div>
                  </div>
                  <div className="filter-category mt-2">
                    <div className="price">
                      <h5>Price</h5>
                      <p>$0 - $25</p>
                    </div>
                    <RangeSlider
                      className="pricing-range"
                      min={0}
                      max={100}
                      defaultValue={[0, 25]}
                      onThumbDragStart={(e) => console.log(e)}
                    />
                  </div>
                  <div className="filter-category pt-3">
                    <h5>Customer Reviews</h5>
                    <div className="ratings-checkbox">
                      <Form.Check className="checkbox-control" />
                      <div className="rating-details">
                        <UilStar color="#FFD700" size="22" />
                        <p>4 &amp; above</p>
                      </div>
                    </div>
                    <div className="ratings-checkbox">
                      <Form.Check className="checkbox-control" />
                      <div className="rating-details">
                        <UilStar color="#FFD700" size="22" />
                        <p>3 &amp; above</p>
                      </div>
                    </div>
                    <div className="ratings-checkbox">
                      <Form.Check className="checkbox-control" />
                      <div className="rating-details">
                        <UilStar color="#FFD700" size="22" />
                        <p>2 &amp; above</p>
                      </div>
                    </div>
                    <div className="ratings-checkbox">
                      <Form.Check className="checkbox-control" />
                      <div className="rating-details">
                        <UilStar color="#FFD700" size="22" />
                        <p>1 &amp; above</p>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            {/* LIST OF PRODUCTS */}
            <Col xl="10" sm="12">
              <Card className="list-table">
                <Card.Body className="list-table-body">
                  <DataTable columns={columns} data={sampleData} className="products-list-table" />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </section>
    );
};

export default NewShopPage;