import braintree from "braintree-web";
import { Button } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { useState } from "react";
import CreditCardInput from 'react-credit-card-input';
const BrainTree = ({ CLIENT_AUTHORIZATION, setNonce }) => {
  const [cardNumber, setCartNumber] = useState(0);
  const [cvv, setCvv] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const [expiry, setExpiry] = useState("");
  const onSubmit = () => {
    if (cardNumber === 0) {
      enqueueSnackbar("Please enter card number", {
        variant: "error",
      });
      return;
    }
    if (cvv === 0) {
      enqueueSnackbar("Please enter cvv", {
        variant: "error",
      });
      return;
    }
    if (expiry === "") {
      enqueueSnackbar("Please enter expiry", {
        variant: "error",
      });
      return;
    }
    braintree.client.create(
      {
        authorization: CLIENT_AUTHORIZATION,
      },
      function (err, client) {
        client.request(
          {
            endpoint: "payment_methods/credit_cards",
            method: "post",
            data: {
              creditCard: {
                number: cardNumber,
                expirationDate: expiry,
                cvv: cvv,
                //  billingAddress: {
                //    postalCode: "12345",
                //  },
              },
            },
          },
          function (err, response) {
            console.log("err: ", err);
            console.log("response: ", response);
            if (err?.details?.originalError?.error?.message) {
              enqueueSnackbar(err?.details?.originalError?.error?.message, {
                variant: "error",
              });
              return;
            }
            console.log('response.creditCards[0].nonce: ', response.creditCards[0].nonce);
            if (response.creditCards[0].nonce) {
              setNonce(response.creditCards[0].nonce);
              enqueueSnackbar("Verified", {
                variant: "success",
              });
            }
            // Send response.creditCards[0].nonce to your server
          }
        );
      }
    );
  };

  return (
    <>

      <form id="my-sample-form">
        <CreditCardInput
          cardNumberInputProps={{
            onChange: e => { console.log('number change', e.target.value); setCartNumber(e.target.value) }
          }}
          cardExpiryInputProps={{
            onChange: e => { console.log('expiry change', e.target.value); setExpiry(e.target.value) }
          }}
          cardCVCInputProps={{
            onChange: e => { console.log('cvc change', e.target.value); setCvv(e.target.value) }
          }}
          fieldClassName="input"
        />
        <input type="hidden" name="payment_method_nonce" />
        {/* <label for="card-number">Card Number</label> */}
        <input
          type="hidden"
          id="card-number"
          value={cardNumber}
          onChange={(e) => {
            setCartNumber(e.target.value);
          }}
        />

        {/* <label for="cvv">CVV</label> */}
        <input
          type="hidden"
          id="cvv"
          value={cvv}
          onChange={(e) => {
            setCvv(e.target.value);
          }}
        />

        {/* <label for="expiration-date">Expiration Date</label> */}
        <input
          value={expiry}
          type="hidden"
          onChange={(e) => {
            setExpiry(e.target.value);
          }}
          id="expiration-date"
        />
        <Button
          className="checkout-btn mt-0"
          
          // onClick={() => setActiveKey("complete")}
          onClick={onSubmit}
          id="my-submit" value="Verify"
        >
          Verify
        </Button>
        
      </form>
    </>
  );
};
export default BrainTree;