/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import Bestsellers from "./components/bestsellers";
import { useDispatch, useSelector } from "react-redux";
import { IMG_URL } from "../../config";
import { useUpdateCart } from "../../hooks/useUpdateCart";
import { cartList, deleteCart, updateCart } from "../../service/api";
import { useSnackbar } from "notistack";
import actions from "../../redux/products/actions";
import { useNavigate } from "react-router";

const quantity = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

const Cart = () => {
  const { cart } = useSelector((state) => state.products);
  const { loggedIn } = useSelector((state) => state.user);
  const [total, setTotal] = useState(0);
  const { setUpdateCart } = useUpdateCart();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [apiCall, setApiCall] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setUpdateCart(true);
    let totalPrice = 0;
    cart?.map((obj) => {
      totalPrice = totalPrice + obj?.quantity * obj?.amount;
    });
    console.log("totalPrice: ", totalPrice);
    console.log("cart: ", cart);
    setTotal(totalPrice);
  }, [cart]);
  const getCartData = () => {
    let params = {
      page: 1,
      sizePerPage: 100,
    };
    cartList(params).then((res) => {
      setApiCall(false);

      if (res?.status) {
        dispatch(actions.setCartData(res?.data?.docs));
      } else {
      }
    });
  };
  const updateCartQuantity = (id, quantity, index) => {
    if (loggedIn) {
      updateCart(id, "UPDATE", { quantity }).then((res) => {
        console.log(res);
        if (res?.status) {
          enqueueSnackbar(res?.message, { variant: "success" });

          setUpdateCart(true);
          getCartData();
        }
      });
    } else {
      cart[index].quantity = quantity;
      dispatch(actions.setCartData(cart));
      enqueueSnackbar("The quantity has been updated in the cart.", {
        variant: "success",
      });
    }
  };
  const handleRemoveCart = (data) => {
    setApiCall(true);

    if (loggedIn) {
      deleteCart(data?._id).then((res) => {
        console.log(res);
        enqueueSnackbar(res?.message, { variant: "success" });

        if (res?.status) {
          setUpdateCart(true);
          getCartData();
        }
      });
    } else {
      let index = cart.indexOf(data);
      let data1 = cart;
      dispatch(actions.setCartData(data1.filter((obj, i) => index !== i)));
      setApiCall(false);
    }
  };
  return (
    <section className="cart ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>Cart | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <Row>
          <Col lg="12" sm="12">
            <h2>Shopping Bag</h2>
          </Col>

          {/* ITEMS LIST */}
          <Col lg="8" sm="12">
            <div className="cart-items">
              <table className="shopping-items">
                <thead>
                  <tr className="table-head">
                    <td>Items</td>
                    <td>Item Price</td>
                    <td>Quantity</td>
                    <td>Total Price</td>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {cart.length === 0 ? <tr>
                    <td className="product-details ptb-10" colSpan={5}>
                      <p>
                        <b>Your cart is empty!</b>
                      </p>
                    </td>
                  </tr> : (cart &&
                    cart.map((data, i) => {
                      return (
                        <tr>
                          <td className="product-details ptb-10">
                            <div className="d-flex">
                              <img
                                src={
                                  IMG_URL +
                                  (data?.product?.productImage?.length > 0
                                    ? data?.product?.productImage?.filter(
                                      (img) => img.color === data.color._id
                                    )[0]?.front[0]
                                    : data?.product?.productImage?.front[0])
                                }
                                className="product-img-bought"
                                alt="Bombshell Brow Kit - Medium"
                              />
                              <div className="ml-2">
                                <h4 className="product-name">
                                  {data?.product?.name}
                                </h4>
                                <p>({data?.product?.sku})</p>
                                <p>
                                  <b>Color: </b>
                                  {data?.color?.name}
                                </p>
                                <p>
                                  <b>Price: </b>${data?.amount}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="product-price">${data?.amount}</p>
                          </td>
                          <td>
                            <Select
                              options={quantity}
                              classNamePrefix="select-control"
                              className="qty-width"
                              placeholder="1"
                              name={"quantity" + i}
                              id="quantity"
                              onChange={(e) => {
                                console.log("e: ", e, data?._id);
                                updateCartQuantity(data?._id, e.value, i);
                              }}
                              value={{
                                value: data?.quantity,
                                label: data?.quantity,
                              }}
                            />
                            <Button
                              className="remove-product-link"
                              onClick={() => handleRemoveCart(data)}
                              disabled={apiCall}
                            >
                              Remove
                            </Button>
                          </td>
                          <td>
                            <p className="product-price">
                              ${data?.amount * data?.quantity}
                            </p>
                          </td>
                        </tr>
                      );
                    }))}
                </tbody>
              </table>
            </div>
          </Col>

          {/* ORDER SUMMARY */}
          <Col lg="4" sm="12">
            <h5 className="summary-heading">Summary</h5>
            <div className="order-summary">
              <div className="items">
                <p>Items ({cart.length})</p>
                <p>${total.toFixed(2)}</p>
              </div>
              <hr />
              <div className="items">
                <p>
                  <b>Estimated Total</b>
                </p>
                <p>
                  <b>${total.toFixed(2)}</b>
                </p>
              </div>
              {/* <p className="after-pay-link">
                Or 4 interest free installments of $26.00 with <b>Afterpay</b>
              </p> */}
            </div>
            <Button
              onClick={() => cart.length === 0 ? enqueueSnackbar('Your Cart is Empty!', { variant: "error" }) : navigate("/checkout")}
              className="checkout-btn"
            >
              Proceed to Checkout
            </Button>
          </Col>

          {/* BESTSELLERS */}
          <Col lg="12" sm="12">
            <div className="mt-5">
              {/* <Bestsellers /> */}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Cart;