const sampleData = [
  {
    id: 1,
    image:
      "https://storage.googleapis.com/cdn-browbarbyreema/product/1674600542706.webp",
    name: "Bombshell Lipstick",
    category: "Face, Lips, Sets",
    sku: "BBRBL1",
  },
  {
    id: 2,
    image:
      "https://storage.googleapis.com/cdn-browbarbyreema/product/1674600542706.webp",
    name: "Bombshell Lipstick",
    category: "Face, Lips, Sets",
    sku: "BBRBL1",
  },
];

export default sampleData;