/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { OverlayTrigger, Navbar, Offcanvas, Nav, NavDropdown, Popover, Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineHeart, AiOutlineUser, AiOutlineShopping } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { IMG_URL } from "../../config";
import { getWishList, getAllCategory, getCategoryMenu, getCategoryMenuParams } from "../../service/api";
import actions from "../../redux/products/actions";

/* IMAGES */
import Logo from "../../assets/browbar-by-reema-logo.png";

const NewHeader = () => {
  const target = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { category, trigger } = useSelector(state => state.products)

  /* STATES */
  const [showCart, setShowCart] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showStarterPack, setShowStarterPack] = useState(false);
  const products = useSelector((state) => state.products);
  const user = useSelector((state) => state?.user);
  const { isLogin } = useSelector((state) => state.user);
  const [wishListCount, setwishListCount] = useState(0);
  const [headerVisible, setHeaderVisible] = useState(false);

  // Refs
  const refheight = useRef(null);
  const rowheight = useRef(null);

  // console.log(refheight.current);

  /* USE EFFFECTS */
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > (refheight.current.clientHeight + rowheight.current.clientHeight)) {
        setHeaderVisible(true);
      } else setHeaderVisible(false);
    });
    return () => {
      window.removeEventListener("scroll");
    };
  }, []);

  useEffect(() => {
    if (user?.token) {
      getWishList({
        page: 1,
        sizePerPage: 10,
      }).then((res) => {
        dispatch(actions.setWishList(res?.data?.totalDocs));
        setwishListCount(res?.data?.totalDocs);
      })
    } else {
      dispatch(actions.setWishList(0));
      setwishListCount(0);
    }
  }, [user?.token]);

  const [categoryMenue, setCategoryMenue] = useState([])
  const [menu, setMenu] = useState([])

  useEffect(() => {
    getAllCategory({
      page: 1,
      sizePerPage: 10,
    }).then((res) => {
      if (res.status) {
        dispatch(actions.setCategory(res?.data));
      }
    })
    getCategoryMenuParams({
      isRoot: true,
      isMenu: true,
      isWholeSale: true
    }).then((res) => {
      if (res.status) {
        console.log("setMenu", res?.data)
        let tempArray = [];
        setMenu(res?.data)
      }
    })
    getCategoryMenu().then(res => {
      console.log("getCategoryMenu", res)
      const tempArray = []
      res?.data?.forEach(element => {
        console.log(element)
        tempArray.push({ element, order: element?._id?.order })
      });
      // console.log("s",res?.data?._id?.order.sort())
      const sortBy = fn => {
        const cmp = (a, b) => -(a < b) || +(a > b);
        return (a, b) => cmp(fn(a), fn(b));
      };
      const getLastName = o => (o.order);
      const sortByLastName = sortBy(getLastName);
      tempArray.sort(sortByLastName);
      const s = tempArray.map(getLastName);

      const tempArray2 = [];
      s.forEach(element => {
        tempArray2.push(tempArray.find((e) => (e?.order === element))?.element)
      });


      setCategoryMenue(tempArray2)
    })
  }, [""]);

  useEffect(() => {
    if (products.wishList === undefined) {
      setwishListCount(0);
      return;
    }
    setwishListCount(products.wishList);
  }, [products.wishList]);

  /* CART OVERLAY */
  const CartOverlay = (
    <Popover>
      <Popover.Body>
        <div className="cart-popup">
          <div className="d-flex justify-content-between">
            <h5>
              MY BAG ({products.cart?.length ? products.cart?.length : 0})
            </h5>
          </div>
          <div className="products-added">
            <Row className="mt-1">
              {products.cart.length > 0 ? (
                <>
                  <Col lg="4" sm="4">
                    <img
                      src={
                        IMG_URL +
                        (products.cart[0]?.product?.productImage?.length > 0
                          ? products.cart[0]?.product?.productImage?.filter(
                            (img) =>
                              img.color === products.cart[0].color._id
                          )[0]?.front[0]
                          : products.cart[0]?.product?.productImage?.front[0])
                      }
                      className="product-img-bought"
                      alt="Bombshell Brow Kit - Medium"
                    />
                  </Col>
                  <Col lg="8" sm="8">
                    <h4 className="product-name">
                      {products?.cart[0]?.product?.name}
                    </h4>
                    <div className="d-flex">
                      <p>
                        <b>Color: </b>
                        {products?.cart[0]?.color?.name}
                      </p>
                      <p className="ml-2">
                        <b>Quantity: </b>
                        {products?.cart[0]?.quantity}
                      </p>
                    </div>
                    <p>
                      <b>Price: </b>$ {products?.cart[0]?.amount}
                    </p>
                  </Col>
                </>
              ) : (
                <Row className="mt-1 justify-content-center">
                  <Col lg="12" sm="12" style={{ fontFamily: "Figtree, sans-serif" }}>
                    Your cart is empty.
                  </Col>
                </Row>
              )}
            </Row>
            {products.cart.length > 1 && (
              <Row className="mt-1 justify-content-center">
                <Col lg="12" sm="12">
                  <Link className="items-in-cart-link" to="/cart">
                    +{products.cart.length - 1} more items in cart
                  </Link>
                </Col>
              </Row>
            )}
            <hr />
            <Row>
              <Col lg="6" sm="12">
                {/* <Link to="/checkout">
                  <Button className="checkout-btn">CHECKOUT</Button>
                </Link> */}
                <Button
                  onClick={() =>
                    products.cart.length === 0
                      ? enqueueSnackbar("Your Cart is Empty!", {
                        variant: "error",
                      })
                      : navigate("/checkout")
                  }
                  className="checkout-btn"
                >
                  CHECKOUT
                </Button>
              </Col>
              <Col lg="6" sm="12">
                <Link to="/cart">
                  <Button className="view-cart-btn">VIEW CART</Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <header className="new-header">
        {/* TOP HEADER */}
        {/* <div className="top-header" ref={refheight}>
          <h5>
          💃 Glam Up Your Memorial Day! Save 40% off ALL brow products with code MD40 at Brow Bar by Reema. 💋✨
          </h5>
        </div> */}
        {/*  <div className="top-header-two">
          <h5>Free shipping for all USA orders $40 plus!</h5>
          <div className="switcher-tabs">
            <div className="switcher-box active">
              <h5>WHOLESALE</h5>
            </div>
            <div className="switcher-box">
              <a
                href="https://browbarbyreema.com/"
                target={"_blank"}
                rel="noreferrer"
              >
                <h5>RETAIL</h5>
              </a>
            </div>
          </div>
        </div> */}

        {/* HEADER CONTENT */}
        <div className="new-header-wrap">
          <div className="container">
            <div className="header-flex">
              {/* HEADER LEFT */}
              <div className="header-left">
                <Navbar expand="lg">
                  <Navbar.Toggle aria-controls="menu-expand" />
                  <Navbar.Offcanvas id="menu-expand" placement="start">
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title id="offcanvas-expand-lg">
                        <Link to="/">
                          <div className="logo">
                            <img src={Logo} alt="Browbar By Reema" />
                          </div>
                        </Link>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav className="main-menu">
                        <Nav.Link
                          className="menu-link"
                          onClick={() => navigate("/")}
                        >
                          Home
                        </Nav.Link>
                        <NavDropdown title="SHOP" className="mobile-dropdown">
                          <NavDropdown.Item href="/contact-us">
                            Bestsellers
                          </NavDropdown.Item>
                          <NavDropdown.Item href="">
                            New Arrivals
                          </NavDropdown.Item>
                          <NavDropdown.Item href="">DIY Brows</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link
                          className="menu-link mobile-hide"
                          onClick={() => setShowDropdown(!showDropdown)}
                        >
                          Shop
                        </Nav.Link>
                        <Nav.Link
                          className="menu-link"
                          onClick={() => navigate("/press")}
                        >
                          Press
                        </Nav.Link>
                        <Nav.Link
                          className="menu-link"
                          onClick={() => navigate("/about")}
                        >
                          About
                        </Nav.Link>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Navbar>
              </div>

              {/* WEBSITE LOGO */}
              <Link to="/" className="logo">
                <img src={Logo} alt="Browbar By Reema" />
              </Link>

              {/* HEADER RIGHT */}
              <div className="header-right">
                {/* WISHLIST ICON */}

                {/* ACCOUNT ICON */}
                <Link
                  className="header-actions redirect-url"
                  to={user?.loggedIn ? "/dashboard" : "/login"}
                >
                  <AiOutlineUser className="action-icon d-block d-lg-none" />
                  <p className="d-none d-lg-block">
                    {user?.loggedIn ? "Dashboard" : "Login"}
                  </p>
                </Link>

                {/* CART ICON */}
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={CartOverlay}
                >
                  <Link
                    className="header-actions"
                    ref={target}
                    onClick={() => setShowCart(!showCart)}
                  >
                    <AiOutlineShopping className="action-icon" />
                    <span className="count">
                      {products.cart?.length ? products.cart?.length : 0}
                    </span>
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            headerVisible ? "new-header-wrap new-header-wrap-scroll" : "d-none"
          }
        >
          <div className="container">
            <div className="header-flex">
              {/* HEADER LEFT */}
              <div className="header-left">
                <Navbar expand="lg">
                  <Navbar.Toggle aria-controls="menu-expand" />
                  <Navbar.Offcanvas id="menu-expand" placement="start">
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title id="offcanvas-expand-lg">
                        <Link to="/">
                          <div className="logo">
                            <img src={Logo} alt="Browbar By Reema" />
                          </div>
                        </Link>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Nav className="main-menu">
                        <Nav.Link
                          className="menu-link"
                          onClick={() => navigate("/")}
                        >
                          Home
                        </Nav.Link>
                        <NavDropdown title="SHOP" className="mobile-dropdown">
                          <NavDropdown.Item href="/contact-us">
                            Bestsellers
                          </NavDropdown.Item>
                          <NavDropdown.Item href="">
                            New Arrivals
                          </NavDropdown.Item>
                          <NavDropdown.Item href="">DIY Brows</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link
                          className="menu-link mobile-hide"
                          onClick={() => setShowDropdown(!showDropdown)}
                        >
                          Shop
                        </Nav.Link>
                        <Nav.Link
                          className="menu-link"
                          onClick={() => navigate("/press")}
                        >
                          Press
                        </Nav.Link>
                        <Nav.Link
                          className="menu-link"
                          onClick={() => navigate("/about")}
                        >
                          About
                        </Nav.Link>
                      </Nav>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </Navbar>
              </div>

              {/* WEBSITE LOGO */}
              <Link to="/" className="logo">
                <img src={Logo} alt="Browbar By Reema" />
              </Link>

              {/* HEADER RIGHT */}
              <div className="header-right">
                {/* WISHLIST ICON */}

                {/* ACCOUNT ICON */}
                <Link
                  className="header-actions redirect-url"
                  to={user?.loggedIn ? "/dashboard" : "/login"}
                >
                  <AiOutlineUser className="action-icon d-block d-lg-none" />
                  <p className="d-none d-lg-block">
                    {user?.loggedIn ? "Dashboard" : "Login"}
                  </p>
                </Link>

                {/* CART ICON */}
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={CartOverlay}
                >
                  <Link
                    className="header-actions"
                    ref={target}
                    onClick={() => setShowCart(!showCart)}
                  >
                    <AiOutlineShopping className="action-icon" />
                    <span className="count">
                      {products.cart?.length ? products.cart?.length : 0}
                    </span>
                  </Link>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* DROPDOWN */}
      <div className={`new-dropdown ${showDropdown ? "active" : "inactive"}`}>
        <div className="container dropdown-items">
          <div className="column right-border">
            <div className="column">
              <div
                className="dropdown-link"
                onClick={() => {
                  setShowStarterPack(!showStarterPack);
                  // navigate('/brow-kit')
                }}
              >
                Starter Pack
              </div>
              <div className={showStarterPack ? "px-2" : "d-none"}>
                <Link
                  to="/salon-kit"
                  onClick={() => {
                    setShowDropdown(!showDropdown);
                    setShowStarterPack(!showStarterPack);
                  }}
                  className="small-menu-link"
                >
                  Prepacks
                </Link>
                <Link
                  to="/display-case"
                  onClick={() => {
                    setShowDropdown(!showDropdown);
                    setShowStarterPack(!showStarterPack);
                  }}
                  className="small-menu-link"
                >
                  Displays
                </Link>
              </div>
            </div>

            <div
              key={menu?.find((item) => item.name === "Shop")?._id}
              className="dropdown-link"
              onClick={() => {
                dispatch(
                  actions.setWholesaleSelectedProduct(
                    menu?.find((item) => item.name === "Shop")
                  )
                );
                dispatch(actions.setMenu(true));
                dispatch(actions.shopAll(false));
                dispatch(actions.setSinglePdt(false));
                dispatch(
                  actions.setCategoryId(
                    menu?.find((item) => item.name === "Shop")?._id
                  )
                );
                setShowDropdown(!showDropdown);
                navigate("/wholesale-shop");
              }}
            >
              {"Shop All"}
            </div>

            <div
              key={menu?.find((item) => item.name === "Best Sellers")?._id}
              className="dropdown-link"
              onClick={() => {
                dispatch(
                  actions.setWholesaleSelectedProduct(
                    menu?.find((item) => item.name === "Best Sellers")
                  )
                );
                dispatch(actions.setMenu(true));
                dispatch(actions.shopAll(false));
                dispatch(
                  actions.setCategoryId(
                    menu?.find((item) => item.name === "Best Sellers")
                  )
                );
                dispatch(actions.setSinglePdt(false));
                setShowDropdown(!showDropdown);
                navigate("/wholesale-shop");
              }}
            >
              {"Best Sellers"}
            </div>

            {/* <Link to="/" className="small-menu-link">
              DIY Brows
            </Link> */}
          </div>

          {categoryMenue?.map((item) => {
            return (
              <div className="column" key={item?._id?._id}>
                <div key={item?._id?.name} className="dropdown-link">
                  {item?._id?.name}
                </div>
                {item?.Products?.map((item2) => {
                  return item2?.isWholeSale ? (
                    <div
                      onClick={() => {
                        dispatch(actions.setWholesaleSelectedProduct(item2));
                        dispatch(actions.shopAll(false));
                        dispatch(actions.setMenu(false));
                        dispatch(actions.setSinglePdt(true));
                        dispatch(actions.setCategoryId(item?._id));
                        setShowDropdown(!showDropdown);
                        navigate("/wholesale-shop");
                      }}
                      key={item2?._id}
                      className="small-menu-link"
                    >
                      {item2?.name}
                    </div>
                  ) : null;
                })}
                <div
                  onClick={() => {
                    console.log(item);
                    dispatch(actions.setCategoryId(item?._id));
                    dispatch(actions.shopAll(false));
                    dispatch(actions.setSinglePdt(false));
                    dispatch(actions.setMenu(false));
                    dispatch(actions.setWholesaleSelectedProduct(null));
                    dispatch(actions.setTrigger(!trigger));
                    setShowDropdown(!showDropdown);
                    navigate("/wholesale-shop");
                  }}
                  key={item?._id?._id}
                  className="small-menu-link"
                  style={{ color: "#3E3EF4", fontWeight: "600" }}
                >
                  View All Products
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default NewHeader;