import React from "react";
import { Row, Col } from 'react-bootstrap';
import { Helmet } from "react-helmet";

const DIYBrows = () => {
    return (
      <section className="diy-brows ptb-30">
        {/* HELMET */}
        <Helmet>
          <title>
            Reema's DIY Brow Tutorial Collection | Brow Bar by Reema
          </title>
        </Helmet>

        <div className="container">
          <h2>REEMA'S DIY BROW TUTORIAL COLLECTION</h2>
          <Row className="justify-content-center">
            <Col lg="12" sm="12">
              <div className="banner">
                <img
                  src={require("../../assets/banners/diy-brows-banner.webp")}
                  alt="DIY Brows | Brow Bar by Reema"
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col lg="6" sm="12">
              <h3>JOIN BROW BAR BY REEMA'S DIY BROW REVOLUTION</h3>
              <video controls className="video-frame ptb-10">
                <source
                  src={require("../../assets/banners/diy-brow-revolution.mp4")}
                  type="video/mp4"
                />
              </video>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <h2>BECOME YOUR OWN BROW EXPERT!</h2>
            <h5>
              TUTORIALS TO HELP YOU APPLY THE PERFECT BROW/MAKEUP FOR EACH BROW
              KIT & SET!
            </h5>
          </Row>

          {/* BOMBSHELL BROW KIT */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Bombshell Brow Kit</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/iP8K8v0igyA"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/DvthJo_twQs"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/9trbeqxJ3E4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/ALkzfKnoXNw"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/aNC5q2dZSBg"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* MICROBLADE PEN */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Microblade Pen</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/aOKdgRluojo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/YTJLyXIgYIU"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/tlCxXaKpJPg"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* BADASS BROW LAMINATE */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Badass Brow Laminate</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/ZcsbU-uCVHA"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/f5_PQEsVqDs"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/O3D2trJ0QG4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* BROW MAKEOVER KIT */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Brow Makeover Kit</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/sDyVgi_p0gI"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/79d9I6xQIdQ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/YxPrEgZHo2I"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* BOMBSHELL BROW ESSENTIALS DAY KIT */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Bombshell Brow Essentials Day Kit</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/INJMn-4NAy4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/5TPQAkVMQjw"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/gX6ocIUCrXo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* BOMBSHELL BROW ESSENTIALS NIGHT KIT */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Bombshell Brow Essentials Night Kit</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/aw0vkmSzNnE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/5TPQAkVMQjw"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/W4Y8UOv3ipY"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* BOMBSHELL BROW ESSENTIALS NIGHT KIT */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Bombshell Brow Serum</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/XhxMZlFqKv0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/6hTQ5u7cVJo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/POsEa9tTtq8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* QUICK FIX BROW KIT */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Quick Fix Brow Kit</h4>
              <Row>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/qJR1xlfgU68"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/LBlJ-S1iX6Q"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* ULTIMATE BROW SPA KIT TUTORIAL */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Ultimate Brow Spa Kit Tutorial</h4>
              <Row>
                <Col lg="12" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="600"
                    src="https://www.youtube.com/embed/0y-ZCBL8xc0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* BBR RED CARPET KIT */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>BBR Red Carpet Kit</h4>
              <Row>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/PWs21Jmseg4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/83f1sWUZvbI"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* BIPOLAR BROW SET */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Bipolar Brow Set</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/FNye5lhKUFo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/z_DUfh-yGTM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/HboSZMQOJJ8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* EYELASHES */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Eyelashes</h4>
              <Row>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/diWm5hn35LQ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/UbnunUCpWmU"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* PRODEFINER PENCIL */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Prodefiner Pencil</h4>
              <Row>
                <Col lg="12" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="600"
                    src="https://www.youtube.com/embed/1vGzowC3EpA"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* FLAWLESS EYELINER */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Flawless Eyeliner</h4>
              <Row>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/iW-keb9U9mc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/qHBF4UvrlNs"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* KAJAL */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Kajal</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/fl8wJA1mfaI"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/LCwlRQcYDDQ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/FvwDZAgicRM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* BOMBSHELL BROW MASCARA GEL */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Bombshell Brow Mascara Gel</h4>
              <Row>
                <Col lg="12" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="600"
                    src="https://www.youtube.com/embed/C4HevZZUyOQ"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* HOLLYWOOD ICON FACE KIT */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Hollywood Icon Sapphire Siren Face Kit Tutorial</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/B3GYM_aXsZ0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/3P291XQN5jE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/KDnSnQx1pyo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* HOLLYWOOD ICON BERRY DIY FACE KIT */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Hollywood Icon Berry Diva Face Kit Tutorial</h4>
              <Row>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/Ja_Dt1gP5os"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="6" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="400"
                    src="https://www.youtube.com/embed/ev_TK9g3bbE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* HOLLYWOOD ICON DRAMA QUEEN FACE KIT */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Hollywood Icon Drama Queen Face Kit Tutorial</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/cMhKdX2leN0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/ThjowV9GoB8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/vg3vRTUX3cY"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* LIPSTICK */}
          <Row className="mt-3">
            <Col lg="12" sm="12">
              <h4>Lipstick</h4>
              <Row>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/nqAtyYbMebc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/XJol3eEwRlI"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
                <Col lg="4" sm="12">
                  <iframe
                    className="youtube-video-frame"
                    height="300"
                    src="https://www.youtube.com/embed/_loN8XJ62Is"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
    );
};

export default DIYBrows;