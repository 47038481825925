const actions = {
  GET_COLOR: "GET_COLOR",
  GET_TYPE: "GET_TYPE",
  GET_COMPOSITION: "GET_COMPOSITION",
  GET_PATTERN: "GET_PATTERN",
  SET_PARAMS: "SET_PARAMS",
  SET_SELECTED_PRODUCT_ID: "SET_SELECTED_PRODUCT_ID",
  SET_SELECTED_COLOR_ID:"SET_SELECTED_COLOR_ID",
  SET_SELECTED_PRODUCT: "SET_SELECTED_PRODUCT",
  SET_WISHLIST: "SET_WISHLIST",
  SET_SEARCH: "SET_SEARCH",
  CART_PRODUCTS: "CART_PRODUCTS",
  SET_CATEGORY: "SET_CATEGORY",
  SET_TAG: "SET_TAG",
  SET_MODAL: "SET_MODAL",
  LOGOUT: "LOGOUT",
  SET_ORDER_DETAILS: "SET_ORDER_DETAILS",
  SET_ORDER_CONFIRMATION: "SET_ORDER_CONFIRMATION",
  SET_WISHLIST_DATA: "SET_WISHLIST_DATA",
  SET_WISHLIST_TRIGGER: "SET_WISHLIST_TRIGGER",
  SET_WHOLESALE_SELECTED_PRODUCT: "SET_WHOLESALE_SELECTED_PRODUCT",
  CATEGORY_ID:"CATEGORY_ID",
  SET_TRIGGER:"SET_TRIGGER",
  SHOP_ALL: "SHOP_ALL",
  SINGLE_PDT: "SINGLE_PDT",
  SET_MENU:"SET_MENU",
  setColor: (data) => {
    return {
      type: actions.GET_COLOR,
      data,
    };
  },
  setType: (data) => {
    return {
      type: actions.GET_TYPE,
      data,
    };
  },
  setComposition: (data) => {
    return {
      type: actions.GET_COMPOSITION,
      data,
    };
  },
  setPattern: (data) => {
    return {
      type: actions.GET_PATTERN,
      data,
    };
  },
  setParams: (data) => {
    return {
      type: actions.SET_PARAMS,
      data,
    };
  },
  setSelectedProductId: (data) => {
    return {
      type: actions.SET_SELECTED_PRODUCT_ID,
      data,
    };
  },
  setSelectedColorId: (data) => {
    return {
      type: actions.SET_SELECTED_COLOR_ID,
      data,
    };
  },
  setSelectedProduct: (data) => {
    return {
      type: actions.SET_SELECTED_PRODUCT,
      data,
    };
  },
  setWishList: (data) => {
    return {
      type: actions.SET_WISHLIST,
      data,
    };
  },
  setSearch: (data) => {
    return {
      type: actions.SET_SEARCH,
      data,
    };
  },
  setCartData: (data) => {
    return {
      type: actions.CART_PRODUCTS,
      data,
    };
  },
  setCategory: (data) => {
    return {
      type: actions.SET_CATEGORY,
      data,
    };
  },
  setTag: (data) => {
    return {
      type: actions.SET_TAG,
      data,
    };
  },
  setModal: (data) => {
    return {
      type: actions.SET_MODAL,
      data,
    };
  },
  logout: (data) => {
    return {
      type: actions.LOGOUT,
      data,
    };
  },
  setOrderDetails: (data) => {
    return {
      type: actions.SET_ORDER_DETAILS,
      data,
    };
  },
  setOrderConfirmation: (data) => {
    return {
      type: actions.SET_ORDER_CONFIRMATION,
      data,
    };
  },
  setWishlistData: (data) => {
    return {
      type: actions.SET_WISHLIST_DATA,
      data,
    };
  },
  setWishlistTrigger: (data) => {
    return {
      type: actions.SET_WISHLIST_TRIGGER,
      data,
    };
  },
  setWholesaleSelectedProduct: (data) => {
    return {
      type: actions.SET_WHOLESALE_SELECTED_PRODUCT,
      data,
    };
  },
  shopAll: (data) => {
    return {
      type: actions.SHOP_ALL,
      data,
    };
  },
  setCategoryId: (data) => {
    return {
      type: actions.CATEGORY_ID,
      data,
    };
  },
  setTrigger: (data) => {
    return {
      type: actions.SET_TRIGGER,
      data,
    };
  },
  setSinglePdt: (data) => {
    return {
      type: actions.SINGLE_PDT,
      data,
    };
  },
  setMenu: (data) => {
    return {
      type: actions.SET_MENU,
      data,
    };
  },
};

export default actions;
