/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Modal,
  Accordion,
  useAccordionButton,
  Spinner,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import countries from "countries-list";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { IMG_URL } from "../../config";
import { YOUR_GOOGLE_MAPS_API_KEY } from "../../config";
import {
  getUserProfie,
  postNewAdress,
  editAdress,
  deleteAdress,
  register,
  resendOtp,
  verifyOtp,
  getShippingAddressData,
  placeOrder,
  getCustomerData,
  checkPromoCode,
} from "../../service/api";
import { useSnackbar } from "notistack";
import SpinnerComponent from "../../components/Spinner";
import Autocomplete from "react-google-autocomplete";
import { AiFillEye, AiFillEyeInvisible, AiOutlineCheckCircle } from "react-icons/ai";
import actions from "../../redux/user/actions";
import productActions from "../../redux/products/actions";
import states from "us-state-codes";
import BrainTree from "./BrainTree";
import { useUpdateCart } from "../../hooks/useUpdateCart";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DocumentScanner } from "@mui/icons-material";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Please enter valid email address").required(),
  address: Yup.string().required("Address is required"),
  addressLine1: Yup.string(),
  city: Yup.string().required("City is required"),
  zipCode: Yup.string().required("Zip code is required"),
  state: Yup.string().required("State is required"),
  country: Yup.string().required("Country is required"),
  firstName: Yup.string().required("First name is required"),
  companyName: Yup.string(),
  phone: Yup.string().required("Phone number is required"),
  lastName: Yup.string().required("Last name is required"),
});

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};
const Checkout = () => {
  const [activeKey, setActiveKey] = useState("shipping");
  const state = [];
  function compare(a, b) {
    if (a[1].name < b[1].name) {
      return -1;
    }
    if (a[1].name > b[1].name) {
      return 1;
    }
    return 0;
  }
  Object.entries(countries?.countries)
    .sort(compare)
    .map((key) => {
      state.push({ value: key[0], label: key[1].name });
    });
  const { cart } = useSelector((state) => state.products);
  const user = useSelector((state) => state.user);

  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();
  useEffect(() => {
    let totalPrice = 0;
    cart?.map((obj) => {
      totalPrice = totalPrice + obj?.quantity * obj?.amount;
    });
    console.log("totalPrice: ", totalPrice);
    console.log("cart: ", cart);
    setTotal(totalPrice);
  }, [cart]);

  const { enqueueSnackbar } = useSnackbar();
  const { setUpdateCart } = useUpdateCart();

  //COUPON CODE
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeErr, setCouponCodeErr] = useState("");
  const [couponCodeData, setCouponCodeData] = useState(null);
  const [codeSubmited, setCodeSubmited] = useState(false);
  console.log("couponCodeData: ", couponCodeData);
  const handleCouponCode = () => {
    if (couponCode.trim().length !== 0) {
      setCouponCodeErr("");
      setCodeSubmited(true);
      checkPromoCode({ code: couponCode, cart: cart, totalAmount: total, shippingData: shippingData, taxData: taxData })
        .then((res) => {
          setCodeSubmited(false);
          console.log("res: ", res);
          if (res.status) {
            setCouponCodeErr("");
            enqueueSnackbar(res?.message, {
              variant: "success",
            });
            document.getElementById('couponCodeDisplayButton').click()
            setCouponCodeData(res.data);
            setCouponCode('');
          } else {
            setCouponCodeData(null);
            setCouponCodeErr(res?.message);
          }
        })
        .catch((err) => {
          setCodeSubmited(false);
          setCouponCodeData(null);

          console.log("err: ", err);
          if (err?.response?.data?.message) {
            setCouponCodeErr(err?.response?.data?.message);
          }
        });
    } else {
      setCouponCodeErr("Please enter a coupon code.");
    }


  };

  //ADDRESS MODULE

  const [userData, setUserData] = useState();
  const [defaultAddress, setDefaultAddress] = useState("");
  const [selectAdressId, setselectAdressId] = useState(0);
  const [addressModal, setAddressModal] = useState(false);
  const [modalState, setModalState] = useState();
  const [addressId, setAddressId] = useState();
  const [shippingData, setShippingData] = useState(null);
  const [paymentMode, setPaymentMode] = useState("PAYPAL"); //COD
  const [BrainTreeData, setBrainTreeData] = useState(null);
  const [nonce, setNonce] = useState(null);
  const navigate = useNavigate();
  console.log("shippingData: ", shippingData);
  const [apiCall, setApiCall] = useState(false);

  const [input, setInput] = useState({
    address: "",
    addressLine1: null,
    city: "",
    state: "",
    zipCode: null,
    country: "",
  });
  const [inputError, setInputError] = useState({
    address: false,
    addressLine1: false,
    city: false,
    state: false,
    zipCode: false,
    country: false,
  });
  const [checkoutAddress, setCheckoutAddress] = useState({
    address: "",
    addressLine1: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    email: "",
    firstName: "",
    lastName: "",
    companyName: "",
    phone: null,
  });
  const [checkoutInputError, setCheckoutInputError] = useState({
    address: false,
    addressLine1: false,
    city: false,
    state: false,
    zipCode: false,
    country: false,
    email: false,
    firstName: false,
    lastName: false,
    phone: false,
  });

  const [loading, setLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const formik = useFormik({
    initialValues: {
      address: "",
      addressLine1: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
      email: "",
      firstName: "",
      lastName: "",
      companyName: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setActiveKey("delivery");
      setCheckoutAddress(values);
      handleCalculateShipping();
    },
  });
  console.log("formik: ", formik);

  useEffect(() => {
    getUserProfie().then((res) => {
      console.log("res: ", res);
      if (res?.status) {
        setUserData(res?.data);
        if (user?.loggedIn) {
          console.log("default address-->", res?.data);
          if (res?.data?.address?.length > 0) {
            setDefaultAddress(res?.data?.address[0]);
            formik.setFieldValue("address", res?.data?.address[0]?.address);
            formik.setFieldValue(
              "addressLine1",
              res?.data?.address[0]?.addressLine1
            );
            formik.setFieldValue("city", res?.data?.address[0]?.city);
            formik.setFieldValue("state", res?.data?.address[0]?.state);
            formik.setFieldValue("zipCode", res?.data?.address[0]?.zipCode);
            formik.setFieldValue("country", res?.data?.address[0]?.country);
            formik.setFieldValue("firstName", res?.data?.firstName);
            formik.setFieldValue("lastName", res?.data?.lastName);
            formik.setFieldValue("email", res?.data?.email);
            formik.setFieldValue("phone", res?.data?.mobile);
            setselectAdressId(res?.data?.address[0]?._id);
          } else {
            formik.setFieldValue("firstName", res?.data?.firstName);
            formik.setFieldValue("lastName", res?.data?.lastName);
            formik.setFieldValue("email", res?.data?.email);
            formik.setFieldValue("phone", res?.data?.mobile);
          }
        }
      }
    });
  }, [trigger]);

  const addAdress = (e) => {
    e.preventDefault();

    console.log("input", input);
    if (!input?.address) {
      setInputError({ ...inputError, address: true });
      return;
    }
    // if (!input?.addressLine1) {
    //   setInputError({ ...inputError, addressLine1: true });
    //   return;
    // }
    if (!input?.city) {
      setInputError({ ...inputError, city: true });
      return;
    }
    if (!input?.state) {
      setInputError({ ...inputError, state: true });
      return;
    }
    if (!input?.zipCode) {
      setInputError({ ...inputError, zipCode: true });
      return;
    }
    if (!input?.country) {
      setInputError({ ...inputError, country: true });
      return;
    }
    if (input?.addressLine1 === "" || input?.addressLine1 === null) {
      delete input?.addressLine1;
    }
    console.log(input.addressLine1);
    setAddressModal(false);
    setLoading(true);
    if (modalState === "Add") {
      postNewAdress(input)
        .then((res) => {
          console.log(res);
          if (res?.status)
            enqueueSnackbar(res?.message, { variant: "success" });

          getUserProfie().then((res) => {
            setUserData(res?.data);
            setLoading(false);
            setDefaultAddress(res?.data?.address[0]);
            // formik.setFieldValue("address", res?.data?.address[0]?.address);
            // formik.setFieldValue(
            //   "address1",
            //   res?.data?.address[0]?.addressLine1
            // );
            // formik.setFieldValue("city", res?.data?.address[0]?.city);
            // formik.setFieldValue("country", res?.data?.address[0]?.country);
            // formik.setFieldValue("state", res?.data?.address[0]?.state);
            // formik.setFieldValue("zip", res?.data?.address[0]?.zipCode);
            setselectAdressId(res?.data?.address[0]?._id);
            console.log(res?.data);
          });
          setInput({
            ...input,
            address: "",
            addressLine1: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
          });
        })
        .catch((e) => {
          enqueueSnackbar(e?.response?.data?.message, {
            variant: "error",
          });

          setLoading(false);
        });
    } else if (modalState === "Edit") {
      editAdress(addressId, input)
        .then((res) => {
          console.log(res);
          if (res?.status)
            enqueueSnackbar(res?.message, { variant: "success" });
          setAddressModal(false);
          getUserProfie().then((res) => {
            setUserData(res?.data);
            setLoading(false);
            setDefaultAddress(res?.data?.address[0]);
            // formik.setFieldValue("address", res?.data?.address[0]?.address);
            // formik.setFieldValue(
            //   "address1",
            //   res?.data?.address[0]?.addressLine1
            // );
            // formik.setFieldValue("city", res?.data?.address[0]?.city);
            // formik.setFieldValue("country", res?.data?.address[0]?.country);
            // formik.setFieldValue("state", res?.data?.address[0]?.state);
            // formik.setFieldValue("zip", res?.data?.address[0]?.zipCode);
            setselectAdressId(res?.data?.address[0]?._id);
            console.log(res?.data);
          });
          setInput({
            ...input,
            address: "",
            addressLine1: "",
            city: "",
            state: "",
            zipCode: " ",
            country: "",
          });
        })
        .catch((e) => {
          enqueueSnackbar(e?.response?.data?.message, {
            variant: "error",
          });

          setLoading(false);
        });
    }
  };
  const [shippingOptions, setShippingOptions] = useState([]);
  const [loadingShipping, setLoadingShipping] = useState(false);
  console.log("shippingOptions: ", shippingOptions);
  const [taxData, setTaxData] = useState(null);

  const handleCalculateShipping = () => {
    if (!formik.values.address || formik.values.address === "") {
      enqueueSnackbar("Please select the address to proceed further", {
        variant: "error",
      });
      return;
    }
    if (!formik.values.country || formik.values.country === "") {
      enqueueSnackbar("Please select the country to proceed further", {
        variant: "error",
      });
      return;
    }
    if (!formik.values?.zipCode || formik.values?.zipCode === "") {
      enqueueSnackbar("Please enter ZIP to proceed further", {
        variant: "error",
      });
      return;
    }
    if (!formik.values?.address || formik.values?.address === "") {
      enqueueSnackbar("Please enter address to proceed further", {
        variant: "error",
      });
      return;
    }
    if (!formik.values?.city || formik.values?.city === "") {
      enqueueSnackbar("Please enter city to proceed further", {
        variant: "error",
      });
      return;
    }
    let stateName = states.getStateCodeByStateName(formik.values.state)
      ? states.getStateCodeByStateName(formik.values.state)
      : formik.values.state;
    // address1Data?.map((obj) => {
    //   if (obj?.types?.includes("administrative_area_level_1")) {
    //     stateName = obj?.short_name;
    //   }
    // });
    let weight = 0;
    cart?.map((obj) => {
      weight = weight + obj?.quantity * obj.product?.weight;
    });
    console.log("weight: ", weight);
    setLoadingShipping(true);
    getShippingAddressData({
      country: state.filter((obj) => obj?.label === formik.values.country)[0]
        ?.value,
      zipCode: formik.values?.zipCode.toString(),
      weight,
      city: formik.values?.city,
      state: stateName,
      address: formik.values?.address,
    })
      ?.then((res) => {
        setLoadingShipping(false);

        console.log("res: ", res);
        if (res?.status) {
          if (res?.data?.shippingData) {
            setShippingOptions(res?.data?.shippingData);
          } else {
            setShippingOptions([]);
            enqueueSnackbar(
              "Your selected shipping address is too far for the delivery options.",
              {
                variant: "error",
              }
            );
          }
          setTaxData(res?.data?.taxData);
        }
      })
      .catch((err) => {
        setLoadingShipping(false);

        console.log("err: ", err);
      });
  };
  const changeShippingUPS = (tag) => {
    let shippingT = document.getElementsByName("shipment-type-ups");
    console.log("shippingT: ", shippingT);
    for (let i = 0, length = shippingT?.length + 1; i < length; i++) {
      if (shippingT[i].checked) {
        // do whatever you want with the checked radio
        // if (total < 40) {
        // setShippingType(shippingT[i].value);

        setShippingData(shippingOptions[shippingT[i].value]);
        // }
        // only one radio can be logically checked, don't check the rest
        break;
      }
    }
  };
  const changePaymentType = (tag) => {
    let shippingT = document.getElementsByName("payment-type");
    console.log("shippingT: ", shippingT);
    for (let i = 0, length = shippingT?.length + 1; i < length; i++) {
      if (shippingT[i].checked) {
        // do whatever you want with the checked radio
        // if (total < 75) {
        // setShippingType(shippingT[i].value);
        setPaymentMode(shippingT[i].value);
        // }
        // only one radio can be logically checked, don't check the rest
        break;
      }
    }
  };
  const deleteAdressFun = (id) => {
    console.log(id);
    setLoading(true);
    deleteAdress(id)
      .then((res) => {
        console.log(res);
        if (res?.status) enqueueSnackbar(res?.message, { variant: "success" });
        getUserProfie().then((res) => {
          setUserData(res?.data);
          setLoading(false);
          setDefaultAddress(res?.data?.address[0]);
          // formik.setFieldValue("address", res?.data?.address[0]?.address);
          // formik.setFieldValue("address1", res?.data?.address[0]?.addressLine1);
          // formik.setFieldValue("city", res?.data?.address[0]?.city);
          // formik.setFieldValue("country", res?.data?.address[0]?.country);
          // formik.setFieldValue("state", res?.data?.address[0]?.state);
          // formik.setFieldValue("zip", res?.data?.address[0]?.zipCode);
          setselectAdressId(res?.data?.address[0]?._id);
          console.log("id--->", res?.data?.address[0]?._id);
        });
      })
      .cathc((e) => {
        enqueueSnackbar(e?.message, { variant: "error" });
      });
  };

  console.log("checkoutAddress: ", checkoutAddress);
  useEffect(() => {
    console.log("defaultAddress", defaultAddress);
    if (defaultAddress && defaultAddress !== "") {
      formik.setFieldValue("address", defaultAddress?.address);
      formik.setFieldValue("addressLine1", defaultAddress?.addressLine1);
      formik.setFieldValue("city", defaultAddress?.city);
      formik.setFieldValue("state", defaultAddress?.state);
      formik.setFieldValue("zipCode", defaultAddress?.zipCode);
      formik.setFieldValue("country", defaultAddress?.country);
      console.log("dcheckoutAddress: ", checkoutAddress);
    }
  }, [defaultAddress]);

  const setActiveFun = () => {
    console.log("checkoutAddress: ", checkoutAddress);
    if (!checkoutAddress?.firstName || checkoutAddress?.firstName === "") {
      setCheckoutInputError({ ...checkoutInputError, firstName: true });
      // return;
    }
    console.log(
      '!checkoutAddress?.lastName || checkoutAddress?.lastName === "": ',
      !checkoutAddress?.lastName || checkoutAddress?.lastName === ""
    );
    if (!checkoutAddress?.lastName || checkoutAddress?.lastName === "") {
      setCheckoutInputError({ ...checkoutInputError, lastName: true });
      // return;
    }
    if (!checkoutAddress?.address || checkoutAddress?.address === "") {
      setCheckoutInputError({ ...checkoutInputError, address: true });
      // return;
    }
    console.log("!checkoutAddress?.city: ", !checkoutAddress?.city);
    if (!checkoutAddress?.city || checkoutAddress?.city === "") {
      setCheckoutInputError({ ...checkoutInputError, city: true });
      // return;
    }
    if (!checkoutAddress?.state || checkoutAddress?.state === "") {
      setCheckoutInputError({ ...checkoutInputError, state: true });
      // return;
    }
    console.log("!checkoutAddress?.zipCode: ", !checkoutAddress?.zipCode);
    if (!checkoutAddress?.zipCode || checkoutAddress?.zipCode === "") {
      setCheckoutInputError({ ...checkoutInputError, zipCode: true });
      // return;
    }
    if (!checkoutAddress?.country || checkoutAddress?.country === "") {
      setCheckoutInputError({ ...checkoutInputError, country: true });
      // return;
    }
    if (!checkoutAddress?.email || checkoutAddress?.email === "") {
      setCheckoutInputError({ ...checkoutInputError, email: true });
      // return;
    }
    // if (!checkoutAddress?.companyName) {
    //   setCheckoutInputError({ ...checkoutInputError, companyName: true });
    //   return;
    // }
    if (!checkoutAddress?.phone || checkoutAddress?.phone === "") {
      setCheckoutInputError({ ...checkoutInputError, phone: true });
      // return;
    }
    if (
      Object.values(checkoutInputError).map((key) => {
        console.log("key: ", key);
        if (key === true) {
          return true;
        }
      })
    ) {
      return;
    }
    if (
      checkoutAddress?.addressLine1 === "" ||
      checkoutAddress?.addressLine1 === null
    ) {
      delete input?.addressLine1;
    }

    console.log(checkoutAddress, checkoutInputError);
    setActiveKey("delivery");
    handleCalculateShipping();
  };
  console.log("checkoutInputError: ", checkoutInputError);

  //REGISTRATION MODULE
  const [checked, setChecked] = useState(false);

  const toggleValue = () => {
    setChecked((prevValue) => !prevValue);
  };

  const [inputReg, setInputReg] = useState({
    name: false,
    email: false,
    password: false,
    phoneNumber: false,
  });
  const [inputR, setInputR] = useState({
    name: "",
    email: "",
    password: "",
    phoneNumber: 0,
  });

  const tabs = {
    REGISTER: "REGISTER",
    VERIFY: "VERIFY",
  };

  const [activeTab, setactiveTab] = useState(tabs.REGISTER);
  const [showRegisterPassword, setRegisterPassword] = useState(false);

  const onRegchange = (e) => {
    console.log("e: ", e);
    setInputR({ ...inputR, [e.target.name]: e.target.value });
    setInputReg({ ...inputReg, [e.target.name]: false });
  };

  function emailValidation(body) {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(body) === false) {
      return false;
    }
    return true;
  }

  const [loadingReg, setloadingReg] = useState(false);

  const registerForm = (e) => {
    e.preventDefault();
    if (!inputR?.name) {
      setInputReg({ ...inputReg, name: true });
      // enqueueSnackbar("Please enter your name");
      return;
    }
    if (!inputR?.phoneNumber) {
      setInputReg({ ...inputReg, phoneNumber: true });
      // enqueueSnackbar("Please enter your name");
      return;
    } else {
      setInputReg({ ...inputReg, phoneNumber: false });
    }
    if (!inputR?.email) {
      // enqueueSnackbar("Please enter email");
      setInputReg({ ...inputReg, email: true });
      return;
    }
    if (!emailValidation(inputR?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputReg({ ...inputReg, email: true });
      return;
    }
    if (!inputR?.password) {
      // enqueueSnackbar("Please enter your password");
      setInputReg({ ...inputReg, password: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("name", inputR.name);
    submitForm.append("email", inputR.email);
    submitForm.append("password", inputR.password);
    submitForm.append("mobile", inputR.phoneNumber);
    setloadingReg(true);
    register(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          dispatch(actions.setUser(res?.data?.userData));
          dispatch(actions.setToken(res?.data?.token));
          localStorage.setItem("authenticated", true);
          localStorage.setItem("token", res?.data?.token);
          setactiveTab(tabs.VERIFY);
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setloadingReg(false);
      });
  };

  const [otp, setOtp] = useState(null);
  const [otpError, setOtpError] = useState(false);

  const resendOtpFun = (e) => {
    e.preventDefault();
    if (!inputR?.email) {
      enqueueSnackbar("Please enter email");
      return;
    }
    if (!emailValidation(inputR?.email)) {
      enqueueSnackbar("Please enter valid email");
      return;
    }
    const submitForm = new FormData();
    submitForm.append("email", inputR.email);
    setloadingReg(true);
    resendOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message);
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message);
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message);
      })
      .finally((res) => {
        setloadingReg(false);
      });
  };

  const sendOtpReg = (e) => {
    e.preventDefault();
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true);
      return;
    }
    const submitForm = new FormData();
    submitForm.append("code", otp);
    console.log("send otp");
    setloadingReg(true);
    verifyOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          setChecked(false);
          setTrigger(!trigger);
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setOtp("");
        setloadingReg(false);
      });
  };
  console.log("nonce: ", nonce);
  const handleOrder = () => {
    let body = {
      shippingType: "Delivery",
      paymentMode,
    };
    if (paymentMode === "BRAINTREE") {
      if (!nonce || nonce === null) {
        enqueueSnackbar("Please fill right card details.", {
          variant: "error",
        });
        return;
      }
      body.paymentNonce = nonce;
    }
    if (user?.loggedIn) {
      let cartIdArr = cart.map((obj) => obj?._id);
      body.cartIdArr = cart;
    } else {
      // body.personalData = personalData;
      // body.email = personalData?.email;
      body.cartIdArr = cart;
    }
    if (!checkoutAddress.address || checkoutAddress?.address === "") {
      enqueueSnackbar("Please select the address to proceed further", {
        variant: "error",
      });
      return;
    }
    if (!checkoutAddress?.country || checkoutAddress?.country === "") {
      enqueueSnackbar("Please select the country to proceed further", {
        variant: "error",
      });
      return;
    }
    if (!checkoutAddress?.zipCode || checkoutAddress?.zipCode === "") {
      enqueueSnackbar("Please enter ZIP to proceed further", {
        variant: "error",
      });
      return;
    }
    if (!checkoutAddress?.city || checkoutAddress?.city === "") {
      enqueueSnackbar("Please enter city to proceed further", {
        variant: "error",
      });
      return;
    }
    if (couponCodeData && couponCodeData?.code) {
      body.promoCode = couponCodeData?.code;
    }
    // if (!formik.isValid) {
    //   enqueueSnackbar(
    //     `Please enter ${
    //       shippingType !== "Delivery" ? "billing" : "shipping"
    //     } address`,
    //     { variant: "error" }
    //   );
    //   return;
    // } else {
    checkoutAddress.countryCode = state.filter(
      (obj) => obj?.label === checkoutAddress.country
    )[0]?.value;
    checkoutAddress.stateCode = states.getStateCodeByStateName(
      checkoutAddress?.state
    );
    body.shippingAddress = checkoutAddress;
    body.billingAddress = checkoutAddress;
    // }
    let weight = 0;
    cart?.map((obj) => {
      weight = weight + obj.product?.weight;
    });
    body.weight = weight;
    if (!shippingData) {
      enqueueSnackbar("Please select shipping option to proceed further.", {
        variant: "error",
      });
      return;
    }
    body.shippingData = shippingData;
    if (taxData) {
      let taxAmount =
        (taxData?.tax_rate * total) / 100
          ? (taxData?.tax_rate * total) / 100
          : null;
      if (taxAmount !== null) {
        body.taxAmount = taxAmount;
      }
    }
    setApiCall(true);
    placeOrder(body)
      .then((res) => {
        setApiCall(false);
        console.log("res: ", res);
        if (res.status && res.status === true) {
          if (res?.data && paymentMode === "PAYPAL") {
            window.location.href = res.data;
          } else {
            enqueueSnackbar(res?.message, { variant: "success" });
            dispatch(productActions.setOrderConfirmation(res?.data));
            dispatch(productActions.setCartData([]));
            navigate("/thank-you/" + res.data?._id);
            setShippingData(null);
            // setError([]);
            setTaxData(null);
            setUpdateCart(true);
            setCouponCode("");
            setCouponCodeErr("");
            setCouponCodeData(null);
          }
        } else {
          // Navigate("/order-cancle");
          enqueueSnackbar(res?.message, { variant: "error" });
        }
      })
      .catch((err) => {
        setApiCall(false);
        console.log("err: ", err, err.response);
        if (
          typeof err?.response?.data?.message === "string" &&
          err?.response?.data?.message
        ) {
          enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
        }
        if (err?.response?.status === 410) {
          // setError(err?.response?.data?.data);
        }
      });
  };
  useEffect(() => {
    if (paymentMode === "BRAINTREE") {
      let body = {
        firstName: checkoutAddress.firstName,
        lastName: checkoutAddress.lastName,
        email: checkoutAddress.email,
        phone: checkoutAddress.phone,
        company:
          checkoutAddress.companyName && checkoutAddress.companyName !== ""
            ? checkoutAddress.companyName
            : "BBR",
      };
      getCustomerData(body).then((res) => {
        console.log("res: ", res);
        if (res.status) {
          setBrainTreeData(res.data.clientToken);
        }
      });
    } else {
      setBrainTreeData(null);
    }
  }, [paymentMode]);

  const CouponCodeBtn = ({ eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => { });

    return (
      <p>
        <span>Have a coupon? </span>
        <span className="text-black" id="couponCodeDisplayButton" onClick={decoratedOnClick}>
          Click here to enter your code
        </span>
      </p>
    );
  };
  const updateEstimateValue = () => {
    let estimateValue = total;
    if (shippingData) {
      estimateValue = shippingData?.shipmentCost + total;
    }
    if (shippingData && taxData) {
      estimateValue = (
        (total.toFixed(2) >= 40 && checkoutAddress.country === "United States"
          ? shippingData ? shippingData?.freeShippingOption ? 0 : shippingData?.shipmentCost : shippingData?.shipmentCost
          : shippingData?.shipmentCost) +
        total +
        (total * taxData?.tax_rate) / 100
      ).toFixed(2);
    }
    if (couponCodeData) {
      if (couponCodeData?.type === "percentage") {
        estimateValue =
          (estimateValue * (100 - couponCodeData?.discountInRate)) / 100;
      }
      if (couponCodeData?.type === "flat") {
        if (estimateValue >= couponCodeData?.discountInAmount) {
          estimateValue = estimateValue - couponCodeData?.discountInAmount;
        } else {
          estimateValue = 0;
        }
      }
      if (couponCodeData?.type === "free-delivery") {

        if (shippingData) {
          estimateValue = (
            total +
            (total * taxData?.tax_rate) / 100
          ).toFixed(2);

        } else {
          estimateValue = (
            total
          ).toFixed(2);
        }
      }
    }
    console.log('estimateValue: ', +estimateValue);
    return (+estimateValue).toFixed(2);
  };
  return (
    <section className="checkout ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>Checkout | Brow Bar By Reema</title>
      </Helmet>

      <div className="container">
        <Tab.Container
          id="checkout-tabs"
          defaultActiveKey="shipping"
          activeKey={activeKey}
        >
          <Row>
            <Col xl="12" sm="12">
              <Nav variant="pills" className="checkout-tabnames">
                <Nav.Item className="checkout-tabnames-item">
                  <Nav.Link eventKey="shipping">Shipping</Nav.Link>
                </Nav.Item>
                <Nav.Item className="checkout-tabnames-item">
                  <Nav.Link eventKey="delivery">Delivery</Nav.Link>
                </Nav.Item>
                <Nav.Item className="checkout-tabnames-item">
                  <Nav.Link eventKey="review">Review</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item className="checkout-tabnames-item">
                  <Nav.Link eventKey="complete">Complete</Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
          </Row>
          <Row className="justify-content-between mt-4">
            <Col lg="7" sm="12">
              <Tab.Content>
                <Tab.Pane eventKey="shipping">
                  <div className="shipping-address">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5>Enter your shipping address</h5>
                      {user?.loggedIn ? <Button
                        className="add-new-address-btn"
                        onClick={() => {
                          setAddressModal(true);
                          setModalState("Add");
                          setInput({
                            ...input,
                            address: "",
                            addressLine1: "",
                            city: "",
                            state: "",
                            zipCode: "",
                            country: "",
                          });
                        }}
                      >
                        Add New Address
                      </Button> : null}
                    </div>
                    <Row className="mt-1">
                      {!loading ? (
                        userData?.address?.map((data, i) => {
                          return (
                            <Col lg="4" sm="12">
                              <div
                                // className="address-box selected"
                                className={`address-box  ${selectAdressId === data?._id ||
                                  selectAdressId === i
                                  ? "selected"
                                  : ""
                                  }`}
                                onClick={() => {
                                  if (data?._id === selectAdressId) {
                                    setselectAdressId(null);
                                    setDefaultAddress(null);
                                    formik.setFieldValue("address", "");
                                    formik.setFieldValue("addressLine1", "");
                                    formik.setFieldValue("city", "");
                                    formik.setFieldValue("state", "");
                                    formik.setFieldValue("zipCode", "");
                                    formik.setFieldValue("country", "");
                                    return;
                                  }
                                  setselectAdressId(data?._id);
                                  setDefaultAddress(data);
                                  // setTaxData(null);
                                  // setShippingOptions(null);
                                }}
                              >
                                <div className="address-details">
                                  <p>
                                    {data?.address &&
                                      data?.address?.split(",")[0]}
                                  </p>
                                  {data?.addressLine1 && (
                                    <p>{data?.addressLine1}</p>
                                  )}
                                  <div className="address">
                                    <p>{data?.city}</p>
                                    <p>
                                      {data?.state}, {data?.zipCode}
                                    </p>
                                    <p>{data?.country}</p>
                                  </div>
                                  <div className="actions">
                                    <Button
                                      className="edit-btn"
                                      onClick={() => {
                                        setAddressModal(true);
                                        setModalState("Edit");
                                        setAddressId(data?._id);
                                        setInput({
                                          ...input,
                                          address: data?.address,
                                          addressLine1: data?.addressLine1,
                                          city: data?.city,
                                          state: data?.state,
                                          zipCode: data?.zipCode,
                                          country: data?.country,
                                        });
                                      }}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      className="delete-btn"
                                      onClick={() => {
                                        deleteAdressFun(data?._id);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </div>
                                <div className="selected-icon">
                                  <img
                                    src={require("../../assets/icons/check.png")}
                                    width="25"
                                    alt="Address Selected"
                                  />
                                </div>
                              </div>
                            </Col>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "12vh",
                          }}
                        >
                          <SpinnerComponent />
                        </div>
                      )}
                    </Row>
                    <form
                      className="shipping-address-details"
                      onSubmit={formik.handleSubmit}
                    >
                      <Row className="mt-2">
                        <Col lg="6" sm="12">
                          <div className="form-group">
                            <input
                              type="text"
                              name="firstName"
                              id="firstName"
                              className="form-control"
                              placeholder="First Name"
                              onChange={formik.handleChange}
                              value={formik.values.firstName}
                            />
                          </div>
                          {!formik.values.firstName &&
                            formik.errors.firstName &&
                            formik.touched.firstName && (
                              <small
                                className="validation-error"
                                style={{ color: "red" }}
                              >
                                {formik.errors.firstName}
                              </small>
                            )}
                        </Col>
                        <Col lg="6" sm="12">
                          <div className="form-group">
                            <input
                              type="text"
                              name="lastName"
                              id="lastName"
                              className="form-control"
                              placeholder="Last Name"
                              onChange={(e) => {
                                setUserData({
                                  ...userData,
                                  lastName: e.target.value,
                                });
                                formik.handleChange(e);
                              }}
                              value={formik.values.lastName}
                            />
                          </div>
                          {!formik.values.lastName &&
                            formik.errors.lastName &&
                            formik.touched.lastName && (
                              <small
                                className="validation-error"
                                style={{ color: "red" }}
                              >
                                {formik.errors.lastName}
                              </small>
                            )}
                        </Col>
                        <Col lg="12" sm="12">
                          <div className="form-group">
                            <input
                              type="text"
                              name="companyName"
                              id="companyName"
                              className="form-control"
                              placeholder="Company Name"
                              onChange={(e) => {
                                setUserData({
                                  ...userData,
                                  companyName: e.target.value,
                                });
                                formik.handleChange(e);
                              }}
                              value={formik.values.companyName}
                            />
                          </div>
                          {formik.errors.companyName &&
                            formik.touched.companyName && (
                              <small
                                className="validation-error"
                                style={{ color: "red" }}
                              >
                                {formik.errors.companyName}
                              </small>
                            )}
                        </Col>
                        <Col lg="12" sm="12">
                          <div className="form-group">
                            <Autocomplete
                              placeholder="Address line 1"
                              className="form-control"
                              apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                              options={{
                                types: ["address"],
                              }}
                              onChange={(e) => {
                                console.log("address change ", e.target.value);
                                formik.setFieldValue("address", e.target.value);
                              }}
                              onPlaceSelected={(place) => {
                                console.log(
                                  "checkoutAddress0: ",
                                  checkoutAddress
                                );

                                console.log(place);
                                let address = "";

                                console.log(
                                  "address",
                                  place?.formatted_address
                                );

                                place?.address_components?.map((data) => {
                                  /* COUNTRY */
                                  if (data?.types?.includes("street_number")) {
                                    address = address + data?.long_name + " ";
                                  }
                                  if (data?.types?.includes("route")) {
                                    address = address + data?.long_name + " ";
                                  }

                                  if (data?.types?.includes("country")) {
                                    checkoutAddress.country = data?.long_name;
                                    // setInput(input);
                                    console.log("input: ", input);

                                    formik.setFieldValue(
                                      "country",
                                      data?.long_name
                                    );
                                    console.log("Country", data?.long_name);
                                  }

                                  /* STATE */
                                  if (
                                    data?.types?.includes(
                                      "administrative_area_level_1"
                                    )
                                  ) {
                                    formik.setFieldValue(
                                      "state",
                                      data?.long_name
                                    );
                                    console.log("State", data?.long_name);
                                  }

                                  /* CITY */
                                  if (data?.types?.includes("locality")) {
                                    formik.setFieldValue(
                                      "city",
                                      data?.long_name
                                    );
                                  }

                                  /* ZIPCODE */
                                  if (data?.types?.includes("postal_code")) {
                                    formik.setFieldValue(
                                      "zipCode",
                                      data?.long_name
                                    );
                                  }
                                  formik.setFieldValue("address", address);
                                  // checkoutAddress.address = address;
                                  console.log(
                                    "checkoutAddress: ",
                                    checkoutAddress
                                  );

                                  // });
                                  console.log(
                                    "checkoutAddress:1 ",
                                    checkoutAddress
                                  );
                                });
                              }}
                              value={formik.values.address}
                            />
                          </div>
                          {!formik.values.address &&
                            formik.errors.address &&
                            formik.touched.address && (
                              <small
                                className="validation-error"
                                style={{ color: "red" }}
                              >
                                {formik.errors.address}
                              </small>
                            )}
                        </Col>
                        {/* <Col lg="12" sm="12">
                          <div className="form-group">
                            <input
                              type="text"
                              id="address"
                              name="address"
                              className="form-control"
                              onChange={formik.handleChange}
                              value={formik.values.address}
                              placeholder="Address line 1"
                            />
                          </div>
                          {!formik.values.address &&
                            formik.errors.address &&
                            formik.touched.address && (
                              <small
                                className="validation-error"
                                style={{ color: "red" }}
                              >
                                {formik.errors.address}
                              </small>
                            )}
                        </Col> */}
                        <Col lg="12" sm="12">
                          <div className="form-group">
                            <input
                              type="text"
                              id="addressLine1"
                              name="addressLine1"
                              onChange={formik.handleChange}
                              value={formik.values.addressLine1}
                              className="form-control"
                              placeholder="Address line 2 (optional)"
                            />
                          </div>
                          {!formik.values.addressLine1 &&
                            formik.errors.addressLine1 &&
                            formik.touched.addressLine1 && (
                              <small
                                className="validation-error"
                                style={{ color: "red" }}
                              >
                                {formik.errors.addressLine1}
                              </small>
                            )}
                        </Col>
                        <Col lg="6" sm="12">
                          <div className="form-group">
                            <input
                              type="text"
                              id="city"
                              name="city"
                              onChange={formik.handleChange}
                              value={formik.values.city}
                              className="form-control"
                              placeholder="City"
                            // onChange={(e) => {
                            //   setCheckoutInputError({
                            //     ...checkoutInputError,
                            //     [e.target.name]: false,
                            //   });
                            //   setCheckoutAddress({
                            //     ...checkoutAddress,
                            //     [e.target.name]: e.target.value,
                            //   });
                            // }}
                            />
                          </div>
                          {!formik.values.city &&
                            formik.errors.city &&
                            formik.touched.city && (
                              <small
                                className="validation-error"
                                style={{ color: "red" }}
                              >
                                {formik.errors.city}
                              </small>
                            )}
                        </Col>
                        <Col lg="6" sm="12">
                          <div className="form-group">
                            <input
                              type="text"
                              name="state"
                              id="state"
                              className="form-control"
                              placeholder="State"
                              onChange={formik.handleChange}
                              value={formik.values.state}
                            />
                          </div>
                          {!formik.values.state &&
                            formik.errors.state &&
                            formik.touched.state && (
                              <small
                                className="validation-error"
                                style={{ color: "red" }}
                              >
                                {formik.errors.state}
                              </small>
                            )}
                        </Col>
                        <Col lg="6" sm="12">
                          <div className="form-group">
                            <input
                              type="number"
                              id="zipCode"
                              name="zipCode"
                              className="form-control"
                              placeholder="Zip code"
                              onChange={formik.handleChange}
                              value={formik.values.zipCode}
                            />
                          </div>
                          {!formik.values.zipCode &&
                            formik.errors.zipCode &&
                            formik.touched.zipCode && (
                              <small
                                className="validation-error"
                                style={{ color: "red" }}
                              >
                                {formik.errors.zipCode}
                              </small>
                            )}
                        </Col>
                        <Col lg="6" sm="12">
                          <div className="form-group">
                            <Select
                              className="basic-single"
                              classNamePrefix="select-control"
                              loadingMessage="Select Country"
                              components={{ Placeholder }}
                              placeholder={"Select Country"}
                              value={
                                formik.values?.country !== "" && {
                                  label: formik.values?.country,
                                  value: state.filter(
                                    (obj) => obj?.label === input.country
                                  )[0]?.value,
                                }
                              }
                              options={state}
                              name="country"
                              onChange={(e) => {
                                formik.setFieldValue("country", e.label);
                              }}
                              styles={{
                                placeholder: (base) => ({
                                  ...base,
                                  fontSize: "1em",
                                  color: "#000",
                                  fontWeight: 400,
                                }),
                              }}
                            />
                          </div>
                          {!formik.values.country &&
                            formik.errors.country &&
                            formik.touched.country && (
                              <small
                                className="validation-error"
                                style={{ color: "red" }}
                              >
                                {formik.errors.country}
                              </small>
                            )}
                        </Col>
                        <Col lg="12" sm="12">
                          <div className="form-group">
                            <input
                              type="email"
                              name="email"
                              id="email"
                              className="form-control"
                              placeholder="Email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                          </div>
                          {formik.errors.email && formik.touched.email && (
                            <small
                              className="validation-error"
                              style={{ color: "red" }}
                            >
                              {formik.errors.email}
                            </small>
                          )}
                        </Col>
                        <Col lg="12" sm="12">
                          <PhoneInput
                            placeholder="Phone"
                            defaultCountry="US"
                            className="phone-number-select"
                            onChange={(e) => {
                              formik.setFieldValue("phone", e);
                            }}
                            value={formik.values.phone}
                          />
                          {formik.errors.phone && formik.touched.phone && (
                            <small
                              className="validation-error"
                              style={{ color: "red" }}
                            >
                              {formik.errors.phone}
                            </small>
                          )}
                        </Col>
                        {!user?.loggedIn && (
                          <Col lg="12" sm="12">
                            <div className="create-account-box">
                              <input
                                type="checkbox"
                                className="radio-control"
                                name="createAcc"
                                id="createAcc"
                                onChange={toggleValue}
                              />
                              <label
                                className="account-text"
                                htmlFor="createAcc"
                              >
                                <h5>Create Account</h5>
                                <p>
                                  Save your information for future checkouts
                                </p>
                              </label>
                            </div>
                          </Col>
                        )}

                        <Row>
                          {checked ? (
                            loadingReg ? (
                              <Col
                                lg="12"
                                sm="12"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <SpinnerComponent />
                              </Col>
                            ) : activeTab === tabs.REGISTER ? (
                              <div className="login_wrap">
                                <h5 className="mb-30 mt-2">
                                  Create an Account
                                </h5>
                                <form className="login-form">
                                  <Row>
                                    <Col lg="6" sm="12">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          name="name"
                                          className="form-control"
                                          placeholder="Name"
                                          value={inputR?.name}
                                          onChange={onRegchange}
                                        />
                                        <small
                                          className="validation-error"
                                          style={{ color: "red" }}
                                        >
                                          {inputReg?.name
                                            ? "Name is required"
                                            : ""}
                                        </small>
                                      </div>
                                    </Col>
                                    <Col lg="6" sm="12">
                                      <div className="form-group">
                                        <PhoneInput
                                          placeholder="Phone"
                                          defaultCountry="US"
                                          className="phone-number-select"
                                          value={inputR?.phoneNumber}
                                          onChange={(e) => {
                                            setInputR({
                                              ...inputR,
                                              phoneNumber: e,
                                            });
                                          }}
                                        />
                                        <small
                                          className="validation-error"
                                          style={{ color: "red" }}
                                        >
                                          {inputReg?.phoneNumber
                                            ? "Phone number is required"
                                            : ""}
                                        </small>
                                      </div>
                                    </Col>
                                    <Col lg="6" sm="12">
                                      <div className="form-group">
                                        <input
                                          type="email"
                                          name="email"
                                          className="form-control"
                                          placeholder="Email"
                                          value={inputR?.email}
                                          onChange={onRegchange}
                                        />
                                        <small
                                          className="validation-error"
                                          style={{ color: "red" }}
                                        >
                                          {inputReg?.email
                                            ? "email is required"
                                            : ""}
                                        </small>
                                      </div>
                                    </Col>
                                    <Col lg="6" sm="12">
                                      <div className="form-group">
                                        <input
                                          type={
                                            showRegisterPassword
                                              ? "text"
                                              : "password"
                                          }
                                          name="password"
                                          className="form-control"
                                          placeholder="Password"
                                          value={inputR?.password}
                                          onChange={onRegchange}
                                        />
                                        <Button
                                          className="password-icon"
                                          onClick={() =>
                                            setRegisterPassword(
                                              !showRegisterPassword
                                            )
                                          }
                                        >
                                          {showRegisterPassword ? (
                                            <AiFillEyeInvisible />
                                          ) : (
                                            <AiFillEye />
                                          )}
                                        </Button>
                                        <small
                                          className="validation-error"
                                          style={{ color: "red" }}
                                        >
                                          {inputReg?.password
                                            ? "password is required"
                                            : ""}
                                        </small>
                                      </div>
                                    </Col>
                                  </Row>
                                  <button
                                    className="login-btn"
                                    onClick={registerForm}
                                  >
                                    Register
                                  </button>
                                </form>
                              </div>
                            ) : (
                              <div className="login_wrap">
                                <h5 className="mb-30 mt-2">Verify Email</h5>

                                <form className="login-form">
                                  <div className="form-group">
                                    <input
                                      value={otp}
                                      onChange={(e) => {
                                        setOtpError(false);
                                        setOtp(e.target.value);
                                      }}
                                      type="number"
                                      name="otp"
                                      className="form-control"
                                      placeholder="Enter 6 digit OTP"
                                    />
                                    <small
                                      className="validation-error"
                                      style={{ color: "red" }}
                                    >
                                      {otpError ? "Please enter OTP" : ""}
                                    </small>
                                    <h5
                                      style={{
                                        fontSize: "15px",
                                        marginTop: "10px",
                                        textAlign: "end",
                                      }}
                                    >
                                      Didn't receive code?
                                      <span
                                        onClick={resendOtpFun}
                                        style={{
                                          fontSize: "15px",
                                          cursor: "pointer",
                                          marginLeft: "5px",
                                          textDecoration: "underline",
                                        }}
                                      >
                                        Request again
                                      </span>
                                    </h5>
                                  </div>
                                  <button
                                    className="login-btn"
                                    onClick={sendOtpReg}
                                  >
                                    Verify Email
                                  </button>
                                </form>
                              </div>
                            )
                          ) : null}
                        </Row>
                        <Col lg="6" sm="12">
                          <Button
                            className="checkout-btn"
                            type="submit"
                          // onClick={setActiveFun}
                          >
                            Save &amp; Continue
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="delivery">
                  <div className="shipping-address">
                    <h5>Select a delivery method</h5>
                    <div className="entered-shipping-details">
                      <h5>Ship to:</h5>
                      <div className="entered-details">
                        <p>
                          {checkoutAddress.firstName +
                            " " +
                            checkoutAddress.lastName}
                        </p>
                        <p>{checkoutAddress.phone}</p>
                        <p>
                          {checkoutAddress.address}, {checkoutAddress.city},
                          {checkoutAddress.state}, {checkoutAddress.zipCode},
                          {checkoutAddress.country}
                        </p>
                        <p>{checkoutAddress.email}</p>
                      </div>
                      <Link
                        className="edit-link"
                        onClick={() => setActiveKey("shipping")}
                      >
                        Edit Shipping Address
                      </Link>
                    </div>

                    <h5 className="mt-3">Delivery Options</h5>
                    <form className="delivery-options">
                      <Row>
                        {loadingShipping ? (
                          <Col lg="12" sm="12">
                            {" "}
                            <Spinner />
                          </Col>
                        ) : (
                          shippingOptions?.length > 0 &&
                          shippingOptions?.map((option, i) => {
                            return (
                              <Col lg="12" sm="12" key={i}>
                                <div className="delivery-box">
                                  <input
                                    type="radio"
                                    name="shipment-type-ups"
                                    className="radio-control"
                                    value={i}
                                    id={"ups-ground" + (i + 1)}
                                    onChange={changeShippingUPS}
                                  />
                                  <label
                                    className="account-text"
                                    htmlFor="shipping-1"
                                  >
                                    <h5> {option?.serviceName}</h5>
                                    <p>${total.toFixed(2) > 40 && checkoutAddress.country === "United States" ? option?.freeShippingOption ? '0.00' : option?.shipmentCost.toFixed(2) : option?.shipmentCost.toFixed(2)}</p>
                                  </label>
                                </div>
                              </Col>
                            );
                          })
                        )}

                        <Col lg="12" sm="12">
                          <Button
                            className="checkout-btn"
                            disabled={loadingShipping}
                            onClick={() => setActiveKey("review")}
                          >
                            Save &amp; Continue
                          </Button>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="review">
                  <h5>Review &amp; confirm</h5>

                  <div className="entered-shipping-details">
                    <h5>Ship to:</h5>
                    <div className="entered-details">
                      <p>
                        {checkoutAddress.firstName +
                          " " +
                          checkoutAddress.lastName}
                      </p>
                      <p>{checkoutAddress.phone}</p>
                      <p>
                        {checkoutAddress.address}, {checkoutAddress.city},
                        {checkoutAddress.state}, {checkoutAddress.zipCode},
                        {checkoutAddress.country}
                      </p>
                      <p>{checkoutAddress.email}</p>
                    </div>
                    <Link
                      className="edit-link"
                      onClick={() => setActiveKey("shipping")}
                    >
                      Edit Shipping Address
                    </Link>
                  </div>
                  <h5 className="mt-3">Delivery Options</h5>
                  <Row>
                    <Col lg="12" sm="12">
                      <div className="delivery-box">
                        <label
                          className="account-text ml-0"
                          htmlFor="shipping-1"
                        >
                          <h5>{shippingData?.serviceName}</h5>
                          <p>
                            $
                            {total.toFixed(2) < 40
                              ? shippingData?.shipmentCost.toFixed(2)
                              : shippingData ? shippingData?.freeShippingOption ? "0.00" : shippingData?.shipmentCost.toFixed(2) : shippingData?.shipmentCost.toFixed(2)}
                          </p>
                        </label>
                      </div>
                      <Link
                        className="edit-link"
                        onClick={() => setActiveKey("delivery")}
                      >
                        Edit Delivery Option
                      </Link>
                    </Col>
                  </Row>
                  <h5 className="mt-3">Payment</h5>
                  <Row>
                    {/* STRIPE PAYMENT FORM */}
                    <Col lg="12" sm="12">
                      <div className="delivery-box">
                        <input
                          type="radio"
                          name="payment-type"
                          className="radio-control"
                          value={"BRAINTREE"}
                          onChange={changePaymentType}
                        />
                        <label className="account-text" htmlFor="shipping-1">
                          <h5>Pay with Debit/Credit Card</h5>
                        </label>
                      </div>
                    </Col>
                    <Col lg="12" sm="12">
                      <div className="delivery-box">
                        <input
                          type="radio"
                          name="payment-type"
                          className="radio-control"
                          value={"PAYPAL"}
                          defaultChecked
                          onChange={changePaymentType}
                        />
                        <label className="account-text" htmlFor="shipping-1">
                          <h5>Paypal</h5>
                        </label>
                      </div>
                    </Col>
                    {paymentMode === "BRAINTREE" && BrainTreeData && (
                      <Col lg="12" sm="12">
                        <BrainTree
                          CLIENT_AUTHORIZATION={BrainTreeData}
                          setNonce={(e) => setNonce(e)}
                        />
                      </Col>
                    )}
                    <Col lg="12" sm="12">
                      <Button
                        className="checkout-btn"
                        // onClick={() => setActiveKey("complete")}
                        onClick={handleOrder}
                        disabled={apiCall}
                      >
                        Place Order
                      </Button>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="complete">
                  <div className="order-complete">
                    <h4>Thank you, your order has been placed.</h4>
                    <p>
                      Your order confirmation and delivery details have been
                      sent to <b>darsh@roundtechsquare.com</b>
                    </p>
                    <div className="order">
                      <p>Order No.</p>
                      <h5>012345ABCDEF</h5>
                    </div>
                    <div className="order mt-2">
                      <p>Order Date</p>
                      <h5>Jan 01, 2023</h5>
                    </div>
                    <div className="entered-shipping-details">
                      <h5>Ship to:</h5>
                      <div className="entered-details">
                        <p>Darsh Shah</p>
                        <p>+1 424 (259)-1872</p>
                        <p>
                          9903 Brunswick Way, San Ramon, CA, 94583, United
                          States
                        </p>
                        <p>darsh@roundtechsquare.com</p>
                      </div>
                    </div>
                    <div className="order mt-2">
                      <p>Delivery Detail</p>
                      <h5>USPS First Class Mail - Package ($10.89)</h5>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
            <Col lg="4" sm="12">
              <Row>
                <Col lg="12" sm="12">
                  <h5 className="summary-heading">Summary</h5>
                  <div className="order-summary">
                    <div className="items">
                      <p>Items ({cart.length})</p>
                      <p>${total.toFixed(2)}</p>
                    </div>
                    <hr />
                    <div className="items">
                      <p>
                        Shipping Charge&nbsp;
                        {total.toFixed(2) >= 40 && checkoutAddress.country === "United States" ?
                          shippingData ? shippingData?.freeShippingOption ? (<p>Free Shipping</p>) : couponCodeData ? couponCodeData?.type === "free-delivery" ? (<p>Free Shipping</p>) : '' : '' : ''
                          : ''}

                      </p>

                      <p>
                        $
                        {shippingData &&
                          total.toFixed(2) >= 40 &&
                          checkoutAddress.country === "United States"
                          ? shippingData ? shippingData?.freeShippingOption ? "0.00" : shippingData?.shipmentCost.toFixed(2) : shippingData?.shipmentCost.toFixed(2)
                          : shippingData
                            ? shippingData?.shipmentCost.toFixed(2)
                            : "0.00 "}
                      </p>
                    </div>
                    <hr />
                    <div className="items">
                      <p>Estimated Tax</p>
                      <p>
                        $
                        {shippingData && taxData
                          ? (
                            (total * taxData?.tax_rate) /
                            100
                          ).toFixed(2)
                          : "0.00"}
                      </p>
                    </div>
                    <hr />

                    {couponCodeData && (
                      <>
                        <div className="coupon-code-summary w-100">
                          <Row className="w-100 align-items-center">
                            <Col md="9">
                              <div
                                className="d-flex"
                                style={{ marginBottom: "0px" }}
                              >
                                <h5
                                  style={{
                                    marginBottom: "0px",
                                    fontWeight: "700",
                                  }}
                                >
                                  {couponCodeData?.code}
                                </h5>{" "}
                                &nbsp;
                                <p
                                  style={{ marginTop: "8px" }}
                                  className="justify-content-between"
                                >
                                  applied
                                </p>
                              </div>
                              <p style={{ marginBottom: "0px" }}>
                                -$
                                {(
                                  (shippingData && taxData
                                    ? (
                                      (total.toFixed(2) >= 40 &&
                                        checkoutAddress.country ===
                                        "United States"
                                        ? 0
                                        : shippingData?.shipmentCost) +
                                      total +
                                      (total *
                                        taxData?.tax_rate) /
                                      100
                                    ).toFixed(2)
                                    : shippingData
                                      ? (
                                        shippingData?.shipmentCost + total
                                      ).toFixed(2)
                                      : total.toFixed(2)) - updateEstimateValue()
                                ).toFixed(2)}{" "}
                                {couponCodeData?.type === "percentage" && (
                                  <>({couponCodeData?.discountInRate}% Off)</>
                                )}
                              </p>
                            </Col>
                            <Col md="1"></Col>
                            <Col md="2">
                              <p
                                className="text-danger mb-0" style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setCouponCodeData(null);
                                }}
                              >
                                Remove
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                    <div className="items">
                      <p>
                        <b>Estimated Total</b>
                      </p>
                      <p>
                        {couponCodeData && (
                          <span
                            style={{
                              textDecoration: "line-through",
                              marginRight: "5px",
                            }}
                          >
                            $
                            {shippingData && taxData
                              ? (
                                (total.toFixed(2) >= 40 &&
                                  checkoutAddress.country === "United States"
                                  ? 0
                                  : shippingData?.shipmentCost) +
                                total +
                                (total *
                                  taxData?.tax_rate) /
                                100
                              ).toFixed(2)
                              : shippingData
                                ? (shippingData?.shipmentCost + total).toFixed(2)
                                : total.toFixed(2)}
                          </span>
                        )}
                        <b>
                          $
                          {/* {couponCodeData &&
                          (shippingData && taxData
                            ? (
                                (total.toFixed(2) >= 40 &&
                                checkoutAddress.country === "United States"
                                  ? 0
                                  : shippingData?.shipmentCost) +
                                total +
                                (shippingData?.shipmentCost *
                                  taxData?.tax_rate) /
                                  100
                              ).toFixed(2)
                            : shippingData
                            ? (shippingData?.shipmentCost + total).toFixed(2)
                            : total.toFixed(2)) >=
                            couponCodeData?.minAmountToBuy
                            ? couponCodeData?.type === "percentage"
                              ? (
                                  ((shippingData && taxData
                                    ? (
                                        (total.toFixed(2) >= 40 &&
                                        checkoutAddress.country ===
                                          "United States"
                                          ? 0
                                          : shippingData?.shipmentCost) +
                                        total +
                                        (shippingData?.shipmentCost *
                                          taxData?.tax_rate) /
                                          100
                                      ).toFixed(2)
                                    : shippingData
                                    ? (
                                        shippingData?.shipmentCost + total
                                      ).toFixed(2)
                                    : total.toFixed(2)) *
                                    (100 - couponCodeData?.discountInRate)) /
                                  100
                                ).toFixed(2)
                              : couponCodeData?.type === "flat"
                              ? (shippingData && taxData
                                  ? (
                                      (total.toFixed(2) >= 40 &&
                                      checkoutAddress.country ===
                                        "United States"
                                        ? 0
                                        : shippingData?.shipmentCost) +
                                      total +
                                      (shippingData?.shipmentCost *
                                        taxData?.tax_rate) /
                                        100
                                    ).toFixed(2)
                                  : shippingData
                                  ? (
                                      shippingData?.shipmentCost + total
                                    ).toFixed(2)
                                  : total.toFixed(2)) >=
                                shippingData?.discountInAmount
                                ? (
                                    (shippingData && taxData
                                      ? (
                                          (total.toFixed(2) <= 40 &&
                                          checkoutAddress.country ===
                                            "United States"
                                            ? 0
                                            : shippingData?.shipmentCost) +
                                          total +
                                          (shippingData?.shipmentCost *
                                            taxData?.tax_rate) /
                                            100
                                        ).toFixed(2)
                                      : shippingData
                                      ? (
                                          shippingData?.shipmentCost + total
                                        ).toFixed(2)
                                      : total.toFixed(2)) -
                                    shippingData?.discountInAmount
                                  ).toFixed(2)
                                : 0
                              : shippingData && taxData
                              ? (
                                  total +
                                  (shippingData?.shipmentCost *
                                    taxData?.tax_rate) /
                                    100
                                ).toFixed(2)
                              : shippingData
                              ? (0 + total).toFixed(2)
                              : total.toFixed(2)
                            : shippingData && taxData
                            ? (
                                (total.toFixed(2) >= 40 &&
                                checkoutAddress.country === "United States"
                                  ? 0
                                  : shippingData?.shipmentCost) +
                                total +
                                (shippingData?.shipmentCost *
                                  taxData?.tax_rate) /
                                  100
                              ).toFixed(2)
                            : shippingData
                            ? (shippingData?.shipmentCost + total).toFixed(2)
                            : total.toFixed(2)} */}
                          {updateEstimateValue()}
                        </b>
                      </p>
                    </div>
                    {/* <p className="after-pay-link">
                      Or 4 interest free installments of $26.00 with{" "}
                      <b>Afterpay</b>
                    </p> */}
                  </div>
                </Col>
                <Col
                  lg="12"
                  sm="12"
                style={{ display: "none" }}
                className="d-none"
                >
                  <Accordion className="coupon-code-accordion mt-3">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="coupon-code-accordion-header">
                        <CouponCodeBtn eventKey="0" />
                      </Accordion.Header>
                      <Accordion.Body className="coupon-code-accordion-body">
                        <p>If you have coupon code, please apply it below.</p>
                        {/* <form> */}
                        <input
                          type="text"
                          value={couponCode}
                          onChange={(e) => {
                            setCouponCode(e.target.value);
                          }}
                          className="form-control"
                          name="couponCode"
                          placeholder="Enter Coupon Code..."
                        />
                        {couponCodeErr !== "" && (
                          <small
                            className="validation-error "
                            style={{
                              color: "red",
                              marginBottom: "10px",
                              display: "block",
                            }}
                          >
                            {couponCodeErr}
                          </small>
                        )}

                        <Button
                          disabled={codeSubmited}
                          className="coupon-code-btn"
                          onClick={handleCouponCode}
                        >
                          Apply Coupon
                        </Button>
                        {/* </form> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
                <Col lg="12" sm="12" className="mt-3">
                  <h5 className="summary-heading">Items ({cart.length})</h5>
                  {cart &&
                    cart.length > 0 &&
                    cart.map((data) => {
                      return (
                        <div className="items-box">
                          <Row className="mt-1">
                            <Col lg="3" sm="4">
                              <img
                                src={
                                  IMG_URL +
                                  (data?.product?.productImage?.length > 0
                                    ? data?.product?.productImage?.filter(
                                      (img) => img.color === data.color._id
                                    )[0]?.front[0]
                                    : data?.product?.productImage?.front[0])
                                }
                                className="product-img-bought"
                                alt="Bombshell Brow Kit - Medium"
                              />
                            </Col>
                            <Col lg="9" sm="8">
                              <h4 className="product-name">
                                {data?.product?.name}
                              </h4>
                              <p>
                                <b>Color: </b>
                                {data?.color?.name}
                              </p>
                              <p>
                                <b>Quantity: </b>
                                {data?.quantity}
                              </p>
                              <p>
                                <b>Price: </b>${data?.amount}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Tab.Container>

        {/* ADD ADDRESS MODAL */}
        <Modal
          size="lg"
          show={addressModal}
          onHide={() => setAddressModal(!addressModal)}
        >
          <Modal.Header closeButton>
            <Modal.Title className="address-modal-title">
              {modalState} Address
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <Row className="px-2">
                <Col xl="12" sm="12" className="mt-2">
                  <div className="form-group">
                    <Autocomplete
                      className="form-control"
                      apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                      options={{
                        types: ["address"],
                      }}
                      autoComplete="on"
                      // defaultValue={input?.address}
                      value={input?.address}
                      onChange={(e) => {
                        console.log("address change ", e.target.value);
                        setInput({
                          ...input,
                          address: e.target.value,
                        });
                        setInputError({
                          ...inputError,
                          address: false,
                        });
                      }}
                      onPlaceSelected={(place) => {
                        console.log(place);
                        let address = "";

                        place?.address_components?.map((data) => {
                          if (data?.types?.includes("street_number")) {
                            address = address + data?.long_name + " ";
                          }
                          if (data?.types?.includes("route")) {
                            address = address + data?.long_name + " ";
                          }
                          input.address = address;
                          setInput({
                            ...input,
                            address: address,
                          });
                          setInputError({
                            ...inputError,
                            address: false,
                          });
                          /* COUNTRY */
                          if (data?.types?.includes("country")) {
                            input.country = data?.long_name;
                            // setInput(input);
                            console.log("input: ", input);
                            setInputError({
                              ...inputError,
                              country: false,
                            });
                            console.log("Country", data?.long_name);
                          }

                          /* STATE */
                          if (
                            data?.types?.includes("administrative_area_level_1")
                          ) {
                            setInput({
                              ...input,
                              state: data?.long_name,
                            });
                            setInputError({
                              ...inputError,
                              state: false,
                            });
                            input.state = data?.long_name;
                            console.log("State", data?.long_name);
                          }

                          /* CITY */
                          if (data?.types?.includes("locality")) {
                            setInputError({
                              ...inputError,
                              city: false,
                            });
                            input.city = data?.long_name;
                          }

                          /* ZIPCODE */
                          if (data?.types?.includes("postal_code")) {
                            setInput({
                              ...input,
                              zipCode: data?.long_name,
                            });
                            setInputError({
                              ...inputError,
                              zipCode: false,
                            });
                            input.zipCode = data?.long_name;
                          }
                        });
                      }}
                    />
                  </div>
                  <small className="validation-error" style={{ color: "red" }}>
                    {/* {inputError?.address ? "Address line 1 is required" : ""} */}
                  </small>
                </Col>
                {/* <Col xl="12" sm="12">
                  <div className="form-group">
                    <input
                      type="text"
                      id="address"
                      name="address"
                      value={input?.address}
                      className="form-control"
                      placeholder="Address Line 1"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setInputError({
                          ...inputError,
                          [e.target.name]: false,
                        });
                        setInput({
                          ...input,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <small className="validation-error" style={{ color: "red" }}>
                    {inputError?.address ? "Address line 1 is required" : ""}
                  </small>
                </Col> */}
                <Col xl="12" sm="12">
                  <div className="form-group">
                    <input
                      type="text"
                      id="addressLine1"
                      name="addressLine1"
                      value={input?.addressLine1}
                      className="form-control"
                      placeholder="Address Line 2"
                      onChange={(e) => {
                        console.log(e.target.value);
                        setInputError({
                          ...inputError,
                          [e.target.name]: false,
                        });
                        setInput({
                          ...input,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <small className="validation-error" style={{ color: "red" }}>
                    {inputError?.addressLine1
                      ? "Address line 2 is required"
                      : ""}
                  </small>
                </Col>
                <Col xl="6" sm="12">
                  <div className="form-group">
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={input?.city}
                      className="form-control"
                      placeholder="City"
                      onChange={(e) => {
                        setInputError({
                          ...inputError,
                          [e.target.name]: false,
                        });
                        setInput({
                          ...input,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <small className="validation-error" style={{ color: "red" }}>
                    {inputError?.city ? "city 1 is required" : ""}
                  </small>
                </Col>
                <Col xl="6" sm="12">
                  <div className="form-group">
                    <input
                      type="text"
                      id="state"
                      name="state"
                      value={input?.state}
                      className="form-control"
                      placeholder="State"
                      onChange={(e) => {
                        setInputError({
                          ...inputError,
                          [e.target.name]: false,
                        });
                        setInput({
                          ...input,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <small className="validation-error" style={{ color: "red" }}>
                    {inputError?.state ? "state is required" : ""}
                  </small>
                </Col>
                <Col xl="6" sm="12">
                  <div className="form-group">
                    <input
                      type="text"
                      id="zipCode"
                      name="zipCode"
                      value={input?.zipCode}
                      className="form-control"
                      placeholder="Zip code"
                      onChange={(e) => {
                        setInputError({
                          ...inputError,
                          [e.target.name]: false,
                        });
                        setInput({
                          ...input,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <small className="validation-error" style={{ color: "red" }}>
                    {inputError?.zipCode ? "zip Code is required" : ""}
                  </small>
                </Col>
                <Col xl="6" sm="12">
                  <div className="form-group">
                    <Select
                      className="basic-single"
                      classNamePrefix="select-control"
                      loadingMessage="Select Country"
                      components={{ Placeholder }}
                      placeholder={"Select Country"}
                      value={{
                        label: input?.country,
                        value: state.filter(
                          (obj) => obj?.label === input.country
                        )[0]?.value,
                      }}
                      options={state}
                      name="country"
                      onChange={(e) => {
                        setInputError({
                          ...inputError,
                          ["country"]: false,
                        });
                        setInput({
                          ...input,
                          country: e.label,
                        });
                      }}
                      styles={{
                        placeholder: (base) => ({
                          ...base,
                          fontSize: "1em",
                          color: "#000",
                          fontWeight: 400,
                        }),
                      }}
                    />
                  </div>
                  <small
                    className="validation-error-select"
                    style={{ color: "red" }}
                  >
                    {inputError?.country ? "country 1 is required" : ""}
                  </small>
                </Col>
              </Row>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="add-address-btn"
              style={{
                backgroundColor: "#000",
                borderColor: "#000",
              }}
              onClick={addAdress}
            >
              Save Address
            </Button>{" "}
            {/* IF USED FOR CREATING NEW ADDRESS, BUTTON TEXT WILL BE ADD ADDRESS */}
          </Modal.Footer>
        </Modal>
      </div>
    </section>
  );
};

export default Checkout;