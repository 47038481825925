/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  register,
  login,
  resendOtp,
  verifyOtp,
  loginVerifyEmail,
  forgotPassword,
} from "../../service/api";
import { useDispatch } from "react-redux";
import actions from "../../redux/user/actions";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useSnackbar } from "notistack";

const NewAccountPage = () => {
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()
  const [loginInput, setLoginInput] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [inputLogin, setInputLogin] = useState({
    name: false,
    email: false,
    password: false,
  });


  const [phoneNumber, setPhoneNumber] = useState(0);
  const [otp, setOtp] = useState(null);
  const [otpError, setOtpError] = useState(false);

  const loginTabs = {
    LOGIN: "LOGIN",
    VERIFY: "VERIFY",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    SEND_OTP: "SEND_OTP",
    SEND_PASSWORD: "SEND_PASSWORD",
  };

  const [loginActiveTab, setLoginActiveTab] = useState(loginTabs.LOGIN);

  const onLogchange = (e) => {
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value });
    setInputLogin({ ...inputLogin, [e.target.name]: false });
  };

  function emailValidation(body) {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(body) === false) {
      return false;
    }
    return true;
  }

  const [loading, setLoading] = useState(false);
  const loginForm = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!loginInput?.password) {
      setInputLogin({ ...inputLogin, password: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    submitForm.append("password", loginInput.password);
    submitForm.append("role", "63bbbbbd72d26924462631bb");
    setLoading(true);
    login(submitForm)
      .then((res) => {
        if (res?.status) {
          enqueueSnackbar(res?.message, { variant: "success" });
          dispatch(actions.setUser(res?.data?.userData));
          dispatch(actions.setToken(res?.data?.token));

          localStorage.setItem("authenticated", true);
          localStorage.setItem("token", res?.data?.token);

          if (!res?.data?.userData?.isEmailVerified) {
            loginVerifyEmail({ email: loginInput.email }).then((res) => {
              console.log("verify email--->", res);
            });
            setLoginActiveTab(loginTabs.VERIFY);
            return;
          } else {
            dispatch(actions.setLoggedIn(true));
            navigate("/dashboard");
            console.log("navigate--->");
          }
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const resendLoginOtpFun = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    setLoading(true);
    resendOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const sendOtp = (e) => {
    e.preventDefault();
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true);
      return;
    }
    const submitForm = new FormData();
    submitForm.append("code", otp);
    console.log("send otp");
    setLoading(true);
    verifyOtp(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          navigate("/dashboard");
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setOtp("");
        setLoading(false);
      });
  };

  const forgotPasswordFun = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!loginInput?.password) {
      // enqueueSnackbar("Please enter your password");
      setInputLogin({ ...inputLogin, password: true });
      return;
    }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    submitForm.append("password", loginInput.password);
    submitForm.append("role", "63bbbbbd72d26924462631bb");
    setLoading(true);
    forgotPassword(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          setLoginActiveTab(loginTabs.LOGIN);
          setLoginInput({ ...loginInput, name: "", email: "", password: "" });
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message, { variant: "error" });
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const [otpMatch, setOtpMatch] = useState(null);
  const sendOtpToMail = (e) => {
    e.preventDefault();
    if (!loginInput?.email) {
      // enqueueSnackbar("Please enter email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    if (!emailValidation(loginInput?.email)) {
      // enqueueSnackbar("Please enter valid email");
      setInputLogin({ ...inputLogin, email: true });
      return;
    }
    // if (!loginInput?.password) {
    //   // enqueueSnackbar("Please enter your password");
    //   setInputLogin({ ...inputLogin, password: true })
    //   return
    // }

    const submitForm = new FormData();
    submitForm.append("email", loginInput.email);
    // submitForm.append("password", loginInput.password);
    setLoading(true);
    loginVerifyEmail(submitForm)
      .then((res) => {
        console.log("res--->", res);
        if (res?.status === false) {
          enqueueSnackbar(res?.message, { variant: "error" });
        } else if (res?.status === true) {
          enqueueSnackbar(res?.message, { variant: "success" });
          setOtpMatch(res?.data);
          setLoginActiveTab(loginTabs.SEND_OTP);
          // setLoginInput({ ...loginInput, name: '', email: '', password: '' })
        }
      })
      .catch((e) => {
        console.log("error--->", e);
        enqueueSnackbar(e?.response?.data?.message);
      })
      .finally((res) => {
        setLoading(false);
      });
  };

  const verifyOtpToChangePassword = (e) => {
    e.preventDefault();
    if (!otp) {
      // enqueueSnackbar("Please enter 6 digit otp");
      setOtpError(true);
      return;
    }
    if (otp === otpMatch) {
      setLoginActiveTab(loginTabs.SEND_PASSWORD);
      enqueueSnackbar("OTP verified successfully", { variant: "success" });
    } else {
      enqueueSnackbar("The OTP enetered is incorrect", { variant: "error" });
    }
  };

  return (
    <section className="wholesale">
      {/* HELMET */}
      <Helmet>
        <title>Login | Brow Bar by Reema</title>
      </Helmet>

      <div className="container mt-5 mb-5">
        <Row className="wholesale-login">
          {/* <Col lg="6" sm="12">
             
              <div className="login_wrap">
                <h3>Login to your Account</h3>
                <p>
                  If you are a registered user, please enter your email and
                  password.
                </p>
                <form className="login-form">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type={showLoginPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder="Password"
                    />
                    <Button
                      className="password-icon"
                      onClick={() => setShowLoginPassword(!showLoginPassword)}
                    >
                      {showLoginPassword ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </Button>
                  </div>

                  <div className="forgot-pass">
                    <span>Forgot Password ?</span>
                  </div>
                  <button className="login-btn">Log in</button>
                  <p className="create-acc-link">Don't have account ? <Link to='/registration-landing'>Create an account</Link></p>
                </form>
              </div>
            </Col> */}

          {loading ? (
            <Col
              lg="6"
              sm="12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </Col>
          ) : loginActiveTab === loginTabs.LOGIN ? (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3>Login to your Account</h3>
                <p>
                  If you are a registered user, please enter your email and
                  password.
                </p>
                <form className="login-form">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={loginInput?.email}
                      onChange={onLogchange}
                    />
                    {inputLogin?.email ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {inputLogin?.email ? "Email is required" : ""}
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      value={loginInput?.password}
                      onChange={onLogchange}
                      type={showLoginPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder="Password"
                    />
                    <Button
                      className="password-icon"
                      onClick={() => setShowLoginPassword(!showLoginPassword)}
                    >
                      {showLoginPassword ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </Button>
                    {inputLogin?.password ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {inputLogin?.password ? "password is required" : ""}
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="forgot-pass">
                    <span
                      onClick={() =>
                        setLoginActiveTab(loginTabs.FORGOT_PASSWORD)
                      }
                    >
                      Forgot Password ?
                    </span>
                  </div>
                  <button className="login-btn" onClick={loginForm}>
                    Log in
                  </button>

                </form>

                <div className="forgot-pass mt-2 mb-0 pb-0">
                  <span
                    onClick={() => {
                      navigate('/registration-landing')
                    }
                    }
                  >
                    Register
                  </span>
                </div>
              </div>

            </Col>
          ) : loginActiveTab === loginTabs.FORGOT_PASSWORD ? (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3 className="mb-30">Forgot your password?</h3>
                <form className="login-form">
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      value={loginInput?.email}
                      onChange={onLogchange}
                      className="form-control"
                      placeholder="Your Email"
                    />
                    {inputLogin?.email ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {inputLogin?.email ? "Email is required" : ""}
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="forgot-pass">
                    <p
                      onClick={() => {
                        setLoginActiveTab(loginTabs.LOGIN);
                      }}
                    >
                      Sign in to your account
                    </p>
                  </div>
                  <button className="login-btn" onClick={sendOtpToMail}>
                    Send OTP
                  </button>
                </form>
              </div>
            </Col>
          ) : loginActiveTab === loginTabs.SEND_OTP ? (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3 className="mb-30">Verify Your Account</h3>
                <form className="login-form">
                  <div className="form-group">
                    <input
                      type="number"
                      name="otp"
                      value={loginInput?.otp}
                      onChange={(e) => {
                        setOtp(e.target.value);
                      }}
                      className="form-control"
                      placeholder="Enter OTP"
                    />
                    {otp ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {otp === null ? "Please enter OTP" : ""}
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>

                  <span className="forgot-pass">
                    <p onClick={sendOtpToMail}>Resend OTP</p>
                  </span>

                  <button
                    className="login-btn"
                    onClick={verifyOtpToChangePassword}
                  >
                    Verify OTP
                  </button>
                </form>
              </div>
            </Col>
          ) : loginActiveTab === loginTabs.SEND_PASSWORD ? (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3 className="mb-30">Enter Credentials</h3>
                <form className="login-form">
                  <div className="form-group">
                    <input
                      value={loginInput?.password}
                      onChange={onLogchange}
                      type={showLoginPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder="Password"
                    />
                    <Button
                      className="password-icon"
                      onClick={() => setShowLoginPassword(!showLoginPassword)}
                    >
                      {showLoginPassword ? (
                        <AiFillEyeInvisible />
                      ) : (
                        <AiFillEye />
                      )}
                    </Button>
                    {inputLogin?.password ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {inputLogin?.password ? "password is required" : ""}
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="forgot-pass">
                    <p
                      onClick={() => {
                        setLoginActiveTab(loginTabs.LOGIN);
                      }}
                    >
                      Sign in to your account
                    </p>
                  </div>
                  <button className="login-btn" onClick={forgotPasswordFun}>
                    Change password
                  </button>
                </form>
              </div>
            </Col>
          ) : (
            <Col lg="6" sm="12">
              <div className="login_wrap">
                <h3 className="mb-30">Verify Email</h3>

                <form className="login-form">
                  <div className="form-group">
                    <input
                      value={otp}
                      onChange={(e) => {
                        setOtpError(false);
                        setOtp(e.target.value);
                      }}
                      type="number"
                      name="otp"
                      className="form-control"
                      placeholder="Enter 6 digit OTP"
                    />
                    {otpError ? (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {otpError ? "Please enter OTP" : ""}
                      </small>
                    ) : (
                      <small
                        className="validation-error"
                        style={{ color: "red", marginBottom: "0px" }}
                      >
                        {otpError ? "Please enter OTP" : ""}
                      </small>
                    )}
                    <span className="forgot-pass">
                      <p onClick={resendLoginOtpFun}>Resend OTP</p>
                    </span>
                  </div>
                  <button className="login-btn" onClick={sendOtp}>
                    Verify Email
                  </button>
                </form>
              </div>
            </Col>
          )}

        </Row>

      </div>

      <div className="copyright-info">
        <div className="container">
          <div className="copyright-links">
            <div className="info">
              <p>© 2023, Browbar by Reema. All rights reserved.</p>
              {/* <div className="d-flex links">
                  <a href="/privacy-policy">Privacy Policy</a>
                  <a href="/terms-of-use">Terms of use</a>
                  <a href="/cookie-settings">Cookie Settings</a>
                </div> */}
            </div>
            <div className="d-flex security-verifications">
              <img
                src={require("../../assets/security/authorize-net.webp")}
                alt="Authorize.net"
              />
              <img
                src={require("../../assets/security/paypal-badge.webp")}
                alt="Paypal Badge"
              />
              <img
                src={require("../../assets/security/badge_google.webp")}
                alt="Google Badge"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewAccountPage;