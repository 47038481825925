import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

const ReturnOrders = () => {
  return (
    <section className="contact-us ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>Returns | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <h2>RETURNS</h2>
        <p>
          Email us for any queries at{" "}
          <a
            href="mailto:hello@browbarbyreema.com"
            style={{ textDecoration: "underline", color: "black" }}
          >
            hello@browbarbyreema.com
          </a>
        </p>
        <p>
          For S.H.A.P.E.S Membership inquiries and requests, please email us{" "}
          <a
            href="mailto:membership@shapesbrowbar.com"
            style={{ textDecoration: "underline", color: "black" }}
          >
            here
          </a>
          .
        </p>
        <Row className="justify-content-center">
          <Col lg="8" sm="12">
            <form className="contact-us-form">
              <Row>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="fName">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="fName"
                    id="fName"
                  />
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="lName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="lName"
                    id="lName"
                  />
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    id="email"
                  />
                </Col>
                <Col lg="6" sm="12">
                  <label className="form-label" htmlFor="username">
                    Order ID
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Order ID"
                    name="order-id"
                    id="order-id"
                  />
                </Col>
                <Col lg="12" sm="12">
                  <Button className="submit-btn">Submit</Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ReturnOrders;
