import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { BsArrowRight } from 'react-icons/bs';

const Press = () => {
    return (
      <section className="press">
        <Helmet>
          <title>Press | Brow Bar by Reema</title>
        </Helmet>

        {/* BANNER */}
        <section className="ptb-30">
          <div className="container">
            <div className="banner">
              <img
                src={require("../../assets/banners/press-banner.webp")}
                alt="Press | Browbar by Reema"
              />
            </div>
          </div>
        </section>

        {/* PRESS CONTENT */}
        <section>
          <div className="container">
            <Row className="justify-content-center">
              <Col lg="6" sm="12">
                <h5 className="press-content">
                  From breaking world records for most eyebrows threaded to
                  breaking into a million dollar business, Reema has done it
                  all. Watch what the press has to say about Reema Khan and her
                  growing brow empire!
                </h5>
              </Col>
            </Row>
          </div>
        </section>

        {/* PRESS ARTICLES */}
        <section className="ptb-20">
          <div className="container">
            <Row className="ptb-10">
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <img
                    src={require("../../assets/banners/daily-mall.webp")}
                    className="image"
                    alt="Daily Mall"
                  />
                  <a
                    href="https://www.dailymail.co.uk/femail/article-9522085/Brow-Bar-Reema-DIY-kits-helped-women-perfect-brows-lockdown.html"
                    target={'_blank'}
                    className="article-link" rel="noreferrer"
                  >
                    THE DAILY MAIL <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/gma-deals-and-steals.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <a
                    href="https://player.vimeo.com/544439244"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    GMA DEALS AND STEALS <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/jills-deals-and-steals.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <a
                    href="https://deals.today.com/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    JILL'S DEALS AND STEALS{" "}
                    <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/gma-deals-and-steals-2.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <a
                    href="https://www.goodmorningamerica.com/shop"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    GMA DEALS AND STEALS <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <iframe
                    className="video-frame"
                    height="420"
                    src="https://www.youtube.com/embed/ALkzfKnoXNw"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <a
                    href="https://www.nbclosangeles.com/california-live/how-to-set-up-your-own-brow-beauty-bar-at-home/2477583/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    NBC CA LIVE <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <img
                    src={require("../../assets/banners/press-lady-gang.webp")}
                    className="image"
                    alt="The Lady Gang Subscription Box"
                  />
                  <a
                    href="https://shop.theladygang.com/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    THE LADY GANG SUBSCRIPTION BOX{" "}
                    <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/daytime-emmys-brand-lounge.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <a
                    href="https://theemmys.tv/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    DAYTIME EMMYS BRAND LOUNGE{" "}
                    <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/evertalk-tv.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <a
                    href="https://www.evertalktv.com/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    EVERTALK TV <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <img
                    src={require("../../assets/banners/daily-mail.webp")}
                    className="image"
                    alt="The Daily Mail"
                  />
                  <a
                    href="https://www.dailymail.co.uk/tvshowbiz/article-8152459/Rachel-McCord-partners-Brow-Bar-Reema-brand-offers-free-bottles-hand-sanitizer.html"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    THE DAILY MAIL <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/the-real.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <a
                    href="https://thereal.com/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    THE REAL <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/daytime-emmy-awards.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <a
                    href="https://theemmysawardlounge.tv/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    DAYTIME EMMY AWARDS <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <video controls style={{ height: "420px" }}>
                    <source
                      src={require("../../assets/banners/kelly-clarkson-show.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <a
                    href="https://www.kellyclarksonshow.com/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    THE KELLY CLARKSON SHOW{" "}
                    <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/the-talk.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <a
                    href="https://www.cbs.com/shows/the_talk/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    THE TALK <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/the-doctors.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <a
                    href="https://www.thedoctorstv.com/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    THE DOCTOR'S <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <video controls>
                    <source
                      src={require("../../assets/banners/la-la-land.mp4")}
                      type="video/mp4"
                    />
                  </video>
                  <a
                    href="https://goodmorninglalaland.com/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    GOOD MORNING LA LA LAND{" "}
                    <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
              <Col lg="6" sm="12">
                <div className="articles-content">
                  <iframe
                    className="video-frame"
                    height="420"
                    src="https://www.youtube.com/embed/oD92DqzfpOo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <a
                    href="https://www.boxycharm.com/"
                    target="_blank"
                    className="article-link"
                    rel="noreferrer"
                  >
                    BOXYCHARM <BsArrowRight className="right-icon" />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        {/* SHOPPING COLLECTION */}
        {/* <ShopCollection /> */}
      </section>
    );
};

export default Press;