/* eslint-disable no-lone-blocks */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import ProductBox from "../../components/product-box";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllProducts } from "../../service/api";
import InfiniteScroll from "react-infinite-scroll-component";
import { IMG_URL } from "../../config";
import SpinnerComponent from "../../components/Spinner";
import ProductLoading from "../../components/loading-product";
import { BsArrowRight } from 'react-icons/bs';
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Card, Tooltip } from "reactstrap";
import {
  BlockHead,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  Block,
} from "../../components/Block";
import { Icon } from "../../components/Icon";
import {Button} from "../../components/Button";
import {
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../components/DataTable";
import ItemRow from "../../components/ItemRow";
import Logo from "../../assets/browbar-by-reema-logo.png";
import { filter } from "lodash";
import { AiOutlineCloseCircle } from "react-icons/ai";


const defaultImg = "category-image/1672941733498.gif"
const options = [
  { value: "DATE", label: "Position" },
  { value: "ALPHABET", label: "Product Name" },
  { value: "AMOUNT", label: "Price" },
];

const ShopProducts = () => {
  const navigate = useNavigate();
  const [allProducts, setAllProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(16);
  const [loading, setLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(null);
  const { category } = useSelector((state) => state.products);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { id } = useParams();
  const [optionSelected, setOptionSelected] = useState("DATE");

  // use states for filters
  const [filterarr, setFilterarr] = useState([]);
  const [sets, setSets] = useState(false);
  const [face, setFace] = useState(false);
  const [lips,setLips] = useState(false);
  const [eyes, setEyes] = useState(false);
  const [brow, setBrow] = useState(false);
  const [shop, setShop] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [catList, setCatList] = useState(false);
  const [price, setPrice] = useState(false);
  const [custreview, setCustReview] = useState(false);
  const [oneStar, setOneStar] = useState(false);
  const [twoStars, setTwoStars] = useState(false);
  const [threeStars, setThreeStars] = useState(false);
  const [fourStars, setFourStars] = useState(false);
  const [fiveStars, setFiveStars] = useState(false);
  const [filtertag, setFilterTag] = useState(false);
  const [checked, setChecked] = useState(false);

  function addfilter(name){
    let count=0;
    {filterarr.map(filter => {
      if(filter===name){
        count=count+1;
      }
    })}
    if(!count){
      setFilterarr(filterarr => [...filterarr, name]);
    }
  }

  function removefilter(name){
    filterarr.filter((name) => name);
    console.log(filterarr);
  }

  function checkboxchecked(){
    setChecked(!checked);
    let count = 0;
    {filterarr.map(filter => {
      if(filter==="Available Items"){
        count=count+1;
      }
    })}
    if(!count){
      setFilterarr(filterarr => [...filterarr, "Available Items"]);
    }
  }

  const onestarrating = () => {
      setOneStar(true);
      setTwoStars(false);
      setThreeStars(false);
      setFourStars(false);
      setFiveStars(false);
  }

  const twostarsrating = () => {
      setOneStar(false);
      setTwoStars(true);
      setThreeStars(false);
      setFourStars(false);
      setFiveStars(false);
  }

  const threestarsrating = () => {
      setOneStar(false);
      setTwoStars(false);
      setThreeStars(true);
      setFourStars(false);
      setFiveStars(false);
  }

  const fourstarsrating = () => {
      setOneStar(false);
      setTwoStars(false);
      setThreeStars(false);
      setFourStars(true);
      setFiveStars(false);
  }

  const fivestarsrating = () => {
      setOneStar(false);
      setTwoStars(false);
      setThreeStars(false);
      setFourStars(false);
      setFiveStars(true);
  }

  /* GET PRODUCTS LIST */
  const getProductList = () => {
    let params = {
      page: 1,
      sizePerPage: pageSize,
      category: [id],
      sortBy: optionSelected
    };
    getAllProducts(params)
      .then((res) => {
        if (res?.status) {
          setAllProducts(res?.data[0]?.docs);
          setTotalResults(res?.data[0]?.metadata[0]?.total);
          // console.log("total results", res?.data[0]?.metadata[0]?.total)
        }
        // console.log("products--->", res?.data[0]?.docs);
        setLoading(false);
      })
      .catch((err) => {
        // console.log("err", err);
      })
      .finally((err) => {
        setLoading(false);
      });
  };
  /* USEEFFECT */
  useEffect(() => {
    setLoading(true)
    getProductList();
    setSelectedCategory(category?.filter((data) => data?._id === id)[0]);
    setPage(1)
  }, [id]);

  const onChange = React.useCallback((event) => {
    const value = event.value;
    // console.log(value)
    setOptionSelected(value);

  }, []);

  /* USEEFFECT */
  useEffect(() => {
    // console.log("page", page)
    setLoading(true)
    if (page > 1) {
      let params = {
        page: page,
        sizePerPage: pageSize,
        category: [id],
        sortBy: optionSelected
      };
      getAllProducts(params)
        .then((res) => {
          if (res?.status && res?.data[0]?.docs?.length !== 0) {
            // console.log("find more-->", res?.data[0]?.docs)
            // allProducts.push(res?.data[0]?.docs)
            setAllProducts([...allProducts, ...res?.data[0]?.docs]);
          }
          // console.log("products--->", res?.data[0]?.docs);
          setLoading(false);
        })
        .catch((err) => {
          // console.log("err", err);
        })
        .finally((err) => {
          setLoading(false);
        })
    }
  }, [page])

  return (
    <section className="new-arrivals ptb-30">
      <Helmet>
        <title>Shop All | Brow Bar by Reema</title>
      </Helmet>

      <div className="container">
        <h2 style={{ textTransform: "uppercase" }}>
          {selectedCategory?.name ? selectedCategory?.name : "Shop"} PRODUCTS
        </h2>
        <Row className="justify-content-center">
          {/* BANNER IMAGE */}
          <Col lg="12" sm="12">
            <div className="banner">
              {selectedCategory?.categoryImage ? (
                <img
                  src={IMG_URL + selectedCategory?.categoryImage}
                  alt={selectedCategory?.name + " Products | Brow Bar by Reema"}
                />
              ) : (
                <img
                  src={IMG_URL + defaultImg}
                  alt={"Shop" + " Products | Brow Bar by Reema"}
                />
              )}
            </div>
          </Col>

          {/* BANNER TEXT */}
          <Col lg="8" sm="12">
            <div className="heading ptb-10">
              <p>{selectedCategory?.description}</p>
              <p>
                <b>
                  For a limited time enjoy FREE gifts, including the luxe{" "}
                  <a
                    href="/"
                    style={{ textDecoration: "underline", color: "#000" }}
                  >
                    Bombshell Brow & Lash Serum
                  </a>
                  , with orders $40 and up PLUS a FREE{" "}
                  <a
                    href="/"
                    style={{ textDecoration: "underline", color: "#000" }}
                  >
                    Golden Gift Bag
                  </a>{" "}
                  with orders $50 and up!
                </b>
              </p>
            </div>
          </Col>
        </Row>

        {/* PRODUCTS LIST */}
        <Row className="products-list border-top-section ptb-30">
          <div className="container">
            {!loading ? allProducts?.length === 0 ? (
              <div className="no-match-found">
                <h5>No products found</h5>
                <p>
                  Try a different search term or check our some of our
                  suggestions below.
                </p>
                <Row className="mt-1">
                  <Col lg="3">
                    <div className="no-match-category">
                      <Link className="collection-card">
                        <img
                          style={{ height: "351px" }}
                          src={require("../../assets/banners/shop-all.webp")}
                          alt="SHOP ALL"
                        />
                        <Link

                          to={{
                            pathname: `/products/${category?.find((obj) =>
                              obj?.name.includes("Shop"))?._id}`
                          }}
                          className="collection-link"
                          style={{ textDecoration: "none" }}
                        >
                          SHOP ALL <BsArrowRight className="right-icon" />
                        </Link>
                      </Link>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="no-match-category">
                      <Link className="collection-card">
                        <img
                          src={require("../../assets/banners/shop-brow.webp")}
                          alt="SHOP BROW"
                        />
                        <Link
                          to={{
                            pathname: `/products/${category?.find((obj) =>
                              obj?.name.includes("Brow"))?._id}`
                          }}
                          className="collection-link">
                          SHOP BROW <BsArrowRight className="right-icon" />
                        </Link>
                      </Link>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="no-match-category">
                      <Link className="collection-card">
                        <img
                          src={require("../../assets/banners/shop-new-arrivals.webp")}
                          alt="SHOP BROW"
                        />
                        <Link
                          to={{
                            pathname: `/products/${category?.find((obj) =>
                              obj?.name.includes("New"))?._id}`
                          }}
                          className="collection-link">

                          SHOP NEW ARRIVALS{" "}
                          <BsArrowRight className="right-icon" />
                        </Link>
                      </Link>
                    </div>
                  </Col>
                  <Col lg="3">
                    <div className="no-match-category">
                      <Link className="collection-card">
                        <img
                          src={require("../../assets/banners/shop-bestsellers.webp")}
                          alt="SHOP BROW"
                        />
                        <Link
                          to={{
                            pathname: `/products/${category?.find((obj) =>
                              obj?.name.includes("Best Sellers"))?._id}`
                          }}

                          className="collection-link">

                          SHOP BEST SELLERS{" "}
                          <BsArrowRight className="right-icon" />
                        </Link>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <>
                <div className="product-stats">
                <div className="left-product-stats">
                  <p>We have total {totalResults} products</p>

                  <div className="selected-filters">
                    <p className={filterarr.length ? "filter-tag" : "d-none"}>Filters:</p>
                    <div className="filter-header">
                    {filterarr.map(filter => (
                      <div className="filter-header-title">
                        {filter}
                        <AiOutlineCloseCircle className="close-icon" onClick={() => removefilter({filter})}/>
                      </div>
                    ))}
                    </div>
                  </div>
                </div>

                  <Select
                    options={options}
                    classNamePrefix="select-control"
                    placeholder="Sort by"
                    name="sort-by"
                    id="sort-by"
                    onChange={onChange}
                  />
                </div>
                
                <div className="product-listing">
                  <div className="filters">
                    <div className="filter-section">
                      <div className="category">
                        <div className="filter-title" onClick={() => setCatList(!catList)}>
                          Category
                        </div>
                        <div className={catList ? "category-list" : "d-none"}>
                          <ul className="category-ul-list" onClick={() => setFilterTag(!filtertag)}>
                            <li onClick={() => addfilter("Sets")} className="cat-item">Sets</li>
                            <li onClick={() => addfilter("Face")} className="cat-item">Face</li>
                            <li onClick={() => addfilter("Lips")} className="cat-item">Lips</li>
                            <li onClick={() => addfilter("Eyes")} className="cat-item">Eyes</li>
                            <li onClick={() => addfilter("Brow")} className="cat-item">Brow</li>
                            <li onClick={() => addfilter("New")} className="cat-item">New</li>
                          </ul>
                        </div>
                      </div>
                      <div className="price">
                        <div className="filter-title" onClick={() => setPrice(!price)}>
                          Price
                        </div>
                        <div className={price ? "price-slider" : "d-none"}>
                          <div className="price-slider-div">
                            <input type="range" value="70" min="0" max="100" oninput="rangevalue.value=value"/>
                          </div>
                        </div>
                      </div>
                      <div className="avg-cust-reviews">
                        <div className="filter-title" onClick={() => setCustReview(!custreview)}>
                          Avg. Customer Reviews
                        </div>
                        <div className={custreview ? "cust-review" : "d-none"}>
                          <div class="stars">
                            <form action="">
                              <input class="star star-5" id="star-5" type="radio" name="star" onClick={fivestarsrating}/>
                              <label class="star star-5" for="star-5"></label>
                              <input class="star star-4" id="star-4" type="radio" name="star" onClick={fourstarsrating}/>
                              <label class="star star-4" for="star-4"></label>
                              <input class="star star-3" id="star-3" type="radio" name="star" onClick={threestarsrating}/>
                              <label class="star star-3" for="star-3"></label>
                              <input class="star star-2" id="star-2" type="radio" name="star" onClick={twostarsrating}/>
                              <label class="star star-2" for="star-2"></label>
                              <input class="star star-1" id="star-1" type="radio" name="star" onClick={onestarrating}/>
                              <label class="star star-1" for="star-1"></label>
                            </form>
                          </div>
                          <div className="rating-main">
                            <div className={oneStar ? "rating-display" : "d-none"}>
                              1-Star rated products
                            </div>
                            <div className={twoStars ? "rating-display" : "d-none"}>
                              2-Stars rated products
                            </div>
                            <div className={threeStars ? "rating-display" : "d-none"}>
                              3-Stars rated products
                            </div>
                            <div className={fourStars ? "rating-display" : "d-none"}>
                              4-Stars rated products
                            </div>
                            <div className={fiveStars ? "rating-display" : "d-none"}>
                              5-Stars rated products
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="availability">
                        <div className="filter-title">
                          <div className="avail">
                            <p>Availability</p>
                          </div>
                          <div className="checkbox-div">
                            <input
                            type="checkbox"
                            checked={checked}
                            onClick={() => checkboxchecked()}
                            className="checkbox"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row>
                  <div className="column-header">
                    <Row>
                      <Col lg="2">
                        <p>Image</p>
                      </Col>
                      <Col lg="2">
                        <p>Name</p>
                      </Col>
                      <Col lg="2">
                        <p>Category</p>
                      </Col>
                      <Col lg="2">
                        <p>SKU</p>
                      </Col>
                      <Col lg="2">
                        <p>Colors</p>
                      </Col>
                      <Col lg="2">
                        <p>Quantity</p>
                      </Col>
                    </Row>
                    </div>
                    <InfiniteScroll
                      dataLength={allProducts?.length}
                      next={() => setPage(page + 1)}
                      hasMore={allProducts?.length < totalResults}
                      loader={loading && <ProductLoading />}
                      style={{
                        overflowX: "hidden",
                        overflowY: "hidden",
                      }}
                    >
                    <div className="table">
                      {/* <div className="item-titles">
                        <Row>
                          <Col lg="2">
                            <h5>Image</h5>
                          </Col>
                          <Col lg="2">
                            <h5>Name</h5>
                          </Col>
                          <Col lg="2">
                            <h5>SKU</h5>
                          </Col>
                          <Col lg="2">
                            <h5>Category</h5>
                          </Col>
                          <Col lg="2">
                            <h5>Colors</h5>
                          </Col>
                          <Col lg="2">
                            <h5>Quantity</h5>
                          </Col>
                        </Row>
                      </div> */}
                      <div className="Product-listing">
                        {allProducts.map(product => (
                          <ItemRow itemImage={product.productImage} name={product.name}
                          sku={product.sku} category={product.category} />
                        ))}
                      </div>
                    </div>
                      {/* <Row>
                        {allProducts?.map((product) => {
                          // console.log("product--.", product)


                          return (
                            product?.isWholeSale ? <Col lg="3" sm="12" key={product.id}>
                              <ProductBox
                                isOutOfStock={product.isOutOfStock}
                                productFrontImg={product.productImage.front[0]}
                                productBackImg={product.productImage.back[0]}
                                productName={product.name}
                                productPrice={product?.wholeSaleAmount}
                                productId={product?._id}
                                colorId={product?.productImage?.color}
                                wishlistObj={product?.WishlistProduct?._id}
                                refresh={false}
                                product={product}
                              />
                            </Col> : false
                          );
                        })}
                      </Row> */}
                    </InfiniteScroll>
                  </Row>
                </div>
              </>
            ) : <ProductLoading />}
          </div>
        </Row>
      </div>
    </section>
  );
};

export default ShopProducts;
