/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { IMG_URL } from "../../config";
import { getOrderById } from "../../service/api";
import "react-phone-number-input/style.css";
import { useUpdateCart } from "../../hooks/useUpdateCart";

const ThankYou = () => {
  const { id } = useParams();
  const { setUpdateCart } = useUpdateCart();

  console.log("location: ", id);
  const [orderData, setOrderData] = useState(null);
  console.log('orderData: ', orderData);
  const getOrderDetails = () => {
    getOrderById(id).then((res) => {
      if (res.status) {
        setOrderData(res.data);
      }
    });
  };
  useEffect(() => {
    getOrderDetails();
    setUpdateCart(true);
  }, [id]);
  
  return (
    <section className="checkout ptb-30">
      {/* HELMET */}
      <Helmet>
        <title>Checkout | Brow Bar By Reema</title>
      </Helmet>

      <div className="container">
        {orderData && (
          <Row className="justify-content-between mt-4">
            <Col lg="7" sm="12">
              <div className="order-complete">
                <h4>Thank you, your order has been placed.</h4>
                <p>
                  Your order confirmation and delivery details have been sent to{" "}
                  <b>{orderData?.shippingAddress?.email}</b>
                </p>
                <div className="order">
                  <p>Order No.</p>
                  <h5>{orderData?.orderId}</h5>
                </div>
                <div className="order mt-2">
                  <p>Order Date</p>
                  <h5>{moment(orderData.createdAt).format("ll")}</h5>
                </div>
                <div className="entered-shipping-details">
                  <h5>Ship to:</h5>
                  <div className="entered-details">
                    <div className="entered-details">
                      <p>
                        {orderData?.shippingAddress?.firstName +
                          " " +
                          orderData?.shippingAddress?.lastName}
                      </p>
                      <p>{orderData?.shippingAddress?.phone}</p>
                      <p>
                        {orderData?.shippingAddress?.address},{" "}
                        {orderData?.shippingAddress?.city},
                        {orderData?.shippingAddress?.state},{" "}
                        {orderData?.shippingAddress?.zipCode},
                        {orderData?.shippingAddress?.country}
                      </p>
                      <p>{orderData?.shippingAddress?.email}</p>
                    </div>
                  </div>
                </div>
                <div className="order mt-2">
                  <p>Delivery Detail</p>
                  <h5>
                    {orderData?.shippingData?.serviceName} ($
                    {orderData.payAmount.toFixed(2) >= 40 &&
                    orderData.shippingAddress?.country === "United States"
                      ? "0.00"
                      : orderData?.shippingData?.shipmentCost.toFixed(2)}
                    )
                  </h5>
                </div>
              </div>
            </Col>
            <Col lg="4" sm="12">
              <Row>
                <Col lg="12" sm="12">
                  <h5 className="summary-heading">Summary</h5>
                  <div className="order-summary">
                    <div className="items">
                      <p>Items ({orderData.order.length})</p>
                      <p>${orderData.payAmount.toFixed(2)}</p>
                    </div>
                    <hr />
                    <div className="items">
                      <p>
                        <b>Estimated Total</b>
                      </p>
                      <p>
                        <b>${orderData.payAmount.toFixed(2)}</b>
                      </p>
                    </div>
                    {/* <p className="after-pay-link">
                      Or 4 interest free installments of $26.00 with{" "}
                      <b>Afterpay</b>
                    </p> */}
                  </div>
                </Col>
                <Col lg="12" sm="12" className="mt-3">
                  <h5 className="summary-heading">Items (2)</h5>
                  {orderData.order &&
                    orderData.order.length > 0 &&
                    orderData.order.map((data) => {
                      return (
                        <div className="items-box">
                          <Row className="mt-1">
                            <Col lg="3" sm="4">
                              <img
                                src={
                                  IMG_URL +
                                  (data?.product?.productImage?.length > 0
                                    ? data?.product?.productImage?.filter(
                                        (img) => img.color === data.color._id
                                      )[0]?.front[0]
                                    : data?.product?.productImage?.front[0])
                                }
                                className="product-img-bought"
                                alt="Bombshell Brow Kit - Medium"
                              />
                            </Col>
                            <Col lg="9" sm="8">
                              <h4 className="product-name">
                                {data?.product?.name}
                              </h4>
                              <p>
                                <b>Color: </b>
                                {data?.color?.name}
                              </p>
                              <p>
                                <b>Quantity: </b>
                                {data?.quantity}
                              </p>
                              <p>
                                <b>Price: </b>${data?.amount}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </section>
  );
};

export default ThankYou;
