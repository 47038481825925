import request from "./request";

// auth
export const login = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const register = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/auth/register", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        resolve(e.response.data);
      });
  });
};

export const resendOtp = (data) => {
  console.log(data);
  return new Promise(async (resolve, reject) => {
    await request
      .patch("auth/email/resend", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const verifyOtp = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("auth/email/verify", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const loginVerifyEmail = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("auth/send-otp", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const forgotPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("auth/forgot/password", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//categories
export const getAllCategory = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`category`, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getCategoryById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`category/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//products
export const getAllProducts = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("product", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getAllProductsCombined = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("product/combined", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getAllProductsWholeSale = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("product/combined", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


export const getProductById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`product/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// color
export const getColor = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/color")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// pattern
export const getPattern = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/pattern")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// type || category
export const getType = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/category")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// composition
export const getComposition = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/content")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// user
export const getUserProfie = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/profile")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateUserProfie = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/profile", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


//address

export const postNewAdress = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/profile/address", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const editAdress = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/profile/address/${id}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deleteAdress = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/profile/address/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//payment Information

export const postNewPayment = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/profile/addPaymentInformation", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const editPayment = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`/profile/addPaymentInformation/${id}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const deletePaymentInformation = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/profile/addPaymentInformation/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// tag
export const getTag = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/tag")
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// contact-us
export const postContact = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/contact-us", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// wishList
export const postWishList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/wishlist", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getWishList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("wishlist", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//add to cart
export const addTocart = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("cart", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteCart = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/cart/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//cart list
export const cartList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("cart", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteWishList = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`wishlist/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// wholesale
export const wholeSale = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/wholesale", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// affiliate - program;
export const affiliateProgram = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/affiliate-program", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//order history
export const orderHistory = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("order", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//order by id
export const getOrderById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("order/" + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// order
export const placeOrder = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/order", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// shipping address
export const getShippingAddressData = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("/order/shipping-charge", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//news letter
export const postNewsLetter = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/news-latter", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//quiz 
export const getQuizDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`quiz/${data}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//rating letter
export const postRating = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`product-review`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteRating = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`product-review/replay/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getRating = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`product-review`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getCustomerData = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`payment/braintree/customer`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
//update cart
export const updateCart = (id, type, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("cart/" + id + "/" + type, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//CMS
export const getCmsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`content-management/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getCms = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`content-management`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getUserProfile = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`profile`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//REGISTRATION FORM
export const registrationForm = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post(`registration-form`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const attachDocument = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`registration-form/${id}`, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


export const getCategoryMenu = () => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`category/product/forMenu`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getCategoryMenuParams = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("category", { params: params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const checkPromoCode = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch("/order/promo-code/check", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};